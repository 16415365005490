import io from 'socket.io-client';
import appConfig from '../../appConfig.json';

export let livestreamSocket: SocketIOClient.Socket;
export const getLivestreamChannel = (streamId: any) => `livestream_${streamId}`;

export const SOCKET_STATE = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  NOT_CONNECTED: 'not-connected',
  DISCONNECTED: 'disconnected',
};

export const handleLivestreamSocket = (streamId: any, callback: any) => {
  if (livestreamSocket?.connected) {
    callback && callback(livestreamSocket);
    return;
  }

  livestreamSocket = io(appConfig.baseUrl, {
    query: {
      channel: getLivestreamChannel(streamId), // required name 'channel'
    },
    transports: ['websocket'],
    transportOptions: {
      polling: {
        extraHeaders: {
          // authorization: "Bearer " + "a1bde50f-e72d-4b2e-a4e8-21b33c31bb4c",
        },
      },
    },
  });

  livestreamSocket.on('connect', () => {
    console.log(
      'Connected to server handleLivestreamSocket: ',
      getLivestreamChannel(streamId)
    );
  });

  // livestreamSocket.on('disconnect', (data: any) => {
  //   console.log('Disconnected to server handleLivestreamSocket', data);
  // });

  callback && callback(livestreamSocket);
};
