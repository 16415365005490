import { getValueBindingComponent } from '@common/utils/component';
import React, { FC, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { WebView } from 'react-native-webview';
import createStyles from './style';

const Vimeo: FC = (attributes: any) => {
  const videoIdPlay = useMemo(() => {
    const url = getValueBindingComponent(attributes, 'url');

    if (url) {
      const regExp = /^.*com\/([a-zA-Z0-9\/]+)/;
      const match = url.match(regExp);
      if (match && match.length !== 0) {
        const match_url = match[1].split('/').filter((m: string) => m !== '');
        if (match_url.length === 1) {
          return `${match_url[0]}?`;
        } else if (match_url.length === 2) {
          return `${match_url[0]}/?h=${match_url[1]}&`;
        }
      }
    }
  }, [attributes]);

  const styles = createStyles(attributes);
  if (Platform.OS === 'web') {
    return (
      <View style={styles.container}>
        <iframe
          title="PMU"
          src={`https://player.vimeo.com/video/${videoIdPlay}
          autoplay=1&
          loop=1&
          autopause=0`}
          width="100%"
          height="100%"
          allow="autoplay; fullscreen"
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <WebView
        javaScriptEnabled={true}
        scrollEnabled={false}
        allowsFullscreenVideo={true}
        allowsInlineMediaPlayback={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1"
        source={{
          uri: `https://player.vimeo.com/video/${videoIdPlay}?autoplay=1&loop=1&autopause=0`,
        }}
        style={{
          width: attributes.width,
          height: attributes.height,
        }}
      />
    </View>
  );
};

export default Vimeo;
