export default {
  name: 'Map',
  displayName: { ja: 'マップ', en: 'Map' },
  icon: './icon.png',
  defaultWidth: 600,
  defaultHeight: 232,
  resizeX: true,
  props: [
    {
      name: 'apiKey',
      displayName: {
        ja: 'Google Maps APIキー( テキスト)',
        en: 'Google Maps API Key(Text)',
      },
      type: 'text',
      // global: true,
      disableDynamicValues: true,
      helpText: 'Get your API Key [here]',
    },
    {
      name: 'markerType',
      displayName: { ja: 'ピンの数', en: 'Number of Pins' },
      type: 'text',
      default: 'simple',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.map.singleMarker',
            value: 'simple',
          },
          {
            label: 'canvas.menuRight.elementTab.map.multiMarker',
            value: 'multiple',
          },
        ],
      },
    },

    {
      name: 'markerCollection',
      displayName: { ja: 'データを選択して下さい', en: 'Please select data.' },
      type: 'list',
      enabled: {
        markerType: 'multiple',
      },
      disableListOptions: true,
    },
    {
      name: 'onPress',
      displayName: { ja: 'Click Actions', en: 'Click Actions' },
      type: 'action',
      role: 'listItem',
      reference: 'markerCollection',
      enableReference: {
        markerType: 'multiple',
      },
    },
  ],
  childComponents: [
    {
      name: 'marker',
      displayName: { ja: 'ピン', en: 'Pins' },
      enabled: {
        markerType: 'simple',
      },
      props: [
        {
          name: 'markerTypeOfPin',
          displayName: { ja: 'ピンの入力設定', en: 'Type of Pins' },
          type: 'text',
          default: 'address',
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.map.pinAddress',
                value: 'address',
              },
              {
                label: 'canvas.menuRight.elementTab.map.pinGeometry',
                value: 'geometry',
              },
            ],
          },
        },
        {
          name: 'markerAddress',
          displayName: { ja: '地名・住所入力', en: 'Pin Address' },
          type: 'text',
          enabled: {
            markerTypeOfPin: 'address',
          },
        },
        {
          name: 'markerY',
          displayName: { ja: '緯度', en: 'Latitude' },
          type: 'number',
          enabled: {
            markerTypeOfPin: 'geometry',
          },
        },
        {
          name: 'markerX',
          displayName: { ja: '経度', en: 'Longitude' },
          type: 'number',
          enabled: {
            markerTypeOfPin: 'geometry',
          },
        },

        {
          name: 'markerSource',
          displayName: { ja: 'マーカーの設定', en: 'Marker Source' },
          type: 'text',
          default: 'default',
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.map.default',
                value: 'default',
              },
              {
                label: 'canvas.menuRight.elementTab.map.custom',
                value: 'custom',
              },
            ],
          },
        },
        {
          name: 'markerImage',
          displayName: { ja: 'マーカーの画像', en: 'Marker Image' },
          type: 'image',
          enabled: {
            markerSource: 'custom',
          },
        },
        {
          name: 'currentLocation',
          displayName: {
            ja: '現在地の表示',
            en: 'Show Current Location',
          },
          type: 'switch',
        },

        // {
        //   name: 'onPress',
        //   displayName: { ja: 'Click Actions', en: 'Click Actions' },
        //   type: 'action',
        // },
      ],
    },

    {
      name: 'markers',
      displayName: { ja: 'ピン', en: 'Pins' },
      role: 'listItem',
      reference: 'markerCollection',
      enabled: {
        markerType: 'multiple',
      },
      props: [
        {
          name: 'markerTypeOfPin',
          displayName: { ja: 'ピンの入力設定', en: 'Type of Pins' },
          type: 'text',
          default: 'address',
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.map.pinAddress',
                value: 'address',
              },
              {
                label: 'canvas.menuRight.elementTab.map.pinGeometry',
                value: 'geometry',
              },
            ],
          },
        },
        {
          name: 'markerAddress',
          displayName: { ja: '地名・住所入力', en: 'Pin Address' },
          type: 'text',
          enabled: {
            markerTypeOfPin: 'address',
          },
        },
        {
          name: 'markerY',
          displayName: { ja: '緯度', en: 'Latitude' },
          type: 'number',
          enabled: {
            markerTypeOfPin: 'geometry',
          },
        },
        {
          name: 'markerX',
          displayName: { ja: '経度', en: 'Longitude' },
          type: 'number',
          enabled: {
            markerTypeOfPin: 'geometry',
          },
        },

        {
          name: 'markerSource',
          displayName: { ja: 'マーカーの設定', en: 'Marker Source' },
          type: 'text',
          default: 'default',
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.map.default',
                value: 'default',
              },
              {
                label: 'canvas.menuRight.elementTab.map.custom',
                value: 'custom',
              },
            ],
          },
        },

        {
          name: 'listMarkerImage',
          displayName: { ja: 'マーカーの画像', en: 'Marker Image' },
          type: 'image',
          enabled: {
            markerSource: 'custom',
          },
        },
        {
          name: 'currentLocation',
          displayName: {
            ja: '現在地の表示',
            en: 'Show Current Location',
          },
          type: 'switch',
        },
        {
          name: 'selectedMarker',
          displayName: {
            ja: '初期表示の設定',
            en: 'Select Center Pin',
          },
          type: 'switch',
        },
        {
          name: 'selectedAddressMarker',
          displayName: {
            ja: '住所の入力',
            en: 'Select An Address Pin',
          },
          type: 'text',
          enabled: {
            selectedMarker: true,
          },
        },
        {
          name: 'distanceSearch',
          displayName: {
            ja: '地図のズームレベル(km)',
            en: 'Display locations by distance (Km)',
          },
          type: 'number',
          placeholder: '5',
          enabled: {
            $or: { currentLocation: true, selectedMarker: true },
          },
        },
        // {
        //   name: 'onPress',
        //   displayName: { ja: 'Click Actions', en: 'Click Actions' },
        //   type: 'action',
        // },
      ],
    },
    {
      name: 'style',
      displayName: { ja: 'スタイル', en: 'Style' },
      props: [
        {
          name: 'mapStyle',
          displayName: { ja: 'マップのスタイル', en: 'Map Style' },
          type: 'text',
          default: 'roadmap',
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.map.roadmap',
                value: 'roadmap',
              },
              {
                label: 'canvas.menuRight.elementTab.map.hybrid',
                value: 'hybrid',
              },
              {
                label: 'canvas.menuRight.elementTab.map.satellite',
                value: 'satellite',
              },
              {
                label: 'canvas.menuRight.elementTab.map.terrain',
                value: 'terrain',
              },
            ],
          },
        },
        {
          name: 'customStyle',
          displayName: {
            ja: 'カスタムスタイル（JSON）',
            en: 'Custom Style JSON',
          },
          type: 'text',
          placeholder: '',
          disableDynamicValues: true,
          helpText: 'Learn more about customizing your map [here]',
        },
      ],
    },
  ],
  resizeY: true,
};
