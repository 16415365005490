import { StyleSheet } from 'react-native';
import { pick } from 'lodash';

import { IStampHistory } from '@common/types/';

const createStyle = (attrs: IStampHistory) => {
  return StyleSheet.create({
    container: {
      marginTop: attrs.marginTop,
      width: '100%',
      height: attrs.height,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: attrs.zIndex,
    },
    stampRallyItem: {
      ...pick(attrs.stampRallyItem, [
        'height',
        'width',
        'borderWidth',
        'borderColor',
        'backgroundColor',
        'borderRadius',
      ]),
      ...(attrs.stampRallyItem?.borderStyle !== 'none' && {
        borderStyle: attrs.stampRallyItem?.borderStyle,
      }),
      alignItems: 'center',
      justifyContent: 'center',
      margin: attrs.gap / 2,
    },
    stampRallyText: {
      ...pick(attrs.stampRallyText, ['color', 'fontSize']),
      textAlign: 'center',
      fontWeight: 'bold',
    },
    shopStampItem: {
      ...pick(attrs.shopStampItem, [
        'height',
        'width',
        'borderWidth',
        'borderColor',
        'backgroundColor',
        'borderRadius',
      ]),
      ...(attrs.shopStampItem?.borderStyle !== 'none' && {
        borderStyle: attrs.shopStampItem?.borderStyle,
      }),
      alignItems: 'center',
      justifyContent: 'center',
      margin: attrs.gap / 2,
    },
    shopStampText: {
      ...pick(attrs.shopStampText, ['color', 'fontSize']),
      textAlign: 'center',
      fontWeight: 'bold',
    },
    shopStampImprint: {
      ...pick(attrs.shopStampImprint, ['height', 'width', 'borderRadius']),
      margin: 'auto',
    },
    stampRallyImprint: {
      ...pick(attrs.stampRallyImprint, ['height', 'width', 'borderRadius']),
      margin: 'auto',
    },
  });
};

export default createStyle;
