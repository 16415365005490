import { first, get, isEmpty, isNil } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  FlatList,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import EmptyList from '../EmptyList';
import { checkFont } from '../func';
import Loading from '../Loading';
import {
  getActions,
  getItemListClick,
  getValueBinding,
  getValueInputDependencies,
} from '../shared';

export const SELECT_LIST = 'selectList';

const CHECKED = require('./checked.png').default;

const SelectList: React.FC<any> = (props) => {
  const ref = useRef(false);
  const {
    dataBinding = [],
    width,
    height,
    attributes = {},
    changeInput,
    setDefaultValue,
    onPress,
    initializeList,
  } = props;

  const {
    opacity,
    backgroundColor,
    color,
    borderRadius,
    fontSize,
    fontFamily,
    items,
    subTitle2,
    selectDatabase,
  } = attributes;

  const list = useMemo(() => {
    return get(attributes, 'itemsSeparate', '')
      .split('\n')
      .map((item: string, index: number) => ({
        id: index,
        label: item.trim(),
        value: item.trim(),
      }))
      .filter(
        (item: Record<string, any>) =>
          !isNil(item.label) || !isEmpty(item.label)
      );
  }, [attributes]);

  const getInitValues = () => {
    if (Platform.OS !== 'web') {
      // App
      if (dataBinding) {
        return Object.keys(dataBinding)?.length === 0 ? items : dataBinding;
      }
      return items;
    } else {
      // web
      const search = queryString.parse(window?.location?.search);
      const target = search?.target;
      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return items;
      }
    }
  };

  const listData =
    selectDatabase || selectDatabase == undefined ? getInitValues() : list;

  const listOptions = getValueBinding('', first(dataBinding), props);

  const valueInputs = getValueInputDependencies(props);

  const handlePress = (item: Record<string, any>) => {
    const options = {
      itemListClick: getItemListClick(item),
    };
    changeInput && changeInput(item?.value || item?.id);
    onPress && onPress(getActions(props, 'action'), options);
  };

  useEffect(() => {
    const path = `${selectDatabase ? 'defaultData' : 'defaultSeparate'}.text`;
    const defaultVal = get(listOptions, path);

    if (!isEmpty(defaultVal) && !ref.current) {
      setDefaultValue && setDefaultValue(defaultVal);
      ref.current = true;
    }
  }, [listOptions, ref.current]);

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;
  const tableId = get(props, 'attributes.items.source.tableId');

  if (target && !tableId && !props?.loading && selectDatabase)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: props.zIndex,
        }}
      >
        <EmptyList attributes={props} />
      </View>
    );

  if (initializeList) {
    return <Loading />;
  }

  return (
    <View
      style={{
        width,
        height,
        backgroundColor: backgroundColor,
        borderTopRightRadius: borderRadius,
        borderTopLeftRadius: borderRadius,
        opacity,
      }}
    >
      <View
        style={{
          width,
          height: 58,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderTopRightRadius: borderRadius,
          borderTopLeftRadius: borderRadius,
          backgroundColor: 'white',
        }}
      >
        <Text
          style={{
            maxWidth: width - 50,
            fontFamily: checkFont(fontFamily),
            fontSize,
            fontWeight: 'bold',
            color,
          }}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {get(listOptions, 'title.text')}
        </Text>
      </View>
      {subTitle2?.enabled ? (
        <View
          style={{
            width,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              marginTop: 15,
              marginBottom: 15,
              marginRight: 40,
              marginLeft: 40,
              fontFamily: checkFont(fontFamily),
              fontSize,
              color,
              textAlign: 'center',
            }}
            numberOfLines={3}
            ellipsizeMode="tail"
          >
            {get(listOptions, 'subTitle2.text')}
          </Text>
        </View>
      ) : null}
      <FlatList
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        maxToRenderPerBatch={4}
        scrollEnabled={true}
        data={Array.isArray(listData) ? listData : []}
        renderItem={({ item }) => {
          const bindingValue = getValueBinding('', item, props);

          return (
            <TouchableOpacity
              style={{
                width,
                height: 58,
                marginTop: 3,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
              }}
              onPress={() => handlePress(item)}
            >
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{
                    width: width - 50,
                    fontFamily: checkFont(fontFamily),
                    fontSize,
                    color,
                  }}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {selectDatabase || selectDatabase == undefined
                    ? get(bindingValue, 'subTitle.text')
                    : item.value}
                </Text>
              </View>
              {valueInputs === item?.value || valueInputs === item?.id ? (
                <Image
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                  source={{
                    uri: CHECKED,
                  }}
                />
              ) : null}
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};

export default SelectList;
