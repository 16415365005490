import React from 'react';
import { View, Image, Platform } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { isEmpty } from 'lodash';
type Props = {
  imageURL: string;
  placeholder: string;
};

const ImageList = ({ imageURL, placeholder }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <View
      style={{
        backgroundColor: '#cccccc',
        height: 50,
        width: 50,
        borderRadius: 3,
      }}
    >
      {isLoading ? null : (
        <ContentLoader
          speed={3}
          viewBox="0 0 300 300"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <Rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      )}
      <Image
        style={{
          height: '100%',
          width: '100%',
          display: isLoading || Platform.OS !== 'web' ? 'flex' : 'none',
          borderRadius: 5,
        }}
        source={{
          uri:
            typeof imageURL !== 'string' || isEmpty(imageURL)
              ? placeholder
              : imageURL,
        }}
        onError={() => {
          setIsLoading(false);
        }}
        onLoad={() => {
          setIsLoading(true);
        }}
      />
    </View>
  );
};

export default ImageList;
