import { ITable } from '@common/types/index';

const createStyles = (attributes: ITable) => ({
  height: attributes.height,
  width: attributes.width,
  marginTop: attributes.marginTop,
  marginLeft: attributes.marginLeft,
  zIndex: attributes.zIndex,
  opacity: attributes.opacity,
});

export default createStyles;
