import React, { memo } from 'react';
import { ActivityIndicator, Text, TouchableOpacity } from 'react-native';

interface Props {
  loading?: boolean;
  isSaveCardAction: boolean;
  rememberCard: boolean;
  handleSubmit: (...args: any) => void;
  styles: any;
  text: string;
}
const SubmitButton: React.FC<Props> = ({
  loading,
  isSaveCardAction,
  rememberCard,
  handleSubmit,
  styles,
  text,
}) => {
  const inactiveStyle = isSaveCardAction && !rememberCard;

  if (loading) {
    return <ActivityIndicator size="small" />;
  }
  return (
    <TouchableOpacity
      style={[
        inactiveStyle ? styles.clickInactive : styles.clickActive,
        { flexDirection: loading ? 'row' : 'column' },
      ]}
      onPress={handleSubmit}
    >
      <Text style={styles.label}>{`${text}`}</Text>
    </TouchableOpacity>
  );
};
export default memo(SubmitButton);
