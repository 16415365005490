import { ICheckBox } from '@common/types/element';
import { get } from 'lodash';
import React, { FC, useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { TouchableOpacity } from 'react-native';
import { setFormValue } from '@common/redux/slice/formInputs';
import Icon from 'react-native-vector-icons/MaterialIcons';

const Checkbox: FC<ICheckBox> = (attributes) => {
  const dispatch = useDispatch();
  const { id } = attributes;
  const [
    state,
    // setstate
  ] = useState({
    activeColor: attributes?.activeColor || '',
    inactiveColor: attributes?.inactiveColor || '',
    activeIcon: attributes?.activeIcon || 'check-box',
    inactiveIcon: attributes?.inactiveIcon || 'check-box-outline-blank',
  });

  const valueInputs = useMemo(
    () => get(attributes, `dependencies.valueFormInputs.${id}`, false),
    [attributes.dependencies, id]
  );
  const changeValue = useCallback(
    (value: any) => {
      dispatch(setFormValue({ [attributes.id]: value }));
    },
    [dispatch, attributes.id]
  );

  return (
    <TouchableOpacity
      onPress={() => {
        changeValue(!valueInputs);
      }} //{() => changeValue(id, !value)}
      activeOpacity={0.4}
      hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
      //   style={layout}
    >
      <Icon
        size={24}
        name={valueInputs ? state.activeIcon : state.inactiveIcon}
        color={valueInputs ? state.activeColor : state.inactiveColor}
      />
    </TouchableOpacity>
  );
};

export default Checkbox;
