import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'borderRadius',
        'opacity',
        'height',
        'width',
      ]),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      borderStyle:
        attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      zIndex: attributes.zIndex,
      overflow: 'hidden',
      position: 'relative',
    },
  });
};

export default createStyles;
