import { pick } from 'lodash';
import { Platform, StyleSheet } from 'react-native';
import { checkFont } from '../func';

const createStyles = (attributes: any) => {
  const getShadow = () => {
    if (!attributes.shadow) return {};

    return {
      shadowColor: attributes.shadow.color,
      shadowRadius: attributes.shadow.size,
      shadowOpacity: attributes?.shadow?.blur || 0,
      shadowOffset: {
        width: attributes.shadow.x,
        height: attributes.shadow.y,
      },
      elevation:
        attributes?.shadow?.size === 0
          ? attributes?.shadow?.x
          : attributes?.shadow?.size,
    };
  };

  const getFontWeight = () => {
    if (!attributes.fontWeight) return 'normal';

    return typeof attributes.fontWeight === 'string'
      ? attributes.fontWeight
      : attributes.fontWeight.toString();
  };

  return StyleSheet.create({
    container: {
      ...pick(attributes, ['marginTop', 'marginLeft', 'width', 'height']),
      zIndex: attributes.zIndex,
    },
    wrapper: {},
    text: {
      ...pick(attributes, ['color', 'width', 'borderRadius']),
      // ...(attributes?.shadow?.size > 0 ? getShadow() : {}),
      fontWeight: getFontWeight(),
      fontFamily: checkFont(attributes.fontFamily),
      textAlign: attributes.textAlignment,
      ...pick(attributes, [
        'backgroundColor',
        // 'fontSize',
        'padding',
        'opacity',
      ]),
      fontSize:
        attributes.fontSize < 13 && attributes.multiline === true
          ? attributes.fontSize * 1.15
          : attributes.fontSize,
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      borderStyle:
        attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      minHeight: attributes.height,
      // maxHeight: attributes.maxHeight,
      ...getShadow(),
      paddingLeft: 10,
      // paddingTop: 0,
      // paddingBottom: 0,
      // padding: 0,
      // margin: 0,
      display: 'flex',
      ...Platform.select({
        ios: {
          paddingTop: attributes?.multiline
            ? attributes?.height < attributes?.fontSize * 1.15 + 10
              ? 0
              : 10
            : 0,
          paddingBottom: attributes?.multiline
            ? attributes?.height < attributes?.fontSize * 1.15 + 20
              ? 0
              : 10
            : 0,
        },
        android: {
          paddingTop: 0,
          paddingBottom: 0,
          includeFontPadding: attributes?.multiline ? true : false,
        },
        web: {
          maxHeight: attributes.height,
        },
      }),
    },
    icon: {
      position: 'absolute',
      right: 10,
    },
  });
};

export default createStyles;
