export default {
  name: 'CustomerChat',
  displayName: {
    en: 'CustomerChat',
    ja: 'CustomerChat',
  },
  defaultWidth: 375,
  defaultHeight: 250,
  minWidth: 200,
  minHeight: 200,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'buyerClick',
      displayName: {
        en: 'After the buyer presses the send message button',
        ja: 'After the buyer presses the send message button',
      },
      type: 'action',
    },
    {
      name: 'sellerClick',
      displayName: {
        en: 'After the seller presses the send message button',
        ja: 'After the seller presses the send message button',
      },
      type: 'action',
    },
    {
      name: 'orderId',
      displayName: { en: 'Order ID', ja: '注文ID' },
      type: 'text',
      default: '',
    },
    {
      name: 'chatHistory',
      displayName: { en: 'Chat History', ja: 'チャット履歴' },
      type: 'text',
      default: '',
    },
    {
      name: 'buyerEmail',
      displayName: { en: 'Buyer Mail', ja: 'バイヤーメール' },
      type: 'text',
      default: '',
    },
    {
      name: 'buyerAvatar',
      displayName: { en: "Buyer's Avatar URL", ja: '購入者のアバターのURL' },
      type: 'text',
      default: '',
    },
    {
      name: 'sellerAvatar',
      displayName: { en: "Seller's Avatar URL", ja: '売り手のアバターのURL' },
      type: 'text',
      default: '',
    },
    {
      name: 'backgroundColor',
      displayName: {
        en: 'Background Color',
        ja: 'Background Color',
      },
      type: 'color',
      default: '#9B9B9B',
    },
    {
      name: 'activeColor',
      displayName: {
        en: 'Active Color',
        ja: 'Active Color',
      },
      type: 'color',
      default: '#FFBB00',
    },
    {
      name: 'borderColor',
      displayName: {
        en: 'Border Color',
        ja: 'Border Color',
      },
      type: 'color',
      default: 'transparent',
      enabled: {
        border: 'true',
      },
    },
    {
      name: 'borderRadius',
      type: 'number',
      displayName: {
        en: 'Border Radius',
        ja: 'Border Radius',
      },
      enabled: {
        showProp: true,
      },
      default: 1,
    },
    {
      name: 'borderWidth',
      displayName: {
        en: 'Border Width',
        ja: 'Border Width',
      },
      type: 'number',
      default: 1,
      enabled: {
        bottomBorder: true,
      },
    },
  ],
};
