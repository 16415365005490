import * as _ from 'lodash';
import { useEffect } from 'react';

const useWatchRealtime = (
  clusterRef: any,
  showCurrentUserLocation: boolean,
  getCurrentUserMaker: any
) => {
  const watchSuccess = (position: any) => {
    if (position && showCurrentUserLocation && clusterRef.current) {
      const currentUserMarker = getCurrentUserMaker(clusterRef.current);
      if (!_.isEmpty(currentUserMarker)) {
        currentUserMarker.setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
    }
  };

  const watchError = (err: any) => {
    console.log('err', err);
  };

  useEffect(() => {
    if (!showCurrentUserLocation) return;

    let watchId = navigator.geolocation.watchPosition(
      watchSuccess,
      watchError,
      {
        enableHighAccuracy: true,
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, [showCurrentUserLocation, clusterRef]);

  return location;
};

export default useWatchRealtime;
