import { ActionStatus } from '@common/constants/shared';
import { MetadataAction } from '@common/types/action';
import { get } from 'lodash';
import { ActionResponse } from '../excuteAction';

export const customAction: (
  action: any,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetadataAction
) => {
  const { messages } = metadata;
  if (action.status === ActionStatus.ERROR) {
    return {
      status: ActionStatus.FAILED,
      message: get(action, 'error.message'),
    };
  }
  return {
    status: ActionStatus.SUCCESS,
    message: messages['custom_action_completed'],
  };
};
