import { appInfoSelector } from '@common/redux/selectors/app';
import {
  createAppleTouchIcon,
  createManifest,
} from '@common/screens/ShareScreen/SetupShare';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function HelmetCustom({ screen }: any) {
  const appInfo = useSelector(appInfoSelector);

  if (!appInfo || !screen) return <Fragment></Fragment>;

  useEffect(() => {
    async function setupManifest() {
      if (!appInfo) return;
      const data = appInfo;
      const manifestEl = document.querySelector("link[rel='manifest']");
      const appleTouchIconEl = document.querySelector(
        "link[rel='apple-touch-icon']"
      );

      const maniFestHref = createManifest(data?.appId, data?.name, data?.icon);
      const appleTouchIcon = createAppleTouchIcon(data?.icon);
      manifestEl?.setAttribute('href', await maniFestHref);
      appleTouchIconEl?.setAttribute('href', appleTouchIcon);
    }
    setupManifest();
  }, [appInfo]);

  return (
    <Helmet>
      <title>{`${appInfo?.name} | ${screen?.name}`}</title>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${appInfo.metadata.ga4?.id}`}
      ></script>
      <script>
        {` window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
          gtag('config', '${appInfo.metadata.ga4?.id}');`}
      </script>
    </Helmet>
  );
}

export default HelmetCustom;
