import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    text: {
      textAlign: 'center',
      fontSize: 13,
      fontWeight: '900',
      justifyContent: 'center',
      alignSelf: 'center',
      minHeight: 50,
    },
  });
};

export default createStyles;