import enMessages from '../locales/en-US.json';

const EnLang = {
  messages: {
    ...enMessages,
  },
  locale: 'en',
};

export default EnLang;
