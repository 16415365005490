import React from 'react';
import BloctoButton from './BloctoButton';
import { useBlocto } from './hook';
import { BloctoElementType } from './type';

const Blocto: React.FC<BloctoElementType> = (props) => {
  return <BloctoButton {...{ ...props, ...useBlocto(props) }} />;
};

export default Blocto;
