import { Locale } from '@common/constants/shared';
import EnLang from './entries/en_US';
import JaLang from './entries/ja-JP';

const AppLocale: Record<keyof typeof Locale, any> = {
  en: EnLang,
  ja: JaLang,
};

export default AppLocale;
