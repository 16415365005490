import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { IconButton } from 'react-native-paper';
import PointIcon from '../CustomIcon';

type Props = {
  icon: string;
  color?: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
  handlePress?: () => any;
};

const IconList = ({
  icon,
  color = '#bbb',
  size = 24,
  style,
  handlePress,
}: Props) => {
  return (
    <View
      style={{
        height: 50,
        width: 50,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {icon && icon === 'point' ? (
        <PointIcon width={size} height={size} fill={color} />
      ) : (
        <IconButton
          icon={icon}
          size={size}
          color={color}
          style={style}
          onPress={handlePress}
        />
      )}
    </View>
  );
};

export default IconList;
