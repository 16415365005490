import { BINDING_SELECTOR_TYPE } from '@common/constants/shared';
import store from '@common/redux/store';
import { get, omit } from 'lodash';
import { IAction, IForm } from '@common/types';

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
