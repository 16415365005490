import { IImageUpload } from '@common/types/element';
import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: IImageUpload) => {
  const imageHeight = attributes.height - 30;
  return StyleSheet.create({
    text: {
      fontSize: 16,
      color: 'orange',
      fontFamily: 'Roboto',
    },
    container: {
      ...pick(attributes, [
        'opacity',
        'width',
        'height',
        'marginTop',
        'marginLeft',
        'borderRadius',
        'backgroundColor',
      ]),
      ...pick(attributes, ['borderWidth', 'borderColor']),
      zIndex: attributes.zIndex,
      alignItems: 'center',

      // borderStyle:
      //   attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
    },
    flatlist: {
      alignItems: 'center',
    },
    innerWrapper: {
      width: (imageHeight * 4) / 3,
      height: imageHeight,
      marginHorizontal: 5,
      backgroundColor: '#ffffff',
      // borderStyle:
      //   attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
    },
    preview: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#eee',
    },
    previewImage: {
      position: 'absolute',
      // left: 0,
      // top: 0,
      width: '100%',
      height: '100%',
    },
    faded: {
      opacity: 0.5,
    },
    icon: {
      opacity: 0.2,
      width: 40,
      height: 30,
    },
    emptyWrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    selectImage: {
      color: '#06f',
      fontWeight: '500',
      fontSize: 14,
    },
    selectAnother: {
      marginTop: 8,
      alignItems: 'center',
    },
    indexNumber: {
      width: 16,
      height: 16,
      backgroundColor: '#d0d0d0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      zIndex: 100,
      top: 0,
      left: 0,
    },
    removeAction: {
      width: 16,
      height: 16,
      backgroundColor: '#d0d0d0',
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      zIndex: 150,
      top: 0,
      right: 0,
    },
  });
};
export default createStyles;
