import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      opacity: 0,
      ...pick(attributes, ['width', 'borderRadius', 'zIndex']),
      minHeight: attributes.height,
    },
  });
};

export default createStyles;
