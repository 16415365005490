import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  locale: 'en' | 'ja';
};

const initialState: State = { locale: 'ja' };

const languageSlice = createSlice({
  name: 'languageSetting',
  initialState: initialState,
  reducers: {
    setLanguage: (state: State, action: PayloadAction<any>) => {
      if (action.payload) {
        state.locale = action.payload;
      }
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
