import { useCallback } from 'react';
import { PlanPermissionResponse, PlanPermissionKey } from '@common/types/plan';
import api from '@common/configs/api';
import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { setPlanPermission } from '@common/redux/slice/planPermission';

export const usePlanPermission = () => {
  const dispatch = useDispatch();
  const checkShowPlanModalByNumberOfRecords = useCallback(
    async (appid: string) => {
      const {
        data: permissionResponse,
      }: AxiosResponse<PlanPermissionResponse> = await api({
        method: 'get',
        url: API_ENDPOINTS.PLAN_PERMISSION,
        params: {
          keys: [PlanPermissionKey.numberOfRecords],
          appid,
        },
      });
      if (permissionResponse.numberOfRecords) {
        if (
          permissionResponse.numberOfRecords.current >=
          permissionResponse.numberOfRecords.max
        ) {
          dispatch(setPlanPermission(permissionResponse));
          alert('プランの上限を超えています。管理者に問い合わせてください。');
          return false;
        }
      }
      return true;
    },
    []
  );

  return {
    checkShowPlanModalByNumberOfRecords,
  };
};
