import { defaultURL } from '@common/constants/shared';
import { ImagePicker } from '@common/utils/upload';
import { get, isEmpty, isString, isUndefined } from 'lodash';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from '@common/redux/selectors/language';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import connectorInput from '../ConnectorInput';
import createStyles from './style';

const CusImageUpload: FC<any> = (attributes) => {
  const locale = useSelector(localeSelector);
  const { placeholder } = attributes;
  const { valueInput, changeInput } = attributes;

  const [error, setError] = useState('');

  const styles = createStyles(attributes);

  const [pending, setPending] = useState(false);
  const isPlaceholderImage =
    placeholder?.placeholderImage && placeholder?.placeholderImageEnabled;

  const handlePress = () => {
    const imageCompress = isUndefined(placeholder?.imageCompress)
      ? true
      : placeholder?.imageCompress;

    if (Platform.OS !== 'web') return;
    setPending(true);
    error && setError('');
    ImagePicker.showImagePicker(
      {
        quality: 0.7,
        setPending,
        imageUpload: true,
        setError,
        attributes,
        imageCompress,
      },
      (response: any) => {
        if (response?.url) {
          setPending(false);
          changeInput(response);
        }
      }
    );
  };

  const Loading = () => {
    if (!pending) return null;
    return (
      <View>
        <ActivityIndicator />
        <Text style={{ color: 'white' }}>Uploading...</Text>
      </View>
    );
  };

  const uri =
    error && isPlaceholderImage
      ? placeholder.placeholderImage
      : error
      ? defaultURL
      : isString(valueInput)
      ? valueInput
      : get(valueInput, 'url');

  if (!isEmpty(valueInput)) {
    return (
      <React.Fragment>
        <View style={styles.innerWrapper}>
          <TouchableOpacity onPress={handlePress} style={{ flex: 1 }}>
            <View style={styles.preview}>
              <Image
                style={[styles.previewImage, pending ? styles.faded : {}]}
                source={{
                  uri,
                }}
                resizeMode={
                  attributes?.defaultValue?.imageResize === 'contain'
                    ? 'contain'
                    : 'cover'
                }
                onError={({ nativeEvent }: any) => {
                  setError(nativeEvent?.error);
                }}
              />
              <Loading />
            </View>
          </TouchableOpacity>
        </View>
      </React.Fragment>
    );
  } else {
    return (
      <View style={styles.innerWrapper}>
        <ImageBackground
          source={{
            uri: isPlaceholderImage ? placeholder.placeholderImage : null,
          }}
          resizeMode={
            attributes?.defaultValue?.imageResize === 'contain'
              ? 'contain'
              : 'cover'
          }
          style={styles.image}
        >
          <TouchableOpacity style={styles.emptyWrapper} onPress={handlePress}>
            <Loading />

            {!valueInput && !pending && (
              <Text style={styles.text}>
                写真の選択
                {/* {locale === 'ja' ? '写真の選択' : 'Choose Photo'} */}
              </Text>
            )}
          </TouchableOpacity>
        </ImageBackground>
      </View>
    );
  }
};

export default connectorInput(CusImageUpload);
