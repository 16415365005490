import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { Checkbox } from 'react-native-paper';

import { checkFont } from '../func';
import { FincodeBindingValue } from './hook';

type Props = {
  bindingValue: Pick<
    FincodeBindingValue,
    'rememberCheckbox.text' | 'rememberCheckbox.icon'
  >;
  attributes: {
    activeColor: string;
    inactiveColor: string;
  };
  rememberCard: boolean;
  fontFamily: string;
  onClickCheckbox: () => void;
};
export const RememberCheckbox: React.FC<Props> = ({
  bindingValue,
  attributes,
  rememberCard,
  fontFamily,
  onClickCheckbox,
}) => {
  const { activeColor = 'orange', inactiveColor = 'orange' } = attributes;
  const styles = StyleSheet.create({
    rememberCard: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 40,
      position: 'relative',
    },
    rememberCardIosUncheck: {
      marginRight: 5,
      width: 20,
      height: 20,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: '#BDBDBD',
    },
    title: {
      fontFamily: checkFont(fontFamily),
      fontSize: 12,
    },
  });
  return (
    <View style={styles.rememberCard}>
      <View
        style={[
          Platform.OS === 'ios' && !rememberCard
            ? styles.rememberCardIosUncheck
            : {},
        ]}
      >
        <Checkbox
          status={rememberCard ? 'checked' : 'unchecked'}
          uncheckedColor={inactiveColor || 'orange'}
          onPress={onClickCheckbox}
          color={activeColor || 'orange'}
        />
      </View>
      <Text style={styles.title}>{bindingValue['rememberCheckbox.text']}</Text>
    </View>
  );
};
