export default {
  name: 'ChipList',
  displayName: {
    en: 'Tag List',
    ja: 'タグリスト',
  },
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 528,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'sortList',
      displayName: {
        en: 'Sorting',
        ja: '並び替え',
      },
      type: 'sort',
    },
    {
      name: 'maximumItems',
      displayName: {
        en: 'Maximum number of items',
        ja: '上限',
      },
      type: 'maximumItems',
    },
    {
      name: 'selectedColor',
      displayName: {
        en: 'Selected Color',
        ja: '選択時の色',
      },
      type: 'color',
      default: '#4259AC',
    },
    {
      name: 'selectedFontColor',
      displayName: {
        en: 'Selected Font Color',
        ja: '選択したフォントの色',
      },
      type: 'color',
      default: '#ffffff',
    },
    {
      name: 'columnCount',
      displayName: {
        en: 'Columns',
        ja: '列',
      },
      type: 'number',
      default: 1,
      control: {
        type: 'menu',
        options: [
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
        ],
      },
      enabled: {
        horizontalScroll: false,
      },
    },
    {
      name: 'onPress',
      displayName: {
        en: 'List',
        ja: 'リスト',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
  ],
  childComponents: [
    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Title',
          styles: {
            fontFamily: '@body',
            color: '@text',
            fontWeight: '500',
            fontSize: '20',
            textAlignment: 'left',
          },
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
      ],
    },
    {
      displayName: {
        en: 'Image',
        ja: '画像',
      },
      name: 'cardCover',
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'image',
          displayName: {
            en: 'Image Source',
            ja: '画像ソース',
          },
          type: 'image',
        },
      ],
    },

    {
      name: 'closeIcon',
      displayName: {
        en: 'Icon',
        ja: 'アイコン',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'iconType',
          displayName: {
            en: 'Type',
            ja: 'タイプ',
          },
          type: 'text',
          default: 'icon',
          control: {
            type: 'menu',
            options: [
              {
                label: 'Icon',
                value: 'icon',
              },
            ],
          },
        },
        {
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          name: 'icon',
          type: 'icon',
          default: 'close-circle',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'onPress',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
          type: 'action',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'color',
          displayName: {
            en: 'Text Color',
            ja: 'テキスト色',
          },
          type: 'color',
          default: '#ccc',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'input',
          displayName: {
            en: 'What does this toggle?',
            ja: 'データを選択してください',
          },
          type: 'boolean',
          role: 'autosaveInput',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeIcon',
          displayName: {
            en: 'Active Icon',
            ja: 'アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeColor',
          displayName: {
            en: 'Active Color',
            ja: 'アクティブ時の色',
          },
          type: 'color',
          default: '@primary',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveIcon',
          displayName: {
            en: 'Inactive Icon',
            ja: '非アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box-outline-blank',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveColor',
          displayName: {
            en: 'Inactive Color',
            ja: '非アクティブ時の色',
          },
          type: 'color',
          default: '#bbb',
          enabled: {
            iconType: 'toggle',
          },
        },
      ],
    },
  ],
};
