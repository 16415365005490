import * as fcl from '@onflow/fcl';
import { useEffect, useState, useMemo } from 'react';

import {
  AccountProofDataResolver,
  AccountProofScope,
  BloctoAuthnScope,
  CurrentUserObject,
} from '@common/hooks/useBlocto';
import { IAppInfo } from '@common/types';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import { BloctoBindingValue, BloctoElementType } from './type';

const configFcl = async ({
  accountProofDataResolver,
  fclConfig,
  email,
}: {
  fclConfig: NonNullable<IAppInfo['metadata']['fcl']>;
  email?: string;
  accountProofDataResolver: () => ReturnType<AccountProofDataResolver>;
}) => {
  const bloctoAppId = fclConfig.bloctoAppId || '-';
  const networkConfig = {
    testnet: {
      'accessNode.api': 'https://rest-testnet.onflow.org', // connect to Flow testnet
      'discovery.wallet': `https://wallet-v2-dev.blocto.app/${bloctoAppId}/flow/authn/${
        email || ''
      }`, // use Blocto testnet wallet
      'fcl.accountProof.resolver': accountProofDataResolver,
    },
    mainnet: {
      'accessNode.api': 'https://rest-mainnet.onflow.org', // connect to Flow testnet
      'discovery.wallet': `https://wallet-v2.blocto.app/${bloctoAppId}/flow/authn/${
        email || ''
      }`, // use Blocto mainnet wallet
      'fcl.accountProof.resolver': accountProofDataResolver,
    },
  };
  fcl.config(networkConfig[fclConfig.network]);
};

export const useBlocto = (props: BloctoElementType) => {
  const {
    isInCustomList,
    data,
    onPress,
    record = null,
    login,
    getAccountResolve,
  } = props;
  const appId = props.dependencies?.appInfor?.appId;

  const [loginFetching, setLoginFetching] = useState(false);
  const [initializing, setInitializing] = useState<boolean>();

  const bindingValue = getValueBinding(
    isInCustomList ? props.initId : props.id,
    isInCustomList ? props?.record : data,
    props
  ) as BloctoBindingValue;

  const handlePress = async () => {
    setLoginFetching(true);
    try {
      const auth: CurrentUserObject = await fcl.reauthenticate();
      console.log('===auth', auth);
      await onAuthSuccess(auth);
    } catch (e) {
      console.error('===press error', e);
    } finally {
      setLoginFetching(false);
    }
  };

  const onAuthSuccess = async (auth: CurrentUserObject) => {
    setLoginFetching(true);
    try {
      const bloctoAuthnScrop = auth.services.find(
        (service) => service.uid === 'blocto#authn'
      ) as BloctoAuthnScope;
      const accountProof = auth.services.find(
        (service) => service.type === 'account-proof'
      ) as AccountProofScope;
      const email = bloctoAuthnScrop?.scoped?.email;
      const accountProofData = accountProof?.data;
      if (email && accountProofData) {
        await login({
          appId,
          accountProofData,
          email,
          expiresAt: auth.expiresAt,
        });
        onPress &&
          (await onPress(getActions(props, 'action'), {
            itemListClick: getItemListClick(record),
          }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoginFetching(false);
    }
  };

  useEffect(() => {
    const fclConfig = props.dependencies?.appInfor?.metadata.fcl;
    const isCanvas =
      window?.location?.href &&
      (window.location.href.split('/').includes('canvas') ||
        window.location.href.split('/').includes('view'));
    if (!isCanvas && fclConfig) {
      setInitializing(true);
      configFcl({
        accountProofDataResolver: () => getAccountResolve(appId),
        fclConfig,
        email: bindingValue.initEmail || '',
      }).then(() => {
        setInitializing(false);
      });
    }
  }, [bindingValue.initEmail]);

  useEffect(() => {
    if (initializing === false) {
      fcl.unauthenticate();
    }
  }, [initializing]);

  return {
    loginFetching,
    handlePress,
    initializing,
  };
};

export type UseBloctoHook = ReturnType<typeof useBlocto>;
