export default {
  name: 'Toggle',
  displayName: {
    en: 'Toggle',
    ja: 'トグル',
  },
  icon: 'icon.png',
  defaultWidth: 24,
  defaultHeight: 24,
  props: [
    {
      name: 'input',
      displayName: {
        en: 'Default Value',
        ja: '初期値',
      },
      type: 'boolean',
      role: 'autosaveInput',
    },
    {
      name: 'activeIcon',
      displayName: {
        en: 'Active Icon',
        ja: 'アクティブ時のアイコン',
      },
      type: 'icon',
      default: 'check-box',
    },
    {
      name: 'activeColor',
      displayName: {
        en: 'Active Color',
        ja: 'アクティブ時の色',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'inactiveIcon',
      displayName: {
        en: 'Inactive Icon',
        ja: '非アクティブ時のアイコン',
      },
      type: 'icon',
      default: 'check-box-outline-blank',
    },
    {
      name: 'inactiveColor',
      displayName: {
        en: 'Inactive Color',
        ja: '非アクティブ時の色',
      },
      type: 'color',
      default: '#bbb',
    },
    // {
    //   name: 'toggleSize',
    //   displayName: 'Size',
    //   type: 'number',
    //   default: 24,
    //   control: {
    //     type: 'slider',
    //     max: 70,
    //     min: 1,
    //   },
    // },
    {
      name: 'inactiveActions',
      displayName: {
        en: 'ClickFlow when toggle is active',
        ja: 'アクティブ時の動作',
      },
      type: 'action',
    },
    {
      name: 'activeActions',
      displayName: {
        en: 'ClickFlow when toggle is inactive',
        ja: '非アクティブ時の動作',
      },
      type: 'action',
    },
    {
      name: 'clickActions',
      displayName: {
        en: 'ClickFlow when toggle is clicked',
        ja: 'トグルアイコンをクリック時の動作',
      },
      type: 'action',
    },
  ],
  resizeX: false,
  resizeY: false,
};
