import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import { appInfoSelector } from '@common/redux/selectors/app';
import { profileSelector } from '@common/redux/selectors/auth';
import { pagesSelector } from '@common/redux/selectors/page';
import { setError } from '@common/redux/slice/app';
import { setDatabaseRefresh } from '@common/redux/slice/database';
import { useHistory, useLocation } from '@common/routes/hooks';
import feathers from '@common/services/featherIO/feathers.io';
import {
  CreateRecordsEventDispatchData,
  PatchRecordsEventDispatchData,
} from 'click-types';
import { get } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

type Data = Record<string, any>;

const useWatchRecords = (): any => {
  const { messages } = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  const app = useSelector(appInfoSelector);
  const pages = useSelector(pagesSelector);
  const query = useMemo(() => qs.parse(search), [search]);
  const startHomePage = useMemo(() => app?.metadata?.startHomePage, [app]);

  const pageInfo = useMemo(
    () => get(pages, query?.target || '', null),
    [pages, query?.target]
  );

  const updateAutomaticTable = useCallback(
    (data: Record<string, any>) => {
      const listenForChanges = get(pageInfo, 'attributes.autoRefresh', true);
      if (!listenForChanges) return;
      dispatch(setDatabaseRefresh(data));
    },
    [dispatch, pageInfo]
  );

  const handleRemoveLoggedUser = useCallback(() => {
    //remove user logged in
    history.replace({
      search: qs.stringify({
        ...query,
        ...{
          target: startHomePage,
        },
      }),
    });

    dispatch(setError({ message: messages['user_login_deleted'] }));
  }, [history, query, startHomePage, dispatch, messages]);

  useEffect(() => {
    const recordService = feathers.service(API_ENDPOINTS.V3_RECORDS);

    const recordDeleteMultiService = feathers.service(
      API_ENDPOINTS.V3_DELETE_MULTIPLE_RECORDS
    );

    const uploadCsvService = feathers.service(API_ENDPOINTS.V3_CSV_UPLOAD);

    const handleAutomaticUpdate = (
      data: CreateRecordsEventDispatchData | PatchRecordsEventDispatchData
    ) => {
      updateAutomaticTable(data);
    };

    const recordDeleteMulti = (data: Data) => {
      updateAutomaticTable(data);
      if ((data?.ids || []).includes(profile?.id)) {
        handleRemoveLoggedUser();
      }
    };

    // recordService
    recordService.on('created', handleAutomaticUpdate);
    recordService.on('removed', recordDeleteMulti);
    recordService.on('patched', handleAutomaticUpdate);

    // recordDeleteMultiService
    recordDeleteMultiService.on('created', recordDeleteMulti);
    uploadCsvService.on('created', handleAutomaticUpdate);

    return () => {
      recordService.removeListener('created', handleAutomaticUpdate);
      recordService.removeListener('removed', recordDeleteMulti);
      recordService.removeListener('patched', handleAutomaticUpdate);
      recordDeleteMultiService.removeListener('created', recordDeleteMulti);
      uploadCsvService.removeListener('created', handleAutomaticUpdate);
    };
  }, [dispatch, profile, handleRemoveLoggedUser, updateAutomaticTable]);
};

export default useWatchRecords;
