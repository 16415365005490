export default {
  name: 'Carousel',
  displayName: {
    en: 'Carousel',
    ja: 'カルーセル',
  },
  icon: './icon.png',
  defaultWidth: 300,
  defaultHeight: 250,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'onPress',
      displayName: {
        en: 'List',
        ja: 'リスト',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
  ],
  childComponents: [
    {
      displayName: {
        en: 'Image',
        ja: '画像',
      },
      name: 'cardCover',
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'image',
          displayName: {
            en: 'Image',
            ja: '画像',
          },
          type: 'image',
        },
      ],
    },
    {
      name: 'autoScroll',
      displayName: {
        en: 'AutoScroll',
        ja: 'オートスクロール',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'autoScrollSpeed',
          displayName: {
            en: 'Autoscroll Speed (seconds)',
            ja: 'オートスクロールの速度 (秒)',
          },
          default: 4,
          type: 'number',
          control: {
            type: 'slider',
            max: 10,
            min: 1,
          },
        },
      ],
    },
    {
      name: 'slideIndicators',
      displayName: {
        en: 'Slide Indicators',
        ja: 'インジケーター',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'position',
          displayName: {
            en: 'Position',
            ja: 'インジケーターの位置',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'Outside',
                value: 1,
              },
              {
                label: 'Inside',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'arrows',
      displayName: {
        en: 'Arrows',
        ja: '矢印',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'leftIcon',
          displayName: {
            en: 'Left Icon',
            ja: '左アイコン',
          },
          type: 'icon',
          default: 'chevron-left',
        },
        {
          name: 'rightIcon',
          displayName: {
            en: 'Right Icon',
            ja: '右アイコン',
          },
          type: 'icon',
          default: 'chevron-right',
        },
        {
          name: 'iconColor',
          displayName: {
            en: 'Icon Color',
            ja: 'アイコン色',
          },
          type: 'color',
          defaut: '#000000',
        },
        {
          name: 'iconDisabledColor',
          displayName: {
            en: 'Icon Disabled Color',
            ja: '選択不可アイコンの色',
          },
          type: 'color',
          defaut: '#BBBBBB',
        },
      ],
    },
    {
      name: 'customImage',
      displayName: {
        en: 'Add image to tail',
        ja: 'Add image to tail',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'image1',
          displayName: {
            en: 'First Image',
            ja: 'First Image',
          },
          type: 'text',
          default: '',
        },
        {
          name: 'image2',
          displayName: {
            en: 'Second Image',
            ja: 'Second Image',
          },
          type: 'text',
          default: '',
        },
        {
          name: 'image3',
          displayName: {
            en: 'Third Image',
            ja: 'Third Image',
          },
          type: 'text',
          default: '',
        },
        {
          name: 'image4',
          displayName: {
            en: 'Fourth Image',
            ja: 'First Image',
          },
          type: 'text',
          default: '',
        },
        {
          name: 'image5',
          displayName: {
            en: 'Fifth Image',
            ja: 'Fifth Image',
          },
          type: 'text',
          default: '',
        },
      ],
    },
    {
      name: 'enableZoom',
      displayName: {
        en: 'Enable Zoom',
        ja: 'Enable Zoom',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
      ],
    },
  ],
};
