import { ActionStatus } from '@common/constants/shared';
import { setValueInput } from '@common/redux/slice/formInputs';
import store from '@common/redux/store';
import { MetadataAction } from '@common/types/action';
import { ActionResponse } from '../excuteAction';
import { get } from 'lodash';

export const setInputValue: (
  action: any,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetadataAction
) => {
  const dispatch = store.dispatch;
  const { messages } = metadata;

  if (action.status === ActionStatus.ERROR) {
    return {
      status: ActionStatus.FAILED,
      message: get(action, 'error.message'),
    };
  }

  dispatch(setValueInput(get(action, 'response', {})));

  return {
    status: ActionStatus.SUCCESS,
    message: messages['set_value_input_completed'],
  };
};
