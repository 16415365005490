import { StyleSheet } from 'react-native';

import { IStamp } from '@common/types';

const createStyles = (attributes: IStamp) => {
  return StyleSheet.create({
    container: {
      marginTop: attributes.marginTop,
      width: '100%',
      height: 535,
      zIndex: attributes.zIndex,
      alignItems: 'center',
    },
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      padding: 20,
    },
    modalView: {
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 8,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalTop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingHorizontal: 24,
      paddingVertical: 16,
      borderColor: '#f0f0f0',
      borderStyle: 'solid',
    },
    modalTopTitle: {
      margin: 0,
      color: 'rgba(0, 0, 0, 0.85)',
      fontWeight: '500',
      fontSize: 16,
      lineHeight: 22,
      display: 'flex',
      alignItems: 'center',
    },
    modalBody: {
      padding: 24,
      paddingTop: 0,
      fontSize: 14,
      lineHeight: 1.5715,
      width: '100%',
    },
    modalBodyContent: {
      position: 'relative',
    },
    button: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: 12,
      paddingHorizontal: 32,
      borderRadius: 100,
      elevation: 3,
      backgroundColor: '#34A0DD',
    },
    text: {
      fontSize: 14,
      lineHeight: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      width: 200,
      textAlign: 'center',
    },
  });
};

export default createStyles;
