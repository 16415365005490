export const OPTIONS_CARD_ELEMENT = {
  style: {
    base: {
      fontSize: '14px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#f5222d',
    },
  },
  hidePostalCode: true,
};

export const STATUS_STRIPE = {
  CANCELED: 'canceled',
  PROCESSING: 'processing',
  REQUIRES_ACTION: 'requires_action',
  REQUIRES_CAPTURE: 'requires_capture',
  REQUIRES_CONFIRMATION: 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
  SUCCEEDED: 'succeeded',
};

export const CARD = {
  CARD_NUMBER: 'cardNumber',
  CARD_EXPIRATION: 'cardExpiration',
  CARD_CVC: 'cardCvc',
  TEXT_ERROR: 'textError',
};

export const amountFormatter = (
  value: string | number,
  typeCurrency: 'usd' | 'jpy'
): number => {
  if (!value) return 0;
  if (typeCurrency === 'jpy') {
    return Math.round(Number(value)) || 0;
  } else {
    return Number(value) || 0;
  }
};

export const cardNumberFormatter = (
  oldValue: string,
  newValue: string
): string => {
  if (oldValue.length > newValue.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

export const cardExpirationDateFormatter = (
  oldValue: string,
  newValue: string
): string => {
  if (oldValue.length > newValue.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{2})/g, '$1/')
    .substring(0, 5);
};
