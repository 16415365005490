import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      // ...pick(attributes, ['marginTop', 'marginLeft']),
      ...pick(attributes, ['width', 'height', 'zIndex']),
      alignItems: 'center',
      justifyContent: 'center',
    },
    map: {
      height: attributes.height,
      width: attributes.width,
    },
    emptyKeyWrap: {
      padding: 16,
      backgroundColor: '#dd3300',
    },
    emptyKeyContent: {
      color: '#FFFFFF',
      fontWeight: '600',
      fontSize: 16,
    },
    currentLocationButton: {
      backgroundColor: '#FFFFFF',
      width: '40px',
      height: '40px',
      justifyContent: 'center',
      alignItems: 'center',
      shadowColor: 'black',
      borderRadius: 2,
      shadowOpacity: 0.26,
      elevation: 8,
      shadowOffset: { width: 1, height: 1 },
      position: 'absolute',
      right: '10px',
      bottom: '125px',
    },
    currentImage: { width: '28px', height: '28px' },
    placeholder: {
      ...pick(attributes, 'height', 'width'),
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};

export default createStyles;
