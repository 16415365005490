export default {
  CAPTURE_ONLY_FOR_CARD_PAYMENT: 'カード決済の場合のみキャプチャが可能。',
  CAPTURE_ONLY_FOR_CREDIT_CARDS:
    'オーソリはカードの種別がクレジットカードのみ可能です。デビットカード、プリペイドカード等ではオーソリは利用できません。',
  CARD_LIMIT_EXCEEDED_FOR_STORE:
    '一定期間内でのカードの合計決済額が限度額を超えました。',
  CHANGE_PROHIBITED: 'このフィールドの変更は禁止されています。',
  CHARGE_AMOUNT_TOO_HIGH: '課金金額が課金最大額より超過しています。',
  CHARGE_AMOUNT_TOO_LOW: '課金金額が課金最少額より不足しています。',
  DEPRECATED: '非推奨のパラメータです。',
  EXPIRATION_DATE_OUT_OF_BOUNDS: '有効期限が許可された範囲外です。',
  FORBIDDEN_PARAMETER:
    'このパラメータを使用する権限が不足しているか、要件を満たしていません。',
  INSTALLMENT_ALREADY_SET: '定期課金が既に開始しているため変更できません。',
  INSTALLMENT_INVALID_PLAN_TYPE: '認識できない分割払いプランです。',
  INVALID_AMOUNT: '金額は 0 より大きい整数である必要があります。',
  INVALID_CARD_BRAND: '認識できないカードブランドです。',
  INVALID_CARD_DESCRIPTOR: 'ディスクリプタの形式が正しくありません。',
  INVALID_CARD_EXPIRATION: 'カード有効期限が過ぎています。',
  REFUND_NOT_WITHIN_BOUNDS:
    '返金金額が有効な範囲ではありません。0より大きく、課金金額以下の必要があります。',
  INVALID_CARD_NUMBER: 'カード番号の形式が正しくありません。',
  INVALID_CHARGE_STATUS: '不正な課金の状態です。',
  INVALID_CURRENCY: '認識できない通貨です。',
  INVALID_CVV: 'CVVは 3から5桁の整数である必要があります。',
  INVALID_FORMAT: '不正な形式です。cardholder',
  INVALID_FORMAT_COUNTRY: '認識できない国コードです。',
  INVALID_FORMAT_CURRENCY: '認識できない通貨コードです。',
  INVALID_FORMAT_DOMAIN: 'ドメイン名の形式が不正です。',
  INVALID_FORMAT_EMAIL: 'メールアドレスの形式が不正です。',
  INVALID_LANGUAGE: '認識できない言語コードです。',
  INVALID_PHONE_NUMBER: '電話番号の形式が不正です。',
  INVALID_SCHEDULED_CAPTURE_DATE:
    'キャプチャ実行日時は、現在から1時間後かつ7日以内である必要があります。',
  INVALID_TIME_PERIOD: '認識できない期間の形式です。',
  MUST_BE_FUTURE_TIME: '未来の日時の必要があります。',
  MUST_BE_LOWER_THAN_FULL_AMOUNT: '合計金額より少ない必要があります。',
  MUST_BE_MONTH_BASED_TO_SET: '課金を行う頻度が monthly である必要があります。',
  NESTED_JSON_NOT_ALLOWED: 'ネストされた JSON の形式は使用できません。',
  NOT_SUPPORTED_BY_PROCESSOR: 'ゲートウェイでサポートされていません。',
  ONLY_FOR_CARD_PAYMENT: '支払い方法がカードでのみ利用できます。',
  ONLY_JAPANESE_PHONE_NUMBER_ALLOWED:
    '日本の電話番号のみサポートされています。',
  REQUIRED_VALUE: '必須のパラメータです。',
  INVALID_FORMAT_LENGTH: 'Cardholderは 2から30桁の整数である必要があります。',
  NO_TEST_CARD_IN_LIVE_MODE: 'No test card in live mode',
  RECURRING_USAGE_REQUIRES_CVV: 'CVV の提供が必要です。',
  CHARGE_TOO_QUICK:
    '制限付きのリカーリングトークンで、指定期間内に複数の課金を作成しようとしました。',

  // stripe locale
  ACCOUNT_COUNTRY_INVALID_ADDRESS: 'account_country_invalid_address',
  ACCOUNT_ERROR_COUNTRY_CHANGE_REQUIRES_ADDITIONAL_STEPS:
    'account_error_country_change_requires_additional_steps',
  ACCOUNT_INFORMATION_MISMATCH: 'account_information_mismatch',
  ACCOUNT_INVALID: 'account_invalid',
  ACCOUNT_NUMBER_INVALID: 'account_number_invalid',
  ACSS_DEBIT_SESSION_INCOMPLETE: 'acss_debit_session_incomplete',
  ALIPAY_UPGRADE_REQUIRED: 'alipay_upgrade_required',
  AMOUNT_TOO_LARGE: 'amount_too_large',
  AMOUNT_TOO_SMALL: 'amount_too_small',
  API_KEY_EXPIRED: 'api_key_expired',
  AUTHENTICATION_REQUIRED: 'authentication_required',
  BALANCE_INSUFFICIENT: 'balance_insufficient',
  BANK_ACCOUNT_BAD_ROUTING_NUMBERS: 'bank_account_bad_routing_numbers',
  BANK_ACCOUNT_DECLINED: 'bank_account_declined',
  BANK_ACCOUNT_EXISTS: 'bank_account_exists',
  BANK_ACCOUNT_RESTRICTED: 'bank_account_restricted',
  BANK_ACCOUNT_UNUSABLE: 'bank_account_unusable',
  BANK_ACCOUNT_UNVERIFIED: 'bank_account_unverified',
  BANK_ACCOUNT_VERIFICATION_FAILED: 'bank_account_verification_failed',
  BILLING_INVALID_MANDATE: 'billing_invalid_mandate',
  BITCOIN_UPGRADE_REQUIRED: 'bitcoin_upgrade_required',
  CARD_DECLINE_RATE_LIMIT_EXCEEDED: 'card_decline_rate_limit_exceeded',
  CARD_DECLINED: 'card_declined',
  CARDHOLDER_PHONE_NUMBER_REQUIRED: 'cardholder_phone_number_required',
  CHARGE_ALREADY_CAPTURED: 'charge_already_captured',
  CHARGE_ALREADY_REFUNDED: 'charge_already_refunded',
  CHARGE_DISPUTED: 'charge_disputed',
  CHARGE_EXCEEDS_SOURCE_LIMIT: 'charge_exceeds_source_limit',
  CHARGE_EXPIRED_FOR_CAPTURE: 'charge_expired_for_capture',
  CHARGE_INVALID_PARAMETER: 'charge_invalid_parameter',
  CLEARING_CODE_UNSUPPORTED: 'clearing_code_unsupported',
  COUNTRY_CODE_INVALID: 'country_code_invalid',
  COUNTRY_UNSUPPORTED: 'country_unsupported',
  COUPON_EXPIRED: 'coupon_expired',
  CUSTOMER_MAX_PAYMENT_METHODS: 'customer_max_payment_methods',
  CUSTOMER_MAX_SUBSCRIPTIONS: 'customer_max_subscriptions',
  DEBIT_NOT_AUTHORIZED: 'debit_not_authorized',
  EMAIL_INVALID: 'email_invalid',
  EXPIRED_CARD: 'expired_card',
  IDEMPOTENCY_KEY_IN_USE: 'idempotency_key_in_use',
  INCORRECT_ADDRESS: 'incorrect_address',
  INCORRECT_CVC: 'incorrect_cvc',
  INCORRECT_NUMBER: 'incorrect_number',
  INCORRECT_ZIP: 'incorrect_zip',
  INSTANT_PAYOUTS_CONFIG_DISABLED: 'instant_payouts_config_disabled',
  INSTANT_PAYOUTS_CURRENCY_DISABLED: 'instant_payouts_currency_disabled',
  INSTANT_PAYOUTS_LIMIT_EXCEEDED: 'instant_payouts_limit_exceeded',
  INSTANT_PAYOUTS_UNSUPPORTED: 'instant_payouts_unsupported',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  INTENT_INVALID_STATE: 'intent_invalid_state',
  INTENT_VERIFICATION_METHOD_MISSING: 'intent_verification_method_missing',
  INVALID_CARD_TYPE: 'invalid_card_type',
  INVALID_CHARACTERS: 'invalid_characters',
  INVALID_CHARGE_AMOUNT: 'invalid_charge_amount',
  INVALID_CVC: 'invalid_cvc',
  INVALID_EXPIRY_MONTH: 'invalid_expiry_month',
  INVALID_EXPIRY_YEAR: 'invalid_expiry_year',
  INVALID_NUMBER: 'invalid_number',
  INVALID_SOURCE_USAGE: 'invalid_source_usage',
  INVOICE_NO_CUSTOMER_LINE_ITEMS: 'invoice_no_customer_line_items',
  INVOICE_NO_PAYMENT_METHOD_TYPES: 'invoice_no_payment_method_types',
  INVOICE_NO_SUBSCRIPTION_LINE_ITEMS: 'invoice_no_subscription_line_items',
  INVOICE_NOT_EDITABLE: 'invoice_not_editable',
  INVOICE_ON_BEHALF_OF_NOT_EDITABLE: 'invoice_on_behalf_of_not_editable',
  INVOICE_PAYMENT_INTENT_REQUIRES_ACTION:
    'invoice_payment_intent_requires_action',
  INVOICE_UPCOMING_NONE: 'invoice_upcoming_none',
  LIVEMODE_MISMATCH: 'livemode_mismatch',
  LOCK_TIMEOUT: 'lock_timeout',
  MISSING: 'missing',
  NO_ACCOUNT: 'no_account',
  NOT_ALLOWED_ON_STANDARD_ACCOUNT: 'not_allowed_on_standard_account',
  OUT_OF_INVENTORY: 'out_of_inventory',
  OWNERSHIP_DECLARATION_NOT_ALLOWED: 'ownership_declaration_not_allowed',
  PARAMETER_INVALID_EMPTY: 'parameter_invalid_empty',
  PARAMETER_INVALID_INTEGER: 'parameter_invalid_integer',
  PARAMETER_INVALID_STRING_BLANK: 'parameter_invalid_string_blank',
  PARAMETER_INVALID_STRING_EMPTY: 'parameter_invalid_string_empty',
  PARAMETER_MISSING: 'parameter_missing',
  PARAMETER_UNKNOWN: 'parameter_unknown',
  PARAMETERS_EXCLUSIVE: 'parameters_exclusive',
  PAYMENT_INTENT_ACTION_REQUIRED: 'payment_intent_action_required',
  PAYMENT_INTENT_AUTHENTICATION_FAILURE:
    'payment_intent_authentication_failure',
  PAYMENT_INTENT_INCOMPATIBLE_PAYMENT_METHOD:
    'payment_intent_incompatible_payment_method',
  PAYMENT_INTENT_INVALID_PARAMETER: 'payment_intent_invalid_parameter',
  PAYMENT_INTENT_KONBINI_REJECTED_CONFIRMATION_NUMBER:
    'payment_intent_konbini_rejected_confirmation_number',
  PAYMENT_INTENT_MANDATE_INVALID: 'payment_intent_mandate_invalid',
  PAYMENT_INTENT_PAYMENT_ATTEMPT_EXPIRED:
    'payment_intent_payment_attempt_expired',
  PAYMENT_INTENT_PAYMENT_ATTEMPT_FAILED:
    'payment_intent_payment_attempt_failed',
  PAYMENT_INTENT_UNEXPECTED_STATE: 'payment_intent_unexpected_state',
  PAYMENT_METHOD_BANK_ACCOUNT_ALREADY_VERIFIED:
    'payment_method_bank_account_already_verified',
  PAYMENT_METHOD_BANK_ACCOUNT_BLOCKED: 'payment_method_bank_account_blocked',
  PAYMENT_METHOD_BILLING_DETAILS_ADDRESS_MISSING:
    'payment_method_billing_details_address_missing',
  PAYMENT_METHOD_CONFIGURATION_FAILURES:
    'payment_method_configuration_failures',
  PAYMENT_METHOD_CURRENCY_MISMATCH: 'payment_method_currency_mismatch',
  PAYMENT_METHOD_CUSTOMER_DECLINE: 'payment_method_customer_decline',
  PAYMENT_METHOD_INVALID_PARAMETER: 'payment_method_invalid_parameter',
  PAYMENT_METHOD_INVALID_PARAMETER_TESTMODE:
    'payment_method_invalid_parameter_testmode',
  PAYMENT_METHOD_MICRODEPOSIT_FAILED: 'payment_method_microdeposit_failed',
  PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_AMOUNTS_INVALID:
    'payment_method_microdeposit_verification_amounts_invalid',
  PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_AMOUNTS_MISMATCH:
    'payment_method_microdeposit_verification_amounts_mismatch',
  PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_ATTEMPTS_EXCEEDED:
    'payment_method_microdeposit_verification_attempts_exceeded',
  PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_DESCRIPTOR_CODE_MISMATCH:
    'payment_method_microdeposit_verification_descriptor_code_mismatch',
  PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_TIMEOUT:
    'payment_method_microdeposit_verification_timeout',
  PAYMENT_METHOD_PROVIDER_DECLINE: 'payment_method_provider_decline',
  PAYMENT_METHOD_PROVIDER_TIMEOUT: 'payment_method_provider_timeout',
  PAYMENT_METHOD_UNACTIVATED: 'payment_method_unactivated',
  PAYMENT_METHOD_UNEXPECTED_STATE: 'payment_method_unexpected_state',
  PAYMENT_METHOD_UNSUPPORTED_TYPE: 'payment_method_unsupported_type',
  PAYOUTS_NOT_ALLOWED: 'payouts_not_allowed',
  PLATFORM_ACCOUNT_REQUIRED: 'platform_account_required',
  PLATFORM_API_KEY_EXPIRED: 'platform_api_key_expired',
  POSTAL_CODE_INVALID: 'postal_code_invalid',
  PROCESSING_ERROR: 'processing_error',
  PRODUCT_INACTIVE: 'product_inactive',
  RATE_LIMIT: 'rate_limit',
  REFER_TO_CUSTOMER: 'refer_to_customer',
  REFUND_DISPUTED_PAYMENT: 'refund_disputed_payment',
  RESOURCE_ALREADY_EXISTS: 'resource_already_exists',
  RESOURCE_MISSING: 'resource_missing',
  RETURN_INTENT_ALREADY_PROCESSED: 'return_intent_already_processed',
  ROUTING_NUMBER_INVALID: 'routing_number_invalid',
  SECRET_KEY_REQUIRED: 'secret_key_required',
  SEPA_UNSUPPORTED_ACCOUNT: 'sepa_unsupported_account',
  SETUP_ATTEMPT_FAILED: 'setup_attempt_failed',
  SETUP_INTENT_AUTHENTICATION_FAILURE: 'setup_intent_authentication_failure',
  SETUP_INTENT_INVALID_PARAMETER: 'setup_intent_invalid_parameter',
  SETUP_INTENT_SETUP_ATTEMPT_EXPIRED: 'setup_intent_setup_attempt_expired',
  SETUP_INTENT_UNEXPECTED_STATE: 'setup_intent_unexpected_state',
  SHIPPING_CALCULATION_FAILED: 'shipping_calculation_failed',
  SKU_INACTIVE: 'sku_inactive',
  STATE_UNSUPPORTED: 'state_unsupported',
  STATUS_TRANSITION_INVALID: 'status_transition_invalid',
  TAX_ID_INVALID: 'tax_id_invalid',
  TAXES_CALCULATION_FAILED: 'taxes_calculation_failed',
  TERMINAL_LOCATION_COUNTRY_UNSUPPORTED:
    'terminal_location_country_unsupported',
  TESTMODE_CHARGES_ONLY: 'testmode_charges_only',
  TLS_VERSION_UNSUPPORTED: 'tls_version_unsupported',
  TOKEN_ALREADY_USED: 'token_already_used',
  TOKEN_IN_USE: 'token_in_use',
  TRANSFER_SOURCE_BALANCE_PARAMETERS_MISMATCH:
    'transfer_source_balance_parameters_mismatch',
  TRANSFERS_NOT_ALLOWED: 'transfers_not_allowed',
  URL_INVALID: 'url_invalid',
  //end stripe locale
};
