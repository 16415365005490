import { configureStore } from '@reduxjs/toolkit';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import {
//   FLUSH,
//   PAUSE,
//   PERSIST,
//   persistReducer,
//   persistStore,
//   PURGE,
//   REGISTER,
//   REHYDRATE,
// } from 'redux-persist';
import rootReducer from './slice';

// const persistConfig = {
//   key: 'rootPrev',
//   version: 1,
//   storage: AsyncStorage,
// };

export const store = configureStore({
  reducer: rootReducer,
});

// const persistedReduce = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReduce,
//   // middleware: getDefaultMiddleware({
//   //   serializableCheck: {
//   //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//   //   },
//   // }),
// });

// export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
