import {
  BINDING_SELECTOR_TYPE,
  CURRENT_LOCATION_KEY,
} from '@common/constants/shared';
import { IAction, paramsAction, IObj } from '@common/types';
import { MetadataAction } from '@common/types/action';
import { forEach, get, isEmpty } from 'lodash';

export const getActions = (actions: any) => {
  return get(Object.keys(actions || {}), '[0]', null);
};

export const getFields = (action: IAction) => {
  return (action?.options?.fields || []).filter((field) => field);
};

export const getPayloadActions = (
  action: IAction,
  defaultValue: Record<string, any> = {}
) => {
  const { fields = [] } = action.options;
  if (fields?.length <= 0) return defaultValue;
  return fields
    .filter((field) => field)
    .reduce((acc, field: Record<string, any>) => {
      if (get(defaultValue, field.fieldId) !== undefined) {
        return {
          ...acc,
          [field.fieldId]: field?.source || get(defaultValue, field.fieldId),
        };
      } else {
        return {
          ...acc,
          [field.fieldId]: field?.source,
        };
      }
    }, {});
};

const getSelector = (
  options: Record<string, any>
): Record<string, any> | null => {
  if (options?.selector) {
    return {
      ...options.selector,
      ...(options.selector.type ===
        BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR && {
        tableId: options.tableId,
      }),
    };
  } else if (options?.source) return getSelector(options?.source);
  else return null;
};

export const getRecordIdAction = (
  action: IAction,
  metadata: MetadataAction
) => {
  const selector = getSelector(action.options);
  // const { dependencies } = metadata;

  const dependencies = {};

  if (!selector?.type) return;

  switch (selector.type) {
    case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
      return get(dependencies, 'loggedInUser');

    case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
      return get(dependencies, `routeParam.${selector?.tableId}`);

    case BINDING_SELECTOR_TYPE.CREATED_OBJECT:
      return get(metadata, 'dependencies.createRecordId');

    case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
      return get(metadata.itemListClick, selector?.listObjectId);

    //SELECT_VALUE_SELECTOR
    default:
      return get(
        metadata,
        `dependencies.valueInputs.${selector?.selectObjectId}`
      );
  }
};

const flattedInputSource = (sources: any[]) =>
  sources.reduce((pre: Record<string, any>, item: any) => {
    return { ...pre, ...getObjectId(item) };
  }, {});

const getObjectId = (source: Record<string, any>): Record<string, any> => {
  let resp: Record<string, any> = {};
  if (source?.objectId) resp[source?.objectId] = '';
  if (source?.selectObjectId) resp[source?.selectObjectId] = '';

  if (source?.type === 'formula' && source?.valid) {
    const { formula = [] } = source;
    return {
      ...resp,
      ...flattedInputSource(formula),
    };
  }
  if (Array.isArray(source) && source.length) {
    return {
      ...resp,
      ...flattedInputSource(source),
    };
  }
  if (source?.source) return { ...resp, ...getObjectId(source?.source) };
  else if (source?.selector)
    return { ...resp, ...getObjectId(source?.selector) };

  return resp;
};

export const getInputFromField = (action: IAction, params: IObj) => {
  let formControl = {},
    inputs = {};

  const { formId = '', record = {} } = params;
  if (!isEmpty(formId)) {
    formControl = Object.keys(record).reduce(
      (acc, key) => ({ ...acc, [`${formId}-${key}`]: '' }),
      {}
    );
  }
  const { fields = [] } = action?.options || {};
  inputs = fields.reduce((acc, field) => {
    const inputs = getObjectId(field?.source);
    return {
      ...acc,
      ...(field?.source && inputs),
    };
  }, {});

  return { formControl, inputs };
};

export const getItemListClick = (record: Record<string, any> | null = {}) => {
  if (isEmpty(record?._meta)) return {};
  const _meta = record?._meta;
  return {
    sortedItemNumber: record?.sortedItemNumber,
    [_meta?.datasourceId]: record?.id,
    [_meta?.databindingId]: record?.id,
    recordObj: {
      [_meta?.tableId]: record?.id,
      [_meta?.databindingId]: record?.id,
      [_meta.componentId]: record?.id,
    },
    ...(_meta?.web3Config && {
      web3Config: {
        [_meta?.tableId]: _meta.web3Config,
        [_meta?.datasourceId]: _meta.web3Config,
        [_meta?.databindingId]: _meta.web3Config,
      },
    }),
  };
};

export const jsonCustomAction = (customAction: Record<string, any>) => {
  if (!customAction) return {};
  let res: any = {};
  const convertField = ['config', 'fields'];
  forEach(Object.keys(customAction)).forEach((field) => {
    const fieldValue = customAction[field];
    if (fieldValue && convertField.includes(field)) {
      res[field] = JSON.parse(fieldValue);
    } else if (fieldValue) res[field] = fieldValue;
  });
  return res;
};

export const checkActionHasLocation = (dependencies: any) => {
  return (
    !!dependencies[CURRENT_LOCATION_KEY]?.lat &&
    !!dependencies[CURRENT_LOCATION_KEY]?.lng
  );
};
