import { StyleSheet, Platform } from 'react-native';
import { checkStyles } from '../func';
import { IGptConversation } from './hook';

const createStyles = ({
  attributes,
  width,
  height,
}: Pick<IGptConversation, 'attributes' | 'width' | 'height'>) => {
  const { opacity, sendButton, borderRadius, backgroundColor } = attributes;

  return StyleSheet.create({
    container: {
      width,
      height,
      opacity,
      backgroundColor,
      padding: 10,
      borderRadius: 6,
    },

    scrollContainer: {
      height: height - 60,
      backgroundColor: '#fff',
      borderRadius: 8,
      padding: 8,
    },

    text: {
      paddingTop: 2,
      ...checkStyles({
        ...attributes,
        color: undefined,
        backgroundColor: undefined,
      }),
      lineHeight: attributes.lineHeight,
    },

    buttonInputContainer: {
      marginTop: 10,
      height: 50,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    input: {
      fontSize: 14,
      ...(Platform.OS === 'android' && { paddingVertical: 0 }),
      paddingHorizontal: 4,
      backgroundColor: '#fff',
      flex: 1,
      marginRight: 8,
      borderRadius,
    },

    btn: {
      justifyContent: 'center',
      lineHeight: sendButton.lineHeight,
      ...checkStyles({ ...sendButton, borderRadius }),
      maxWidth: '50%',
    },
    btnText: {
      lineHeight: sendButton.lineHeight,
      ...checkStyles(sendButton),
    },
  });
};

export default createStyles;
