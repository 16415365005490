import BaseComponent from './base.type';

export enum TorusLoginProvider {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  REDDIT = 'reddit',
  DISCORD = 'discord',
  TWITCH = 'twitch',
  APPLE = 'apple',
  LINE = 'line',
  GITHUB = 'github',
  KAKAO = 'kakao',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  WEIBO = 'weibo',
  WECHAT = 'wechat',
  EMAIL_PASSWORDLESS = 'email_passwordless',
  WEBAUTHN = 'webauthn',
  // ANY = '',
}

interface Auth {
  userId: string;
}
interface Attributes {
  text: string;
  typeOfLogin: TorusLoginProvider;
  clientId: string | Array<string>;
  network: string;

  colors?: {
    linerColors?: string;
  };

  icons: {
    enabled: boolean;
    iconEnableOutline?: boolean;
    icon?: string;
  };

  leadImage: {
    enabled: boolean;
    imageUrl?: string | Array<string>;
  };
  tailImage: {
    enabled: boolean;
    imageUrl?: string | Array<string>;
  };
  contrastColor?: string;
  borderRadius?: number;
  upperCase?: boolean;
  opacity: number;
  backgroundColor: string;
  fontFamily: string;
  fontWeightBold?: boolean;
  fontStyleItalic?: boolean;
  lineHeight: number;
  fontSize: number;
  color: string;
  borderColor: string;
  borderStyle: string;
  borderWidth: number;
  zIndex?: number;
}

export type LoginSuccessWithTorus = {
  publicAddress?: string;
  privKey: string;
  userInfo: {
    email: string;
    name: string;
    idToken: string;
    typeOfLogin: string;
    verifierId: string;
  };
};

export default interface ITorusLogin extends BaseComponent {
  attributes: Attributes;
  libraryName: string;
  libraryVersion: string;
  componentName: string;
  loading?: boolean;
  loginSuccessWithTorus: (info: LoginSuccessWithTorus) => Promise<boolean>;
  auth?: Auth;
  width: number;
  height: number;
  minWidth: number;
  locale: string;
  record: any;
  data: any;
}
