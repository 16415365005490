import React, { useMemo, useCallback } from 'react';
import useStripePayment, {
  KEY_MODE_STRIPE,
  Props,
  ReceivedProps,
} from './hook';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { View, Text } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import createStyles from './style';
import { parseText } from '../func';
import { OPTIONS_CARD_ELEMENT, STATUS_STRIPE } from './constant';

const StripeForm = (props: Props) => {
  const {
    locale,
    loading,
    attributes,
    submitButtonText,
    enabledTitle,
    titleText,
    handleSubmit,
    card,
    disabledButton,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const {
    text: { status, message },
  } = card;

  const styles = createStyles({
    attributes,
    isError: status !== STATUS_STRIPE.SUCCEEDED,
  });

  return (
    <View>
      <View>
        {enabledTitle && (
          <Text style={styles.title}>{parseText(titleText)}</Text>
        )}
        <View style={styles.wrapperInput}>
          <CardElement options={OPTIONS_CARD_ELEMENT} />
        </View>
        {!!status && <Text style={styles.text}>{message}</Text>}
        <View>
          <RNButton
            loading={loading}
            disabled={loading || disabledButton}
            mode={attributes.type}
            style={styles.btn}
            labelStyle={styles.labelStyle}
            children={submitButtonText.toUpperCase()}
            onPress={() => handleSubmit(stripe, elements)}
          />
        </View>
      </View>
    </View>
  );
};

const StripePaymentLayout = (props: Props) => {
  const {
    locale,
    accountConnectId,
    publicKeyDefault,
    enabledTestMode,
    publishableKey,
    updateStatusCard,
  } = props;

  const checkStripe = useCallback(async () => {
    const resultStripe: any = await loadStripe(
      enabledTestMode ? publishableKey : publicKeyDefault,
      enabledTestMode ? undefined : { stripeAccount: accountConnectId }
    );

    const isSuccessKey = [KEY_MODE_STRIPE.TEST, KEY_MODE_STRIPE.LIVE].includes(
      resultStripe?._keyMode
    );

    if (!isSuccessKey) {
      updateStatusCard(
        'notValidOrRequired',
        'Publishable Key or Secret Key is not correct. Please try again.'
      );
      return null;
    }

    return resultStripe;
  }, [enabledTestMode, publishableKey, publicKeyDefault, accountConnectId]);

  const stripe = useMemo(() => checkStripe(), [checkStripe]);

  return (
    <Elements
      stripe={stripe}
      options={{
        locale: locale,
        appearance: { theme: 'stripe' },
      }}
    >
      <StripeForm {...props} />
    </Elements>
  );
};

const StripePayment = (props: ReceivedProps) => (
  <StripePaymentLayout {...useStripePayment(props)} />
);

export default StripePayment;
