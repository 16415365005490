import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';

import api from '@common/configs/api';
import Loading from '@common/components/Loading';
import ShareScreen from '../ShareScreen';
import Render from '.';

enum APP_VERSION {
  V2 = 'v2',
  V3 = 'v3',
}

export default function Home() {
  const [appId, setAppId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [appVersion, setAppVersion] = useState<APP_VERSION | null>(null);

  const getURL = window.location.host;

  useEffect(() => {
    (async () => {
      try {
        const resp: any = await api({
          method: 'get',
          url: `v2/apps-domain/${getURL}`,
        });

        console.log('resp :>> ', resp);
        localStorage.setItem('appId', resp.data?.appId);

        setLoading(false);
        setAppId(resp.data?.appId);
        setAppVersion(resp.data?.version);
      } catch (error) {
        setLoading(false);
        console.log('error :>> ', error);
      }
    })();
  }, []);

  if (loading) return <Loading />;

  if (!appId) {
    return (
      <View>
        <Text>Cannot found page</Text>
      </View>
    );
  }

  if (appVersion == APP_VERSION['V3']) {
    return <Render appId={appId} />;
  }
  console.log('appVersion :>> ');
  return <ShareScreen appId={appId} />;
}
