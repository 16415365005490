import { AliRTS, LocalStream } from 'aliyun-rts-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Item from '@common/components/LiveStreamCamera/MobileSource/Viewer/Item';
import createStyles from '@common/components/LiveStreamCamera/WebSource/style';
import {
  getLivestreamChannel,
  handleLivestreamSocket,
  livestreamSocket,
  SOCKET_STATE,
} from '@common/configs/livestream-socket';
import useLiveStream from '@common/hooks/useLiveStream';
import history from '@common/routes/history';

enum StatusStream {
  connecting = 'connecting',
  connected = 'connected',
  started = 'started',
  ended = 'ended',
}

const StartLiveWeb: React.FC<any> = (props) => {
  const { thumbnail, title } = props;
  const styles = createStyles();
  const windowHeight = Dimensions.get('window').height;
  const windowWidth = Dimensions.get('window').width;

  const [localStream, setLocalStream] = useState<LocalStream>();
  const [statusStream, setStatusStream] = useState<StatusStream>(
    StatusStream.ended
  );
  const [videoR, setVideoR] = useState<any>(null);
  const aliRts = AliRTS.createClient({});
  const [vDevID, setVDevID] = useState('');
  const [aDevID, setADevID] = useState('');
  // const [isCamera, setIsCamera] = useState(true);
  const [listMic, setListMic] = useState<Array<MediaDeviceInfo>>([]);
  const [listCam, setListCam] = useState<Array<MediaDeviceInfo>>([]);

  const [liveStreamDetail, setLiveStreamDetail] = useState<any>({});
  const { convertTimeLive, changeValue, createLiveStreamNew } =
    useLiveStream(props);
  const [socketLivestreamState, setSocketLivestreamState] = useState(
    SOCKET_STATE.NOT_CONNECTED
  );
  const [streaming, setStreaming] = useState(true);

  useEffect(() => {
    setVideoR(document.getElementById('video'));
  }, []);

  useEffect(() => {
    AliRTS.getMicList()
      .then((re: Array<MediaDeviceInfo>) => {
        setListMic(re);
        setADevID(re[0].deviceId);
      })
      .catch((err) => {
        console.error('The browser is unavailable.', err);
      });
  }, []);

  useEffect(() => {
    AliRTS.getCameraList()
      .then((re: Array<MediaDeviceInfo>) => {
        setListCam(re);
        setVDevID(re[0].deviceId);
      })
      .catch((err) => {
        console.error('The browser is unavailable.', err);
      });
  }, []);

  useEffect(() => {
    if (aDevID && vDevID) {
      upsertIngestStream(aDevID, vDevID);
    }
  }, [aDevID, vDevID]);

  useEffect(() => {
    let handleCountTimeLive = setInterval(() => {
      statusStream === StatusStream.started &&
        setLiveStreamDetail((prev: any) => ({
          ...prev,
          timeLiveStream: (prev.timeLiveStream || 0) + 1,
        }));
    }, 1000);
    return () => {
      clearInterval(handleCountTimeLive);
    };
  }, [statusStream]);

  useEffect(() => {
    changeValue({ totalView: liveStreamDetail?.totalView });
  }, [liveStreamDetail?.totalView]);

  useEffect(() => {
    const streamId = liveStreamDetail?.streamInfo?.stream_id;
    if (streamId && socketLivestreamState !== SOCKET_STATE.CONNECTED) {
      handleLivestreamSocket(streamId, (socket: SocketIOClient.Socket) => {
        setSocketLivestreamState(SOCKET_STATE.CONNECTED);
        socket.emit('get', 'livestreams', streamId, (data: any) => {
          data?.totalView &&
            setLiveStreamDetail((prev: any) => ({
              ...prev,
              totalView: data.totalView > 0 ? data.totalView - 1 : 0,
            }));
        });
        socket.on(getLivestreamChannel(streamId), (data: any) => {
          const { totalView } = data;
          totalView &&
            setLiveStreamDetail((prev: any) => ({
              ...prev,
              totalView: totalView > 0 ? totalView - 1 : 0,
            }));
        });
      });
    }
  }, [socketLivestreamState, liveStreamDetail?.streamInfo?.stream_id]);

  useEffect(() => {
    return () => {
      livestreamSocket?.removeAllListeners();
      livestreamSocket?.close();
    };
  }, []);

  const MediaElement = useMemo(() => {
    return (
      <video id="video" muted style={{ width: '100%', height: '100%' }}></video>
    );
  }, []);

  const startOrRestartIngestStream = useCallback(
    async (info, stream, alirts) => {
      let url = '';
      await createLiveStreamNew({
        infoUser: info,
        title,
      })
        .then((data: any) => {
          url = data?.urls?.RTS;

          changeValue({
            id: data?.streamInfo?.stream_id,
            totalView: 0,
            streamer: data?.streamInfo?.metadata?.owner?.id,
          });
        })
        .catch((e) => {
          console.error('==startOrRestartIngestStream ', e);
          setStreaming(false);
        });

      if (stream && url) {
        alirts
          .publish(url, stream)
          .then(() => {
            // The stream is ingested.
            setStatusStream(StatusStream.started);
            console.log('published', url);
            setStreaming(false);
          })
          .catch((err: any) => {
            // The stream failed to be ingested.
            console.error('publish', err);
            setStatusStream(StatusStream.ended);
            setStreaming(false);
          });
      }
    },
    []
  );

  const upsertIngestStream = useCallback(
    (audioID: string, videoID: string) => {
      setStatusStream(StatusStream.connecting);
      AliRTS.createStream({
        audio: { deviceId: audioID },
        video: { deviceId: videoID },
        screen: false,
      })
        .then((stream) => {
          setLocalStream(stream);
          setStatusStream(StatusStream.connected);
          if (stream && stream.audioTrack) {
            stream.play(videoR);
          }

          // start livestreaming
          startOrRestartIngestStream(props.infoUser, stream, aliRts);
        })
        .catch((err) => {
          setStatusStream(StatusStream.connected);
          console.error(err);
          setStreaming(false);
        });
    },
    [videoR, localStream, vDevID, aDevID]
  );

  const stopIngestStream = useCallback(async () => {
    try {
      localStream?.stop();
      videoR.srcObject = null;
      history.goBack();
      setStatusStream(StatusStream.ended);
    } catch (err: any) {
      localStream?.stop();
      videoR.srcObject = null;
      console.error('unpublish', err);
      history.goBack();
    }
  }, [aliRts, localStream, videoR]);

  const LoadingLiveStream = () => {
    if (!streaming) return null;
    return (
      <View
        style={{
          zIndex: 1,
          alignContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          paddingTop: windowHeight / 2.5,
          paddingLeft: windowWidth / 2.5,
        }}
      >
        <ActivityIndicator />
        <Text style={{ color: 'white' }}>Starting Live...</Text>
      </View>
    );
  };

  return (
    <View style={{ width: '100%', height: '100%', flex: 1 }}>
      <LoadingLiveStream />
      <View style={styles.videoContainer}>{MediaElement}</View>
      <Item
        item={{ id: `${props.id}_item_livestream` }}
        attrs={props}
        index={0}
        isActive={true}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <View>
          <Text style={styles.timeCount}>
            {convertTimeLive(liveStreamDetail?.timeLiveStream)}
          </Text>
        </View>
        <TouchableOpacity onPress={stopIngestStream}>
          <View style={styles.buttonEnd}>
            <Text style={{ color: '#ff0000', fontSize: 13 }}>終了</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default React.memo(StartLiveWeb);

// useEffect(() => {
//   aliRts
//     .isSupport({
//       isReceiveVideo: true,
//     })
//     .then((re) => {
//       // The browser is available.
//     })
//     .catch((err) => {
//       // The browser is unavailable.
//       alert(`not support errorCode: ${err.errorCode}`);
//       alert(`not support message: ${err.message}`);
//     });
// }, [aliRts]);

// const handleDevChange = (event: any) => {
//   event.preventDefault();
//   if (event.target.id === 'videoin') {
//     console.log('videoin');
//     setVDevID(event.target.value);
//     upsertIngestStream3(aDevID, event.target.value);
//   }
//   if (event.target.id === 'audioin') {
//     console.log('audioin');
//     setADevID(event.target.value);
//     upsertIngestStream3(event.target.value, vDevID);
//   }
// };

// useEffect(() => {
//   aliRts
//     .subscribe(host)
//     .then((remoteStream) => {
//       remoteStream.play(videoR);
//     })
//     .catch((err) => {
//       console.log('unsubcribe', err);
//     });
// }, [host]);

/* {listCam && (
        <select
          id="videoin"
          onChange={(value) => handleDevChange(value)}
          style={{
            marginBottom: 10,
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {listCam.map((videoin) => (
            <option key={videoin.deviceId} value={videoin.deviceId}>
              {videoin.label}
            </option>
          ))}
        </select>
      )}

      {listMic && (
        <select
          id="audioin"
          onChange={(value) => handleDevChange(value)}
          style={{
            marginBottom: 10,
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {listMic.map((videoin) => (
            <option key={videoin.deviceId} value={videoin.deviceId}>
              {videoin.label}
            </option>
          ))}
        </select>
      )} */
