import { IBarcode } from '@nocode/types';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import Barcode from 'react-barcode';
import { TouchableOpacity } from 'react-native';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import createStyles from './style';

const BarCode: React.FC<IBarcode> = (props) => {
  const styles = createStyles(props);
  const { onPress } = props;

  const barCodeValue = useMemo(() => {
    const isItemList = !!props?.record;

    const bindingValue = getValueBinding(
      isItemList ? props.initId : props.id,
      isItemList ? props?.record : props.data,
      props
    );
    return get(bindingValue, 'value');
  }, [props]);

  const handlePress = (id: string) => {
    onPress &&
      onPress(getActions(props, id), {
        itemListClick: getItemListClick(props.record),
      });
  };

  const barCodeHeight = props.height - 5;
  return barCodeValue ? (
    <TouchableOpacity
      style={styles.container}
      onPress={() => handlePress('onPress')}
    >
      <Barcode
        value={barCodeValue}
        margin={0}
        marginBottom={10}
        height={barCodeHeight}
      />
    </TouchableOpacity>
  ) : null;
};

export default BarCode;
