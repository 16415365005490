import { StyleSheet, Platform } from 'react-native';
import {
  checkFont,
  checkFontWeight,
  getBorderStyle,
  getBorderWidth,
  getTextDecorationLine,
} from '@nocode/components/func';

const createStyles = (
  attributes: any,
  cardMargin: any,
  isLast: boolean,
  index: number
) => {
  const getTextStyle = (styles: any) => {
    const {
      textDecorationUnderline,
      textDecorationLineThrough,
      fontStyleItalic,
      fontWeightBold,
      font,
      size,
      color,
    } = styles || {};
    const textStyle = getTextDecorationLine({
      textDecoration: textDecorationUnderline,
      linethrough: textDecorationLineThrough,
    });

    return {
      textDecorationLine: textStyle,
      fontStyle: (fontStyleItalic ? 'italic' : 'normal') as 'normal' | 'italic',
      fontWeight: checkFontWeight(fontWeightBold, font),
      fontSize: size,
      color: color,
      fontFamily: checkFont(font),
    };
  };

  const avatarSize = attributes?.avatarImage?.size || 64;

  const borderWidth = getBorderWidth(
    attributes?.borderWidth,
    attributes?.borderStyle
  );

  return StyleSheet.create({
    cardWrapper: {
      width: attributes?.cardCoverStyle?.width + borderWidth * 2,
      borderRadius: attributes?.borderRadius,
      borderColor: attributes?.borderColor,
      borderWidth,
      borderStyle: getBorderStyle(attributes?.borderStyle),
      // backgroundColor: attributes?.backgroundColor,
      opacity: attributes?.opacity,
      overflow: 'hidden',
      shadowColor: attributes.shadow?.color,
      shadowOffset: {
        width: attributes.shadow?.x,
        height: attributes.shadow?.y,
      },
      shadowOpacity: attributes.shadow?.blur,
      shadowRadius: attributes.shadow?.size,
      marginBottom: cardMargin?.marginBottom,
      marginTop: cardMargin?.marginTop,
      marginLeft: index === 0 ? cardMargin?.marginLeft : 0,
      marginRight: isLast ? cardMargin?.marginRight : 0,
    },

    cardImage: {
      width: '100%',
      height: attributes?.cardCoverStyle?.height,
      borderRadius: attributes?.cardCoverStyle?.rounding,
      borderTopLeftRadius: attributes?.cardCoverStyle?.rounding,
      borderTopRightRadius: attributes?.cardCoverStyle?.rounding,
    },

    cardTitleWrapper: {
      minHeight: 30,
      position: 'absolute',
      backgroundColor: attributes.tagStyle?.backgroundColor,
      borderRadius: 0,
      width: attributes?.cardCoverStyle?.width,
      justifyContent: 'center',
      alignItems: 'center',
      right: 0,
      bottom: 0,
    },

    cardTitle: {
      fontSize: 13,
      fontWeight: 'bold',
      color: '#fff',
    },

    cardWrapperTag: {
      minHeight: 30,
      position: 'absolute',
      backgroundColor: '#fff',
      borderRadius: 0,
      width: attributes?.cardCoverStyle?.width / 1.5,
      justifyContent: 'center',
      alignItems: 'center',
      bottom: '45%',
      left: '18%',
    },

    cardTag: {
      fontFamily: checkFont(attributes?.tagStyle?.font),
      fontSize: attributes?.tagStyle?.size,
      fontWeight: 'bold',
      color: attributes?.tagStyle?.color,
    },

    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 0,
    },

    emptyWrapper: {
      height: avatarSize / 2,
    },

    cardContentWrapper: {
      flex: 1,
      backgroundColor: attributes.backgroundColor,
      ...(attributes?.avatarImage?.enabled && {
        minHeight: avatarSize / 2 + 1,
      }),
    },
  });
};

export default createStyles;
