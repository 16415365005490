import styled from 'styled-components';
import { Table } from 'antd';

const AntStyles = styled(Table)`
  min-height: ${(attributes: any) => attributes.height}px;
  width: ${(attributes: any) => attributes.width}px;
  overflow: auto;

  .ant-table-body {
    height: ${(attributes: any) => attributes.height - 100}px;
  }
`;

export const styleUnWrapperText = `
max-width: 100%;
word-break: keep-all;
overflow: hidden;
text-overflow: ellipsis;
white-space: break-spaces;
// display: block;
`;

export const styleWrapperText = `
max-width: 100%;
overflow: inherit;
text-overflow: inherit;
white-space: break-spaces;
work-break: inherit;
`;

export const ImageMinHeight = styled.div`
  min-height: 60px;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
`;

export const FileWrapper = styled.a`
  ${styleUnWrapperText}
`;

export default AntStyles;
