import React from 'react';
import { Dimensions, ScrollView, View } from 'react-native';

type Props = {
  children: any;
  scroll?: {
    active?: boolean;
    arrange?: 'horizontal' | 'vertical';
    showsHorizontalScrollIndicator?: boolean;
  };
  attrs: {
    width: number;
    height: number;
    marginLeft?: number;
  };
};
const GroupScrollableWrapper: React.FC<Props> = (props) => {
  const { width } = Dimensions.get('window');
  const offset = props.attrs.marginLeft;

  const { attrs, scroll } = props;
  if (scroll?.active) {
    return (
      <ScrollView
        horizontal={scroll?.arrange === 'horizontal'}
        nestedScrollEnabled
        showsHorizontalScrollIndicator={scroll?.showsHorizontalScrollIndicator}
        style={{
          minHeight: attrs.height,
          width: Math.min(offset ? width - offset : width, attrs.width),
        }}
      >
        <View>{props.children}</View>
      </ScrollView>
    );
  }
  return <>{props.children}</>;
};

export default GroupScrollableWrapper;
