import {
  TABLE_DELETE,
  bindingComponentType,
  isShowLoadingAction,
  qrCodeStatus as QR_CODE_STATUS_ACTION,
} from '@common/constants/shared';
import {
  showQrCodeStatusSelector,
  showQrCodeValueSelector,
} from '@common/redux/selectors/page';
import {
  resetQrCodeScanner,
  showQrCodeScanner,
} from '@common/redux/slice/page';
import ActionWrapper from '@common/screens/LoadingAction';
import { IOnPressProps } from '@common/types/action';
import { ActionTypes, ElementType } from '@common/types/index';
import { getSourceDataBinding } from '@common/utils/binding';
import {
  checkingTypeComponent,
  convertParamAction,
  getFlattedMetadata,
  memoriesConditional,
} from '@common/utils/component';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import { find, get, some } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ListObject from './Components/ListObject';
import SimpleObject from './Components/SimpleObject';
import DependenciesConnector from './DependenciesConnnector';

type Props = {
  ObjectClass: FC;
  obj: ElementType;
  dependencies: any;
};

const BindingComponent = React.memo(
  ({ ObjectClass, obj, dependencies }: Props) => {
    const dispatch = useDispatch();
    const qrCodeStatus = useSelector(showQrCodeStatusSelector);
    const qrCodeValue = useSelector(showQrCodeValueSelector);
    const { messages } = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>(null);

    const onShowQrCode = useCallback(
      (params: Record<string, any>) => {
        dispatch(showQrCodeScanner(true));
        setParams(params);
      },
      [dispatch]
    );

    const handleTableDelete = useCallback(
      async (actionId: string, options: IOnPressProps) => {
        return actionPromise(
          convertParamAction({
            obj,
            dependencies,
            options,
            objectId: obj.id,
            actionId,
            messages,
            arrayAction: [],
          })
        ).then((data) => {
          setLoading(false);
          return data;
        });
      },
      [dependencies, messages, obj]
    );

    const onPress = useCallback(
      async (actionId: string | null, options: IOnPressProps) => {
        try {
          if (actionId === TABLE_DELETE) {
            return await handleTableDelete(actionId, options);
          }
          if (!actionId || typeof actionId !== 'string') return;
          const metadata = getFlattedMetadata(obj.screenUuid);
          const actionObject = find(metadata, (item) =>
            JSON.stringify(item).includes(actionId)
          );
          if (!actionObject) return;
          setLoading(true);
          const objectId = actionObject.id;
          const arrayAction = get(obj, `actions.${actionId}.actions`, []);

          const params = convertParamAction({
            obj,
            dependencies,
            options,
            objectId,
            actionId,
            messages,
            arrayAction,
          });
          const result = await actionPromise(params);

          const includeQrCodeAction =
            Array.isArray(result) &&
            some(result, {
              actionType: ActionTypes.QR_CODE_SCANNER,
            });
          if (!includeQrCodeAction) {
            return result;
          } else {
            onShowQrCode(params);
          }
        } catch (error) {
          console.log('error', error);
        } finally {
          setLoading(false);
        }
      },
      [dependencies, messages, obj, onShowQrCode]
    );

    useEffect(() => {
      const action = async () => {
        if (QR_CODE_STATUS_ACTION.includes(qrCodeStatus) && params) {
          await actionPromise({ ...params, scannerId: qrCodeValue });
          setParams(null);
          dispatch(resetQrCodeScanner());
        }
      };

      action();
    }, [dispatch, params, qrCodeValue, qrCodeStatus]);

    const dataBinding = getSourceDataBinding(obj);

    const props = useMemo(
      () => ({ ObjectClass, obj, onPress, dependencies }),
      [ObjectClass, obj, onPress, dependencies]
    );

    const componentType =
      checkingTypeComponent(obj) || bindingComponentType.SIMPLE;

    const loadingAction =
      loading && isShowLoadingAction.includes(obj.componentName);

    if (loadingAction) {
      return <ActionWrapper obj={obj} />;
    }

    if (componentType === bindingComponentType.MULTIPLE) {
      return <ListObject {...props} dataBinding={dataBinding} />;
    }

    return <SimpleObject {...props} />;
  },
  memoriesConditional(['obj', 'dependencies', 'ObjectClass', 'screenUuid'])
);

export default DependenciesConnector(BindingComponent);
