import {
  ExecutingPaymentRequest,
  PaymentObject,
  PaymentStatus,
} from '@fincode/js';
import axiosInstance from '@common/services/axiosIntance/axiosService';
import api from '@common/configs/api';

export type TestModeParams = {
  shopId: string;
};
export type FincodeAppOrderEntity = {
  id: number;
  appId: number;
  paymentOrderId: string;
  paymentAccessId: string;
  paymentStatus: PaymentStatus;
  userRecordEmail: string | null;
  amount: number;
  shopId: string;
  cardId: string | null;
  fincodeCustomerId: string | null;
  cancelReason: string | null;
};
export type FincodeConfig = {
  publicKey: string;
  isLiveMode: boolean;
  baseUrl: string;
};

type ExecutePaymentWithCardId = {
  payType: 'Card';
  cardId: string;
};
type ExecutePaymentWithCardToken = {
  payType: 'Card';
  cardToken: string;
};
type ExecutePaymentWithPayPay = {
  payType: 'Paypay';
  /**
   * Redirect Type of PayPay payment.
   *
   * - `1`: Web browser. PayPay app/website will open your redirect URL on web browser.
   * - `2`: Native App. PayPay app/website will open your redirect URL on your app.
   */
  redirectType?: '1' | '2';
};
export type ExecutePaymentDto = {
  appId: string;
  amount: number;
  description?: string;
} & (
  | ExecutePaymentWithCardId
  | ExecutePaymentWithCardToken
  | ExecutePaymentWithPayPay
);
export type CreateSubscriptionDto = {
  fincodePlanId: string;
  startDate: Date;
  stopDate?: Date;
  endMonthFlag: boolean;
  initAmount?: number;
} & (
  | {
      cardId: string;
    }
  | {
      cardToken: string;
    }
);
export type CreateSubscriptionResponse = {
  subscriptionId: string;
};
export type CancelSubscriptionRequest = {
  appId: string;
};
export type CancelSubscriptionResponse = {
  appId: string;
};
export type ExecutePaymentResponse = Pick<PaymentObject, 'id'> & {
  acs_url?: string;
  /**
   * Code URL of PayPay payment. Customer can pay to access this URL.
   */
  code_url?: string | null;
};
export type SaveCardDto = {
  cardToken: string;
  appId: string;
};
export type GetCardDefaultDto = {
  appId: string;
} & {
  testMode?: TestModeParams;
};

export type FincodeCard = {
  customer_id: string;
  id: string;
  card_no: string;
  expire: string;
  holder_name: string;
  brand: string;
};

export const fincodeActions = {
  getConfig: async (isTestMode: boolean) => {
    const res = await axiosInstance({
      method: 'get',
      url: `/fincode/config`,
      params: {
        isTestMode,
      },
    });
    return res.data as FincodeConfig;
  },

  executePayment: async (
    data: ExecutePaymentDto,
    testMode?: TestModeParams
  ) => {
    try {
      const res = await axiosInstance({
        method: 'POST',
        url: '/fincode/payment',
        data,
        params: {
          appId: data.appId,
          testMode,
        },
      });
      return res.data as ExecutePaymentResponse;
    } catch (error: any) {
      if (error.response?.data) {
        throw error.response?.data;
      }
      throw error;
    }
  },

  saveCard: async (data: SaveCardDto, testMode?: TestModeParams) => {
    try {
      const res = await axiosInstance({
        method: 'POST',
        url: '/fincode/card',
        data,
        params: {
          appId: data.appId,
          testMode,
        },
      });
      return res.data as FincodeCard;
    } catch (error: any) {
      if (error.response?.data) {
        throw error.response?.data;
      }
      throw error;
    }
  },

  getCardDefault: async (params: GetCardDefaultDto) => {
    try {
      const res = await axiosInstance({
        method: 'GET',
        url: '/fincode/card',
        params,
      });
      return res.data;
    } catch (error: any) {
      if (error.response?.data) {
        throw error.response?.data;
      }
      throw error;
    }
  },

  removeCardDefault: async (params: GetCardDefaultDto) => {
    try {
      const res = await axiosInstance({
        method: 'DELETE',
        url: '/fincode/card',
        params,
      });
      return res.data;
    } catch (error: any) {
      if (error.response?.data) {
        throw error.response?.data;
      }
      throw error;
    }
  },

  getPaymentStatus: async (orderId: string): Promise<FincodeAppOrderEntity> => {
    try {
      const res = await api({
        method: 'GET',
        url: `/fincode/app-order/${orderId}`,
      }).then((r) => r.data as FincodeAppOrderEntity);
      return res;
    } catch (error: any) {
      if (error.response?.data) {
        throw error.response?.data;
      }
      throw error;
    }
  },

  createSubscription: async (
    data: CreateSubscriptionDto,
    appId: string,
    testMode?: TestModeParams
  ) => {
    try {
      const res = await axiosInstance({
        method: 'POST',
        url: '/fincode/app-subscription',
        data,
        params: {
          appId,
          testMode,
        },
      });
      return res.data as CreateSubscriptionResponse;
    } catch (error: any) {
      if (error.response?.data) {
        throw error.response?.data;
      }
      throw error;
    }
  },
};
export type FincodeActionsType = typeof fincodeActions;
