import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import RenderScreen from '../screens/RenderScreen';
import history from './history';
import Home from '@common/screens/RenderScreen/Home';

export default function WebRoutes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/preview/:appId" component={RenderScreen} />
      </Switch>
    </Router>
  );
}
