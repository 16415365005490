export default {
  name: 'Switch',
  displayName: 'Switch',
  icon: './example-thumbnail.png',
  defaultWidth: 40,
  defaultHeight: 20,
  props: [
    // {
    //   name: 'toggle',
    //   displayName: 'What does this switch change?',
    //   type: 'boolean',
    //   role: 'autosaveInput',
    // },
    {
      name: 'color',
      displayName: {
        en: 'Color',
        ja: '色',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'value',
      displayName: {
        en: 'Default Value',
        ja: '初期値',
      },
      type: 'boolean',
      role: 'autosaveInput',
    },
    // {
    //   name: 'value',
    //   displayName: {
    //     en: 'Default Value',
    //     ja: '初期値',
    //   },
    //   type: 'text',
    //   control: {
    //     type: 'menu',
    //     options: [
    //       {
    //         label: 'canvas.menuRight.elementTab.switch.true',
    //         value: true,
    //       },
    //       {
    //         label: 'canvas.menuRight.elementTab.switch.false',
    //         value: false,
    //       },
    //     ],
    //   },
    // },
    {
      name: 'onActions',
      displayName: {
        en: 'ClickFlow when Switch is turned ON',
        ja: 'スイッチON時のClickFlow',
      },
      type: 'action',
    },
    {
      name: 'offActions',
      displayName: {
        en: 'ClickFlow when Switch is turned OFF',
        ja: 'スイッチOFF時のClickFlow',
      },
      type: 'action',
    },
  ],
  resizeX: false,
};
