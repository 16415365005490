import AnimationWrapper from '@common/components/Animation/NormalAnimation';
import { dimensionSelector } from '@common/redux/selectors/page';
import { IPage } from '@common/types/element';
import { animatedScreen } from '@common/utils';
import React, { Fragment } from 'react';
import { Animated } from 'react-native';
import { useSelector } from 'react-redux';
import Render from '../Render';

type Props = { modalScreen: IPage; modalTransition: string };

function ModalScreen({ modalScreen, modalTransition }: Props) {
  const dimension = useSelector(dimensionSelector);

  if (!modalScreen) return <Fragment />;

  return (
    <Animated.View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: dimension.width,
        height: dimension.height,
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
    >
      <AnimationWrapper animation={animatedScreen(modalTransition)}>
        <Render screen={modalScreen} />
      </AnimationWrapper>
    </Animated.View>
  );
}

export default ModalScreen;
