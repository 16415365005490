import BaseComponent from '@nocode/types/base.type';
import { PaymentIntent } from '@stripe/stripe-js';
import { get } from 'lodash';
import { StyleSheet } from 'react-native';
import { checkFont } from '../func';

export type CardStripeInfo = {
  exp_month: number;
  exp_year: number;
  brand: string;
  last4: string;
};
export interface IStripePaymentSecurity extends BaseComponent {
  accountConnectId: string;
  groupActionId: string;
  auth: Record<string, any>;
  handlePayment: (
    data:
      | {
          type: 'create-payment';
          testMode: {
            enabled: boolean;
            secretKey: string;
          };
          paymentMethodId: string;
          receipt_emil: string;
          destination: string;
          amount: string | number;
          description: string;
          currency: string;
          customer?: string;
        }
      | {
          type: 'get-card-info';
          secretKey?: string;
          paymentMethod: string;
          stripeAccount?: string;
        }
  ) => Promise<{ paymentIntent: PaymentIntent } | CardStripeInfo | null>;
  updateRecordRecordApi: (data: Record<string, any>) => Promise<void>;
  attributes: {
    successActions?: () => Promise<void>;
    failedActions?: () => Promise<void>;
    fontFamily: string;
    inactiveColor: string;
    activeColor: string;
    color: string;
    loading?: boolean;
    rememberCheckbox: {
      enabled: boolean;
      text: string;
      cardTokenSaved: string;
      cardTokenSavedFid?: string;
      customer?: string;
      customerFid?: string;
      icon: string;
    };
    testMode: {
      enabled: boolean;
      publishableKey: string;
      secretKey: string;
    };
    email: {
      emailBuyer: string;
    };
    changedescription: {
      description: string;
    };
    paymentOptions: {
      paymentAmount: number;
      typeCurrency: 'jpy' | 'usd';
    };
    submitButton: {
      text: string;
      borderRadius: number;
      type: 'payment' | 'saveCard';
    };
    opacity: number;
    title: {
      enabled: boolean;
      text: string;
    };
  };
  publicKey: string;
  locale: string;
  libraryName: string;
  componentName: string;
  onPress: (actionRef: any, options?: any) => Promise<any>;
  data: Record<string, any>;
  dependencies: Record<string, any>;
}

const createStyles = ({ attrs, width, height }: any) => {
  const { fontFamily, opacity } = attrs;
  return StyleSheet.create({
    container: {
      width,
      opacity,
    },
    errorText: {
      color: 'red',
      overflow: 'hidden',
    },
    successText: {
      color: 'green',
      overflow: 'hidden',
    },
    title: {
      fontFamily: checkFont(fontFamily),
      marginBottom: 5,
    },
    wrapperInput: {
      padding: 10,
      borderRadius: 5,
      borderColor: '#BDBDBD',
      borderWidth: 1,
      minHeight: 40,
    },
    block: {
      borderColor: '#BDBDBD',
      borderWidth: 1,
      borderRadius: 5,
    },
    clickActive: {
      backgroundColor: get(attrs, 'activeColor', 'white'),
      borderRadius: get(attrs, 'submitButton.borderRadius', 5),
      justifyContent: 'center',
      borderColor: get(attrs, 'activeColor', 'white'),
      marginTop: 5,
      minHeight: 30,
      alignItems: 'center',
    },
    clickInactive: {
      backgroundColor: get(attrs, 'inactiveColor', 'white'),
      borderRadius: get(attrs, 'submitButton.borderRadius', 5),
      justifyContent: 'center',
      borderColor: get(attrs, 'inactiveColor', 'white'),
      marginTop: 5,
      minHeight: 30,
      alignItems: 'center',
    },
    label: {
      fontFamily: checkFont(fontFamily),
      fontSize: 15,
      color: get(attrs, 'color', 'black'),
      textTransform: 'uppercase',
    },
    group: {
      position: 'absolute',
      top: 52,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      paddingHorizontal: 8,
    },
    placeHolder: { color: '#BDBDBD' },
    rememberCard: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: 40,
      position: 'relative',
    },
    rememberCardIosUncheck: {
      marginRight: 5,
      width: 20,
      height: 20,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: '#BDBDBD',
    },
  });
};

export default createStyles;
