import React, { useEffect, useState } from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';

const BackgroundImage = (props: any) => {
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [deviceHeight, setDeviceHeight] = useState<number>(0);
  const [deviceWidth, setDeviceWidth] = useState<number>(0);
  useEffect(() => {
    const { source } = props;
    const imageSource =
      typeof source === 'number' ? Image.resolveAssetSource(source) : source;
    if (imageSource) {
      Image.getSize(
        imageSource.uri || imageSource,
        setImageSize,
        console.error
      );
    }
    getDeviceDimensions();
    Dimensions.addEventListener('change', getDeviceDimensions);
    return Dimensions.removeEventListener('change', getDeviceDimensions);
  }, []);

  const getDeviceDimensions = () => {
    let { height, width } = Dimensions.get('window');
    height = Math.round(height);
    width = Math.round(width);
    return setDeviceHeight(height), setDeviceWidth(width);
  };

  const setImageSize = (width: any, height: any) => {
    return setImageHeight(height), setImageWidth(width);
  };

  const handleImageSize = () => {
    const { resizeMode } = props;
    const widthRatio = imageWidth ? deviceWidth / imageWidth : 0;
    const heightRatio = imageHeight ? deviceHeight / imageHeight : 0;
    let height;
    let width;
    if (
      imageHeight > 0 &&
      ((resizeMode === 'cover' && heightRatio > widthRatio) ||
        (resizeMode === 'contain' && heightRatio < widthRatio))
    ) {
      height = deviceHeight;
      width = (deviceHeight * imageWidth) / imageHeight;
    } else if (imageWidth > 0) {
      height = (deviceWidth * imageHeight) / imageWidth;
      width = deviceWidth;
    } else {
      height = deviceHeight;
      width = deviceWidth;
    }

    return { height: height || 0, width: width || 0 };
  };
  const handlePosition = () => {
    let { positionX, positionY } = props;

    switch (positionX) {
      case 'left':
      case 'flex-start':
        positionX = 'flex-start';
        break;
      case 'right':
      case 'flex-end':
        positionX = 'flex-end';
        break;
      case 'center':
      default:
        positionX = 'center';
        break;
    }

    switch (positionY) {
      case 'top':
      case 'flex-start':
        positionY = 'flex-start';
        break;
      case 'bottom':
      case 'flex-end':
        positionY = 'flex-end';
        break;
      case 'center':
      default:
        positionY = 'center';
        break;
    }

    return { positionX, positionY };
  };

  const getSource = () => {
    const { source } = props;

    if (typeof source === 'number') return source;
    return { uri: source };
  };
  const { positionX, positionY } = handlePosition();
  const viewStyles = {
    justifyContent: positionX,
    alignItems: positionY,
  };

  const imageStyles = {
    ...handleImageSize(),
  };

  const source = getSource();
  return (
    <View style={[styles.view, viewStyles]}>
      <Image source={source} style={[styles.image, imageStyles]} />
    </View>
  );
};

const styles = StyleSheet.create({
  view: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  image: {},
});

export default BackgroundImage;
