import { showQrCodeSelector } from '@common/redux/selectors/page';
import { onQrCodeSuccess, showQrCodeScanner } from '@common/redux/slice/page';
import React from 'react';
import { Modal, Text, TouchableOpacity, View } from 'react-native';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from './CloseIcon';
import createStyles from './style';

const QrCodeScanner = (): JSX.Element => {
  const dispatch = useDispatch();
  const styles = createStyles();
  const { visible } = useSelector(showQrCodeSelector);

  const handleCloseModal = () => {
    dispatch(showQrCodeScanner(false));
  };

  const onSuccess = (err: any, result: any) => {
    if (result) {
      dispatch(onQrCodeSuccess(result?.text));
    }
  };

  const onError = () => {
    handleCloseModal();
    alert('Not found camera !');
  };

  return (
    <Modal visible={visible} onRequestClose={handleCloseModal}>
      <View style={styles.wrapper}>
        <View style={styles.frameLine} />
        <TouchableOpacity onPress={handleCloseModal} style={styles.back}>
          <CloseIcon />
        </TouchableOpacity>
        <BarcodeScannerComponent
          delay={100}
          onError={onError}
          onUpdate={onSuccess}
        />
      </View>
    </Modal>
  );
};

export default QrCodeScanner;
