import { get } from 'lodash';
import { StyleSheet, Platform } from 'react-native';
import { checkFont, checkStyles } from '../func';

const createStyles = ({
  attributes,
  isError,
}: {
  attributes: any;
  isError: boolean;
}) => {
  const {
    title,
    width,
    opacity,
    titleCardName,
    titleCardNumber,
    submitButton,
  } = attributes;

  return StyleSheet.create({
    wrapperInput: {
      padding: 10,
      borderRadius: 5,
      borderColor: '#BDBDBD',
      borderWidth: 1,
      minHeight: 40,
    },
    title: {
      marginBottom: 6,
      ...checkStyles(title),
    },

    text: {
      paddingVertical: 8,
      color: isError ? '#ff0000' : '#008000',
    },

    btn: {
      justifyContent: 'center',
      marginTop: 20,
      minHeight: 44,
      backgroundColor: get(attributes, 'submitButton.backgroundColor'),
      borderColor: get(attributes, 'submitButton.backgroundColor'),
      borderRadius: get(attributes, 'submitButton.borderRadius'),
    },

    btnMobile: {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      height: 44,
      lineHeight: 44,
      backgroundColor: get(attributes, 'submitButton.backgroundColor'),
      borderColor: get(attributes, 'submitButton.backgroundColor'),
      borderRadius: get(attributes, 'submitButton.borderRadius'),
    },

    labelStyleMobile: {
      fontFamily: checkFont(attributes.fontFamily),
      fontSize: get(attributes, 'submitButton.fontSize'),
      color: get(attributes, 'submitButton.color'),
      marginTop: 0,
      marginLeft: 0,
    },

    labelStyle: {
      fontFamily: checkFont(attributes.fontFamily),
      fontSize: get(attributes, 'submitButton.fontSize'),
      color: get(attributes, 'submitButton.color'),
    },

    // style on mobile
    container: {
      width,
      opacity,
    },
    inputCardName: {
      padding: 10,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: 'rgb(189, 189, 189)',
      marginBottom: 10,
    },
    inputCardContainer: {
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'space-between',
      // alignItems: 'center',
      // padding: 10,
      fontSize: 14,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: 'rgb(189, 189, 189)',
    },

    inputCardImage: {
      // flex: 0.2,
      paddingRight: 8,
    },

    cardImage: { width: 21, height: 14 },

    inputCardNumber: {
      flex: 1,
      paddingRight: 8,
    },

    inputCardExpiration: {
      flex: 0.4,
      paddingRight: 8,
      ...(Platform.OS !== 'android' && { maxWidth: 62 }),
    },

    inputCardCVC: {
      flex: 0.3,
      maxWidth: 40,
    },

    input: {
      fontSize: 14,
      ...(Platform.OS === 'android' && { paddingVertical: 0 }),
      ...(Platform.OS === 'web' && {
        outlineStyle: 'none',
        outlineColor: 'transparent',
        placeholderTextColor: 'rgb(189, 189, 189)',
      }),
    },
    btnCard: {
      marginTop: 20,
    },
    btnSubmit: {
      // opacity: disabledButtonSubmit ? 0.6 : 1,
    },
  });
};

export default createStyles;
