import { HEIGHT_STATUS_BAR } from '@common/constants/shared';
import { loseConnectSelector } from '@common/redux/selectors/liveStream';
import { IPage } from '@common/types/index';
import React, { Fragment } from 'react';
import { Platform, StatusBar as StatusBarRN, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

type Props = {
  originY: number;
  screen: IPage;
};

function LoseConnectLiveStream({ originY, screen }: Props) {
  const loseConnect = useSelector(loseConnectSelector);

  const isStartLiveComponent =
    screen?.metadata[0]?.type === 'LiveStreamCamera' &&
    screen?.metadata[0]?.defaultScreen === 'start';

  if (!loseConnect || !isStartLiveComponent) return <Fragment />;

  return (
    <>
      <StatusBarRN barStyle={'light-content'} />
      <View
        style={{
          width: '100%',
          height:
            Platform.OS === 'ios'
              ? HEIGHT_STATUS_BAR + 25 * originY
              : 25 * originY,
          alignItems: 'center',
          backgroundColor: 'red',
        }}
      >
        <Text
          style={{
            color: '#fff',
            position: 'absolute',
            bottom: 3,
            fontSize: 12,
            fontFamily: 'Noto Sans JP',
            fontWeight: '500',
          }}
        >
          インターネット接続がありません
        </Text>
      </View>
    </>
  );
}
export default LoseConnectLiveStream;
