import { useEffect, useState } from 'react';

const useLocation = () => {
  const [location, setLocation] = useState<{
    loaded: boolean;
    currentLocation: any;
    error: object;
  }>({
    loaded: false,
    currentLocation: undefined,
    error: {},
  });

  const onSuccess = (location: any) =>
    setLocation((state) => ({
      ...state,
      loaded: true,
      currentLocation: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    }));

  const onError = (error: any) =>
    setLocation((state) => ({ ...state, loaded: true, error }));

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return location;
};

export default useLocation;
