import store from '@common/redux/store';
import { IAction } from '@common/types/';
import { Platform } from 'react-native';

export const sendEventAnalytics = async (action: IAction) => {
  const { eventName = '' } = action.options || {};

  const gtag = window.gtag as any; //only in web
  const state: any = store.getState();
  const { email = '' } = state.auth.profile || {};
  const { isAuth } = state.auth;
  if (typeof gtag === 'function' && Platform.OS === 'web')
    gtag(
      'event',
      `click_${eventName}`,
      isAuth && { logged_user: email, platform: Platform.OS }
    );
  return {
    status: 'SUCCEED',
  };
};
