import { ActionStatus } from '@common/constants/shared';
import { formValueSelector } from '@common/redux/selectors/formInputs';
import { resetValueForm, setFormValue } from '@common/redux/slice/formInputs';
import { Field, IForm, IObj, Obj } from '@common/types';
import { getActions } from '@common/utils/handleActions/func/helps';
import { find, forEach, get, isEmpty } from 'lodash';
import moment from 'moment';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import FormField from './FormField';
import { generateSchema, IField } from './schema';
import createStyles from './style';
import SubmitButton from './SubmitButton';

type State = {
  loading: boolean;
  notifications: IObj;
  formError: IObj;
  errors: {
    [key: string]: Array<{
      id: string;
      message: string;
    }>;
  };
};

export const ActionContext = createContext({});
export const useActionContext = () => useContext(ActionContext);

const CusForm: FC<IForm> = (object) => {
  const { onPress } = object;
  const dispatch = useDispatch();
  const styles = createStyles(object);
  const reduxFormFields = useSelector(formValueSelector);

  const [state, setState] = useState<State>({
    loading: false,
    errors: {},
    notifications: {},
    formError: {},
  });

  let { fields, submitButton, hidden } = object || {};

  const valuesChanged = useMemo(() => {
    let valueChanges: Obj = {};
    forEach(object.fields, (field: any) => {
      valueChanges[field.fieldId] =
        reduxFormFields[`${object.id}-${field.fieldId}`];
    });

    return valueChanges;
  }, [object, reduxFormFields]);

  const handleSubmit = useCallback(() => {
    setState({
      ...state,
      loading: true,
      errors: {},
    });

    const fields: Array<IField | any> = object.fields;
    const schema = generateSchema(fields, 'ja');

    schema
      .validate(valuesChanged, { abortEarly: false })
      .then(function (val) {
        val && setState({ ...state, errors: {} });
        val &&
          onPress(getActions(object.actions), {
            record: val,
            formId: object.id,
          })
            .then((resp: any) => {
              if (!resp) return;
              if (!Array.isArray(resp)) {
                setState((props) => ({
                  ...props,
                  formError: resp?.message,
                }));
              }
              const actionId = getActions(object.actions);
              const actions = get(object, `actions.${actionId}.actions`, []);
              const defaultAction = actions[0];
              const successMessage = find(resp, {
                actionId: defaultAction?.id,
                status: ActionStatus.SUCCESS,
              });

              const errorMessage = find(resp, {
                actionId: defaultAction?.id,
                status: ActionStatus.FAILED,
              });

              if (successMessage) {
                setState((props) => ({
                  ...props,
                  notifications: {
                    ...props.notifications,
                    [object.id]: successMessage.message,
                  },
                }));
              }
              if (errorMessage) {
                setState((props) => ({
                  ...props,
                  formError: {
                    ...props.formError,
                    [object?.id]: errorMessage.message,
                  },
                }));
              }
            })
            .then(() => {
              setTimeout(
                () =>
                  setState((props) => ({
                    ...props,
                    formError: { ...props.formError, [object.id]: '' },
                    notifications: { ...props.notifications, [object.id]: '' },
                  })),
                2000
              );
            });
      })
      .catch(function (err: any) {
        let errors: any[] = [];
        err.inner.forEach((e: any) => {
          errors.push({ id: e.path, message: e.message });
        });
        setState({ ...state, errors: { [object.id]: errors }, loading: false });
      });
  }, [object.fields, valuesChanged]);

  const renderMessage = useCallback(() => {
    return (
      <View>
        <Text style={{ color: 'green' }}>
          {get(state, `notifications.${object?.id}`, '')}
        </Text>
      </View>
    );
  }, [state.notifications, object.id]);

  const renderError = useCallback(() => {
    return (
      <View>
        <Text style={{ color: 'red' }}>
          {get(state, `formError.${object?.id}`, '')}
        </Text>
      </View>
    );
  }, [state.formError]);

  useEffect(() => {
    // set default value Form
    if (!object?.actions) return;
    const actionId = getActions(object.actions);
    const actions = get(object, `actions.${actionId}.actions`);

    const handleSetValue = async () => {
      try {
        if (actions.length <= 0) return;
        const defaultAction = actions[0];
        if (defaultAction?.actionType == 'updateObject') {
          const defaultValueForm = get(object, 'data.reference', {});
          if (defaultValueForm === null) return;
          const valueInput = fields.reduce(
            (acc, { fieldId, ...fieldProps }) => {
              const fieldValue = defaultValueForm[fieldId];
              return {
                ...acc,
                [`${object.id}-${fieldId}`]:
                  typeof fieldProps.type !== 'string' && fieldValue?.id
                    ? fieldValue?.id
                    : fieldValue,
              };
            },
            {}
          );
          !isEmpty(valueInput) && dispatch(setFormValue(valueInput));
        } else {
          const valueInput = fields.reduce(
            (acc, { fieldId, ...fieldProps }) => {
              return {
                ...acc,
                [`${object.id}-${fieldId}`]:
                  fieldProps.type === 'date' || fieldProps.type === 'dateOnly'
                    ? moment().format()
                    : fieldProps?.type === 'boolean'
                    ? false
                    : undefined,
              };
            },
            {}
          );
          !isEmpty(valueInput) && dispatch(setFormValue(valueInput));
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    handleSetValue();

    return () => {
      // reset value Form
      const valueField = object.fields.reduce((pre, curr) => {
        return { ...pre, [`${object.id}-${curr.fieldId}`]: '' };
      }, {});
      !isEmpty(valueField) && dispatch(resetValueForm(valueField));
    };
  }, [object.data]);

  const objectError = get(state.formError, object?.id);
  const objectMessage = get(state.notifications, object?.id);

  return object.database ? (
    <View style={styles.container}>
      {fields &&
        fields.map((field: Field) => {
          const fieldId = field?.fid || field.fieldId;
          // * check if field has an error
          const hasError = (state.errors[object.id] || []).find(
            (err) => err.id === fieldId
          );

          return (
            <FormField
              key={fieldId}
              object={object}
              field={field}
              active={hidden}
              hasError={hasError}
            />
          );
        })}
      {objectError ? renderError() : null}
      {!objectError && objectMessage ? renderMessage() : null}

      <SubmitButton
        {...submitButton}
        marginTop={fields.length ? 20 : 0}
        onSubmit={handleSubmit}
        submitting={state.loading}
        disabled={state.loading}
      />
    </View>
  ) : (
    <></>
  );
};
export default CusForm;
