import { getImageNames } from '@common/components/ImageUploadList/constant';
import ImageUploadWeb from '@common/components/ImageUploadList/imageUploadWeb';
import { getValueFields } from '@common/redux/selectors/formInputs';
import { setValueInput } from '@common/redux/slice/formInputs';
import { getValueBindingComponent } from '@common/utils/component';
import { get } from 'lodash';
import React, { FC, useEffect, useMemo } from 'react';
import { FlatList } from 'react-native';
import { View } from 'react-native-animatable';
import { useDispatch, useSelector } from 'react-redux';
import createStyles from './style';

const CusImageUploadList: FC<any> = (attributes) => {
  const dispatch = useDispatch();
  const styles = createStyles(attributes);
  const valueInputs = useSelector(getValueFields)[attributes.id] || [];

  const maxImage = useMemo(() => get(attributes, 'maximumImage'), [attributes]);
  const imageNames = getImageNames(maxImage);
  const defaultValues = imageNames.map((name) =>
    getValueBindingComponent(attributes, name)
  );

  useEffect(() => {
    const temp: any[] = [...valueInputs];
    let changed = false;
    for (let i = 0; i < maxImage; i++) {
      if (temp.length < i + 1) {
        temp.push(null);
        changed = true;
      }
      if (
        !temp[i] &&
        defaultValues[i] &&
        typeof defaultValues[i] === 'string'
      ) {
        temp[i] = {
          url: defaultValues[i],
        };
        changed = true;
      }
    }

    changed && dispatch(setValueInput({ [attributes.id]: temp }));
  }, [dispatch, valueInputs, defaultValues]);

  return (
    <View style={styles.container}>
      <FlatList
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        horizontal
        scrollEnabled={true}
        contentContainerStyle={styles.flatlist}
        data={defaultValues}
        renderItem={({ item, index }) => {
          return (
            <ImageUploadWeb
              key={index}
              attributes={attributes}
              index={index}
              defaultImage={item}
              valueInputs={valueInputs}
            />
          );
        }}
      />
    </View>
  );
};

export default CusImageUploadList;
