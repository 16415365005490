import { combineReducers } from 'redux';
import action from './action';
import appInfo from './app';
import authReducer from './auth';
import database from './database';
import formInputs from './formInputs';
import language from './language';
import locationHistory from './locationHistory';
import pages from './page';
import notification from '@common/redux/slice/notification';
import admob from '@common/redux/slice/admob';
import customAction from '@common/redux/slice/customAction';
import externalCollection from '@common/redux/slice/externalCollection';
import listRecords from '@common/redux/slice/listRecords';
import liveStream from '@common/redux/slice/liveStream';
import planPermission from '@common/redux/slice/planPermission';

const rootReducer = combineReducers({
  pageInfo: pages,
  auth: authReducer,
  appInfo: appInfo,
  action: action,
  language,
  formInputs,
  locationHistory,
  database,
  notification,
  admob,
  customAction,
  externalCollection,
  listRecords,
  liveStream,
  planPermission,
});

export default rootReducer;
