import AnimationWrapper from '@common/components/Animation/NormalAnimation';
import { IPage } from '@common/types/element';
import { animatedScreen } from '@common/utils/index';
import React from 'react';
import { Animated } from 'react-native';
import Render from '../Render';
import ErrorComponent from './ErrorComponent';

type Props = {
  screen: IPage;
  animation: string;
};

function PageScreen({ screen, animation }: Props) {
  return (
    <Animated.View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <AnimationWrapper animation={animatedScreen(animation)}>
        <Render screen={screen} />
        <ErrorComponent />
      </AnimationWrapper>
    </Animated.View>
  );
}

export default PageScreen;
