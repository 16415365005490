import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find } from 'lodash';

type Obj = { [key: string]: any };

type State = {
  values: any[];
};

const initialState: State = {
  values: [],
};

const listRecordsSlice = createSlice({
  name: 'listRecords',
  initialState: initialState,
  reducers: {
    setListRecordValue: (state: State, action: PayloadAction<any>) => {
      const record = action.payload;
      const index = state.values.findIndex((item) => item.id === record.id);
      if (record.id && index === -1) {
        state.values.push(record);
      } else {
        state.values[index] = { ...state.values[index], ...record };
      }
    },
    deleteRecordValue: (state: State, action: PayloadAction<any>) => {
      const record = action.payload;
      const index = state.values.findIndex((item) => item.id === record.id);
      if (record.id && find(state.values, { id: record.id })) {
        state.values.splice(index, 1);
      }
    },
  },
});

export const { setListRecordValue, deleteRecordValue } =
  listRecordsSlice.actions;
export default listRecordsSlice.reducer;
