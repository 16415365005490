import Loading from '@common/components/Loading';
import { getValueBindingComponent } from '@common/utils/component';
import {
  getActions,
  getItemListClick,
} from '@common/utils/handleActions/func/helps';
import Lottie from 'lottie-react-native';
import { default as React, useState } from 'react';
import { TouchableHighlight } from 'react-native';
import { Text } from 'react-native-paper';
import createStyles from './style';
import { isEmpty } from 'lodash';

const Animation: React.FC<any> = (attributes) => {
  const [error, setError] = useState<string>();
  const styles = createStyles(attributes);
  const url = getValueBindingComponent(attributes, 'url');
  const loop = !!attributes.loop;
  const imageResize = attributes.imageResize || 'contain';

  const handlePress = (event: any) => {
    event.preventDefault();
    let arrayAction = getActions(attributes.actions);

    attributes.onPress &&
      attributes.onPress(arrayAction, {
        itemListClick: getItemListClick(attributes?.record),
      });
  };

  return (
    <TouchableHighlight
      style={styles.container}
      disabled={isEmpty(attributes.actions)}
      activeOpacity={1}
      {...(attributes.actions && {
        onPress: handlePress,
      })}
    >
      <>
        {url ? (
          <Lottie
            source={{ uri: url }}
            webStyle={{
              ...(imageResize === 'cover'
                ? {
                    width: Math.max(attributes.width, attributes.height),
                    height: Math.max(attributes.width, attributes.height),
                  }
                : {
                    width: attributes.width,
                    height: attributes.height,
                  }),
            }}
            style={{
              width: attributes.width,
              height: attributes.height,
            }}
            onAnimationFailure={(error) => {
              console.error('===Lottie Error===', error);
              setError(error);
            }}
            resizeMode={imageResize} // only work on native-app
            loop={loop}
            autoPlay
          />
        ) : (
          <Loading size={'small'} />
        )}
        {error ? <Text style={{ color: '#FF0000' }}>{error}</Text> : <></>}
      </>
    </TouchableHighlight>
  );
};

export default Animation;
