import { APP_API_TOKEN, TOKEN_KEY } from '@common/constants/shared';
import appConfig from '../../../appConfig.json';
import axios, { AxiosRequestConfig } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const axiosInstance = async (options: AxiosRequestConfig) => {
  const token = (await AsyncStorage.getItem(TOKEN_KEY)) || APP_API_TOKEN;

  return axios.create({
    baseURL: appConfig.baseUrl,
    timeout: 60 * 2 * 1000,
    headers: {
      authorization: 'Bearer ' + token,
    },
  })(options);
};

export default axiosInstance;
