import { IPassword } from '@common/types/element';
import React, { FC } from 'react';
import { TextInput, TouchableOpacity } from 'react-native';
import connectorInput from '../ConnectorInput';
import createStyles from './style';

const Password: FC<IPassword> = (attributes) => {
  const { changeInput, valueInput } = attributes;
  const styles = createStyles(attributes);

  const sanitizeValue = (value: any) => {
    return typeof value === 'string' ? value : '';
  };

  const checkPlaceholder = (value: any) => {
    if (Array.isArray(value)) {
      return value[0];
    } else {
      return value;
    }
  };

  return (
    <TouchableOpacity activeOpacity={1} style={styles.container}>
      <TextInput
        onChangeText={changeInput}
        value={sanitizeValue(valueInput)}
        style={[
          styles.text,
          { display: 'flex', alignSelf: 'center', textAlignVertical: 'center' },
        ]}
        placeholder={checkPlaceholder(attributes.placeholder)}
        placeholderTextColor={attributes.placeholderColor}
        secureTextEntry={true}
      />
    </TouchableOpacity>
  );
};

export default connectorInput(Password);
