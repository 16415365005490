import { checkFont } from '../func';

export const getStyles = (attributes: any) => {
  const {
    answers = {},
    inputs = {},
    labels = {},
    numberLabels = {},
  } = attributes;

  return {
    labelsStyle: {
      ...labels,
    },
    answersStyle: {
      ...answers,
      backgroundColor: 'transparent',
      fontFamily: checkFont(answers.fontFamily, answers.fontWeightBold),
    },
    inputsStyle: {
      ...inputs,
      fontSize: inputs.fontSize || 16,
      padding: inputs.padding || 10,
      marginTop: 7,
      borderWidth: 1,
      borderRadius: inputs.borderRadius || 4,
      fontFamily: checkFont(inputs.fontFamily, inputs.fontWeightBold),
      color: inputs.color === '#BDBDBD' ? '#000000' : inputs.color,
      placeholderColor: answers?.placeholderColor || '#BDBDBD',
    },

    numberLabelsStyle: {
      ...numberLabels,
      fontFamily: checkFont(
        numberLabels.fontFamily,
        numberLabels.fontWeightBold
      ),
    },

    answersBackgroundColor: answers.backgroundColor,
  };
};
