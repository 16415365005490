import AnimationWrapper from '@common/components/Animation/NormalAnimation';
import { forgotPassword } from '@common/redux/selectors/action';
import { appInfoSelector } from '@common/redux/selectors/app';
import { appLanguageSelector } from '@common/redux/selectors/language';
import { setForgotPassword } from '@common/redux/slice/action';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Animated, Text, TextInput, View } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPopup from './ConfirmPopup';
import { convertMessage, useForgotPassword } from './func';
import createStyles from './style';
import { Fragment } from 'react';

export type Response = {
  status: boolean;
  loading: boolean;
  error: string;
};

const ForgotPasswordWrap = () => {
  const dispatch = useDispatch();
  const isForgotPassword = useSelector(forgotPassword);
  const { locale } = useSelector(appLanguageSelector);
  const appInfo = useSelector(appInfoSelector);

  const [value, setValue] = useState<string>('');
  const [response, setResponse] = useState<Response>({
    status: false,
    loading: false,
    error: '',
  });

  const styles = createStyles(appInfo);

  const isJa = locale === 'ja';

  const onCancel = () => {
    dispatch(
      setForgotPassword({
        state: false,
        animation: 'fadeOutDownBig',
      })
    );
  };

  const onSubmit = async () => {
    try {
      if (!appInfo) return;

      setResponse({
        ...response,
        loading: true,
      });

      const res = await useForgotPassword({ appId: appInfo?.id, value });

      setResponse({
        ...response,
        status: !!res,
        loading: false,
      });
    } catch (err: any) {
      setResponse({
        ...response,
        error:
          get(err, 'response.data.errors.email', '') ||
          get(err, 'response.data.message', '') ||
          err.message,
      });

      console.log('err :>> ', err);
    }
  };

  if (!isForgotPassword.state || !appInfo) return <Fragment />;

  return (
    <Animated.View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: '#fff',
      }}
    >
      <AnimationWrapper animation={isForgotPassword.animation}>
        <View
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '100%',
          }}
        >
          {!response.status ? (
            <View style={styles.wrapper}>
              <Animated.View>
                <AnimationWrapper animation="fadeInUpBig">
                  <View>
                    <Text style={styles.title}>
                      {isJa ? 'パスワードのリセット' : 'Password Reset'}
                    </Text>
                    <Text style={styles.desc}>
                      {isJa
                        ? 'メールアドレスを入力すると、パスワードをリセットするためのメールが送信されます'
                        : `Enter your email address and we'll send you instructions on how to reset your password.`}
                    </Text>

                    <Text style={styles.text}>{isJa ? 'メール' : 'Email'}</Text>
                    <TextInput
                      style={styles.input}
                      onChangeText={(e) => setValue(e)}
                      placeholder={
                        isJa
                          ? 'メールアドレスを入力してください'
                          : 'Enter email...'
                      }
                      placeholderTextColor="#777777"
                    />
                    <Text style={styles.error}>
                      {convertMessage({
                        message: response.error,
                        locale: locale,
                      })}
                    </Text>

                    <RNButton
                      onPress={onSubmit}
                      labelStyle={{
                        color: '#fff',
                        fontSize: 14,
                      }}
                      style={{
                        backgroundColor: response.loading
                          ? '#BDBDBD'
                          : '#34A0DD',
                        borderRadius: 4,
                        minHeight: 40,
                        justifyContent: 'center',
                        marginTop: 16,
                      }}
                      disabled={response.loading}
                      loading={response.loading}
                    >
                      {isJa ? '送信' : 'Submit'}
                    </RNButton>
                    <RNButton
                      onPress={onCancel}
                      labelStyle={{
                        fontSize: 14,
                        color: '#777777',
                      }}
                      style={{
                        borderRadius: 4,
                        minHeight: 40,
                        justifyContent: 'center',
                        marginTop: 5,
                      }}
                    >
                      {isJa ? 'キャンセル' : 'Cancel'}
                    </RNButton>
                  </View>
                </AnimationWrapper>
              </Animated.View>
            </View>
          ) : (
            <View style={styles.wrapper}>
              <Animated.View>
                <AnimationWrapper animation="fadeInRightBig">
                  <ConfirmPopup
                    email={value}
                    setResponse={setResponse}
                    locale={locale}
                  />
                </AnimationWrapper>
              </Animated.View>
            </View>
          )}
        </View>
      </AnimationWrapper>
    </Animated.View>
  );
};

export default ForgotPasswordWrap;
