export default {
  name: 'StripePayment',
  displayName: {
    en: 'Stripe Connection',
    ja: 'Stripe接続',
  },
  icon: './icon.png',
  defaultWidth: 330,
  defaultHeight: 125,
  // resizeX: true,
  // resizeY: true,

  props: [
    {
      name: 'Settings',
      displayName: {
        en: 'Settings',
        ja: 'Settings',
      },
      type: 'payment',
    },
    {
      name: 'successActions',
      displayName: {
        en: 'ClickFlow when Stripe Payment is paid successful',
        ja: '決済成功時のClickFlow',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: 'ClickFlow when Stripe Payment is paid failed',
        ja: '決済失敗時のClickFlow',
      },
      type: 'action',
    },
  ],
  childComponents: [
    {
      name: 'email',
      displayName: {
        en: 'Email Field',
        ja: 'メールアドレス',
      },
      props: [
        {
          name: 'emailBuyer',
          displayName: {
            en: 'Buyer email',
            ja: '購入者のメールアドレス',
          },
          type: 'text',
          default: '',
        },
      ],
    },

    {
      name: 'paymentOptions',
      displayName: {
        en: 'Payment Amount',
        ja: '支払い金額',
      },
      props: [
        {
          name: 'paymentAmount',
          displayName: {
            en: 'Payment Amount',
            ja: '支払い金額',
          },
          type: 'number',
          default: 0,
        },

        {
          name: 'typeCurrency',
          displayName: {
            en: 'Currency Type',
            ja: '通貨',
          },
          type: 'text',
          default: 'jpy',
          control: {
            type: 'menu',
            options: [
              {
                label: 'JPY',
                value: 'jpy',
              },
              {
                label: 'USD',
                value: 'usd',
              },
            ],
          },
        },
      ],
    },

    {
      name: 'changedescription',
      displayName: {
        en: 'Payment Details',
        ja: '支払い詳細',
      },
      props: [
        {
          name: 'description',
          displayName: {
            en: 'Description',
            ja: '詳細',
          },
          type: 'text',
          default: '',
        },
      ],
    },

    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      // type: 'text',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Card information',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#000000',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#fff',
          enabled: {
            background: true,
          },
        },
        {
          name: 'fontSize',
        },
        {
          name: 'titleLineNum',
        },
        {
          name: 'fontStyleItalic',
        },
        {
          name: 'textDecorationUnderline',
        },
        {
          name: 'textDecorationLineThrough',
        },
        {
          name: 'fontWeightBold',
        },
      ],
    },

    {
      name: 'submitButton',
      displayName: {
        en: 'Submit Button',
        ja: '送信ボタン',
      },
      // type: 'text',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Pay Now',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'fontSize',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#fff',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#34A0DD',
        },
        {
          name: 'borderRadius',
          default: 100,
        },
      ],
    },
  ],
};
