import { ActionStatus } from '@common/constants/shared';
import { signupUserSuccess } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import io from '@common/services/featherIO/feathers.io';
import { MetadataAction } from '@common/types/action';
import { get, pick } from 'lodash';
import { ActionResponse } from '../excuteAction';
import { handleError } from '../func/func';
import { registerFCMUser, unregisterFCMUser } from './FCMregister';

export const signUp: (
  action: any,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetadataAction
) => {
  try {
    const { messages } = metadata;
    const dispatch = store.dispatch;
    const state: any = store.getState();

    if (action.status === ActionStatus.ERROR) {
      return {
        status: ActionStatus.FAILED,
        message: handleError(action.error, messages) || '',
      };
    }

    const response = get(action, 'response');
    await unregisterFCMUser(); //remove old token
    await registerFCMUser(response, state.appInfo.app?.id);
    await io.authenticate({
      strategy: 'jwt',
      accessToken: response?.accessToken,
    });

    dispatch(
      signupUserSuccess({
        user: {
          ...response,
          appId: state.appInfo.app?.id,
          ...pick(response, ['createdAt', 'updatedAt', 'databaseId']),
        },
      })
    );

    return {
      status: ActionStatus.SUCCESS,
      message: messages['auth_signupSuccess'],
      accessToken: response?.accessToken,
      profile: response,
    };
  } catch (error: any) {
    console.log('error', error);
    return { status: ActionStatus.FAILED, message: error.message };
  }
};
