import { ElementType, IPage } from '@common/types/element';
import React from 'react';
import { KeyboardAvoidingView, View } from 'react-native';
import ObjectRender from '../ObjectRender';

type Props = { arrFixed: ElementType[]; screen: IPage };

function FixedComponent({ arrFixed, screen }: Props) {
  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: '100%',
      }}
      pointerEvents="box-none"
    >
      <KeyboardAvoidingView
        behavior="height"
        style={{ flex: 1 }}
        pointerEvents="box-none"
        enabled={true}
        keyboardVerticalOffset={0}
      >
        {arrFixed.length > 0 && (
          <ObjectRender arrComp={arrFixed} isScreen={true} screen={screen} />
        )}
      </KeyboardAvoidingView>
    </View>
  );
}

export default FixedComponent;
