import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type typeProps = {
  color?: string;
  size?: number;
};
const CloseIcon: React.FC<typeProps> = ({ size }) => {
  return (
    <Svg
      width={size || '25px'}
      height={size || '25px'}
      viewBox="0 0 1200.000000 1200.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <G
        transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)"
        fill="#707f89"
        stroke="none"
      >
        <Path
          d="M2180 9814 l-425 -426 1700 -1694 1699 -1695 -1697 -1697 -1697
-1697 422 -423 423 -422 1697 1697 1698 1698 1698 -1698 1697 -1697 423 422
422 423 -1697 1697 -1698 1698 1698 1698 1697 1697 -422 423 -423 422 -1697
-1697 -1698 -1698 -1698 1698 -1697 1697 -425 -426z"
        />
      </G>
    </Svg>
  );
};

export default CloseIcon;
