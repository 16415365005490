export default {
  name: 'BarcodeScanner',
  displayName: { ja: 'バーコードスキャナー', en: 'BarcodeScanner' },
  resizeX: true,
  props: [
    {
      name: 'value',
      type: 'string',
      role: 'formValue',
    },
    {
      name: 'onPress',
      type: 'action',
      displayName: {
        en: 'Click Actions',
        ja: 'Click Actions',
      },
    },
  ],
};
