import { StyleSheet, Dimensions } from 'react-native';
const windowWidth = Dimensions.get('window').width;

const createStyles = (): Record<string, any> =>
  StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
      justifyContent: 'center',
      alignItems: 'center',
    },
    frameLine: {
      position: 'absolute',
      width: 200,
      height: 200,
      borderWidth: 2,
      borderColor: 'white',
    },
    fullWidth: { width: '100%', height: '100%' },
    back: {
      width: 29,
      height: 29,
      backgroundColor: 'white',
      position: 'absolute',
      top: windowWidth / 10,
      right: 23,
      zIndex: 99,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 23,
    },
  });

export default createStyles;
