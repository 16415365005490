import jaMessages from '../locales/ja-JP.json';

const JaLang = {
  messages: {
    ...jaMessages,
  },
  locale: 'ja',
};

export default JaLang;
