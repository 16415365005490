import React from 'react';

import StartLiveWeb from '@common/components/LiveStreamCamera/WebSource/StartLiveWeb';
import ViewLiveWeb from '@common/components/LiveStreamCamera/WebSource/ViewLiveWeb';
import { getValueBindingComponent } from '@common/utils/component';
import { useSelector } from 'react-redux';
import { profileSelector } from '@common/redux/selectors/auth';

const LiveStreamCamera: React.FC<any> = (attrs) => {
  const { defaultScreen, thumbnail } = attrs;
  const profile = useSelector(profileSelector);

  const title = getValueBindingComponent(attrs, 'title');
  const streamId = getValueBindingComponent(attrs, 'streamId');

  return (
    <>
      {defaultScreen === 'view' ? (
        <ViewLiveWeb
          {...attrs}
          profile={profile}
          streamId={streamId}
        ></ViewLiveWeb>
      ) : (
        <StartLiveWeb
          {...attrs}
          infoUser={profile}
          thumbnail={thumbnail}
          title={title}
        ></StartLiveWeb>
      )}
    </>
  );
};

export default React.memo(LiveStreamCamera);
