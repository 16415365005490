import CusAdmob from '@common/components/Admob';
import Animation from '@common/components/AnimationLottie';
import CusDatePicker from '@common/components/DatePicker';
import CusFileUpload from '@common/components/FileUpload';
import CusFrom from '@common/components/Form';
import CusGroup from '@common/components/Group';
import CusImage from '@common/components/Image';
import CusImageUpload from '@common/components/ImageUpload';
import CusImageUploadList from '@common/components/ImageUploadList';
import CusInput from '@common/components/Input';
import CusLabel from '@common/components/Label';
import CusLibraryComponent from '@common/components/LibraryComponent';
import CusList from '@common/components/List';
import CusLiveStreamCamera from '@common/components/LiveStreamCamera';
import CusPassword from '@common/components/Password';
import CusQuestion from '@common/components/Question';
import CusRectangle from '@common/components/Rectangle';
import CusSelect from '@common/components/Select';
import CusTable from '@common/components/Table/index';
import CusVimeo from '@common/components/Vimeo';
import WebView from '@common/components/WebView';
import CusYoutube from '@common/components/Youtube';
import Stamp from '@common/components/Stamp';
import StampHistory from '@common/components/StampHistory';
import { ComponentType } from '@common/types';
// import CusCustomerChat from '@common/components/CustomerChat';
import CusInvisibleShape from '@common/components/InvisibleShape';

export const mapping: Record<string, any> = {
  [ComponentType.input]: CusInput,
  [ComponentType.label]: CusLabel,
  [ComponentType.rectangle]: CusRectangle,
  [ComponentType.image]: CusImage,
  [ComponentType.libraryComponent]: CusLibraryComponent,
  [ComponentType.password]: CusPassword,
  [ComponentType.select]: CusSelect,
  [ComponentType.datePicker]: CusDatePicker,
  [ComponentType.list]: CusList,
  [ComponentType.form]: CusFrom,
  [ComponentType.youtube]: CusYoutube,
  [ComponentType.animation]: Animation,
  [ComponentType.vimeo]: CusVimeo,
  [ComponentType.admob]: CusAdmob,
  [ComponentType.imageUpload]: CusImageUpload,
  [ComponentType.fileUpload]: CusFileUpload,
  [ComponentType.group]: CusGroup,
  [ComponentType.webView]: WebView,
  [ComponentType.table]: CusTable,
  [ComponentType.question]: CusQuestion,
  [ComponentType.liveStreamCamera]: CusLiveStreamCamera,
  [ComponentType.imageUploadList]: CusImageUploadList,
  // [ComponentType.customerChat]: CusCustomerChat,
  [ComponentType.web3List]: CusList,
  [ComponentType.invisibleShape]: CusInvisibleShape,
  [ComponentType.stamp]: Stamp,
  [ComponentType.stampHistory]: StampHistory,
};

export const notScaleComponent = [
  ComponentType.image,
  ComponentType.group,
  ComponentType.rectangle,
  ComponentType.imageUpload,
];
