import { RootState } from '../store';

export const formInputs = (state: RootState) => state.formInputs;

export const getValueFields = (state: RootState) => state.formInputs.values;

export const formValueSelector = (state: RootState) =>
  state.formInputs.formValue;

export const getDefaultValueFields = (state: RootState) =>
  state.formInputs.defaultValues;
