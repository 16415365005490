import { componentName } from '@common/constants/shared';
import { find, isNil, map, round } from 'lodash';
import { Platform } from 'react-native';

interface ObjectParams {
  obj: any;
  prevObj: any;
  index: any;
  dimension: any;
  offsetLeft: any;
  isScreen: any;
  metadata: any;
  insets: any;
  statusBarHeight: any;
  layout: any;
  offsetTop: any;
  modalMetadata: any;
  currentPageInfo: any;
  isShowStatusBar?: boolean;
  isFromViewLive?: boolean;
}

// const defaultMargin = 15 as const;

export const getComponentChild = (metadata: any[], objId: string) => {
  let component;

  map(metadata, (item) => {
    if (item.children) {
      component = find(item.children, { id: objId });
    }
  });

  return component;
};

export const updateObjectOrigin = (params: ObjectParams) => {
  const {
    obj,
    prevObj,
    index,
    offsetLeft,
    isScreen,
    metadata,
    insets,
    statusBarHeight,
    modalMetadata,
    offsetTop,
    isShowStatusBar,
    isFromViewLive,
  } = params;

  const oldObj =
    find(metadata, { id: obj.id }) ||
    getComponentChild(metadata, obj.id) ||
    find(modalMetadata, { id: obj.id });
  const objectWidth = oldObj ? oldObj.width : obj.width - insets.left;
  const objectHeight = oldObj ? oldObj.height : obj.height;

  const objX = oldObj ? oldObj.x : obj.x;

  if (index == 0) {
    const marginTopPocth =
      insets.bottom + Platform.OS !== 'ios' && obj.y < statusBarHeight
        ? statusBarHeight - obj?.y || 0
        : 0;

    obj.marginTop = !isScreen
      ? obj.y - offsetTop
      : obj.y + (isShowStatusBar ? -statusBarHeight + marginTopPocth : 0);
  } else {
    let marginTop = obj.y - prevObj.y - prevObj.height;
    obj.marginTop = marginTop;
  }

  obj.marginLeft = objX - offsetLeft;

  // obj.height = objectHeight * originX;

  if (
    obj?.type == 'section' ||
    obj?.type == 'group' ||
    obj?.type == 'image' ||
    obj.type === 'image-upload'
  ) {
    obj.width = objectWidth;
    obj.height = objectHeight;

    // fix rounded image
    if (
      obj?.type == 'image' &&
      round(obj.borderRadius / objectWidth, 2) >= 0.48
    ) {
      obj.borderRadius = obj.borderRadius * objectWidth;
    }
  } else {
    obj.width = objectWidth;
  }

  if (isFromViewLive) {
    obj.width = objectWidth;
  }

  if (
    oldObj &&
    [componentName.Toggle, componentName.Icon].includes(oldObj?.componentName)
  ) {
    const attributes = oldObj.attributes;

    const iconSize = attributes.size;

    obj.attributes.size = iconSize;
  }

  return obj;
};

export const webComponent = (params: ObjectParams) => {
  const {
    obj,
    prevObj,
    index,
    offsetLeft,
    isScreen,
    metadata,
    insets,
    statusBarHeight,
    modalMetadata,
    offsetTop,
    layout,
    dimension,
    currentPageInfo,
    isFromViewLive,
  } = params;

  const oldObj =
    find(metadata, { id: obj.id }) ||
    getComponentChild(metadata, obj.id) ||
    find(modalMetadata, { id: obj.id });

  const objectWidth = oldObj ? oldObj.width : obj.width - insets.left;
  const objectHeight = oldObj ? oldObj.height : obj.height;

  if (index == 0) {
    const marginTopPocth =
      insets.bottom + Platform.OS !== 'ios' && obj.y < statusBarHeight
        ? statusBarHeight - obj?.y || 0
        : 0;

    obj.marginTop = !isScreen
      ? obj.y - offsetTop
      : obj.y - statusBarHeight + marginTopPocth + insets.top;
  } else {
    obj.marginTop = obj.y - prevObj.y - prevObj.height;
  }

  if (objectWidth / currentPageInfo.width >= 0.9) {
    obj.width = objectWidth;
    // obj.height = objectHeight ;
  } else if (objectWidth / dimension.width >= 0.9) {
    obj.width = dimension.width * 0.9;
    // obj.height = (objectWidth / dimension.width) * 0.9 * obj.height;
  } else {
    obj.width = objectWidth;
  }

  if (isFromViewLive) {
    obj.width = objectWidth;
  }

  let webOffset = 0;

  const scaleWidth = objectWidth;
  webOffset = (scaleWidth - obj.width) / 2;
  obj.webOffset = webOffset;

  if (isNil(layout?.offsetLeft)) {
    obj.marginLeft = obj.x === 0 ? 0 : obj.x - offsetLeft + webOffset;
  } else {
    obj.marginLeft = obj.x - offsetLeft;

    const objTopRight = obj.x + objectWidth;
    const parentTopRight = layout.offsetLeft + layout.parentWidth;
    if (objTopRight >= parentTopRight) {
      obj.marginLeft = obj.marginLeft - (objTopRight - parentTopRight);

      if (obj.marginLeft < 0) {
        obj.marginLeft = 0;
      }
    }
  }

  if (
    oldObj &&
    [componentName.Toggle, componentName.Icon].includes(oldObj?.componentName)
  ) {
    const attributes = oldObj.attributes;

    const iconSize = attributes.size;

    obj.attributes.size = iconSize;
  }

  return { ...obj, isWeb: true };
};

export const mapPropColor = (props: Record<string, any>, appInfo: any): any => {
  for (const key in props) {
    props[key] = getColorMapping(props[key], appInfo);
  }
  return props;
};

export const getColorMapping = (color: string, appInfo: any) => {
  if (color === '@primary') {
    return appInfo?.primaryColor || '#4259AC';
  }
  if (color === '@secondary') {
    return appInfo?.secondaryColor || '#88c6ff';
  }
  return color;
};
