import { get } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import {
  Image,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { checkFont, parseText } from '../func';
import { getValueBinding } from '../shared';
import { CARD, STATUS_UNIVAPAY_SUBSCRIPTION } from './constant';
import { Props } from './hook';
import createStyles from './style';

const UnivaPayForm: React.FC<Props> = (props) => {
  const {
    loading,
    isDisableButton,
    card,
    uriImage,
    attributes,
    checkTypeCard,
    handleChange,
    handleSubmit,
    cardNameRef,
    cardNumberRef,
    cursor,
    handleChangeCursor,
    submitButtonText,
  } = props;

  const bindingValue = getValueBinding(props.id, props.data, props);

  const { width, titleCardName, titleCardNumber } = attributes;
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const { enabled: titleCardNameEnabled = true } = titleCardName || {};
  const { enabled: titleCardNumberEnabled = true } = titleCardNumber || {};

  const {
    cardName,
    cardNumber,
    cardExpiration,
    cardCvc,
    text: { status, message },
  } = card;

  const styles = createStyles({
    attrs: attributes,
    width,
    disabled: isDisableButton,
    isError: status !== STATUS_UNIVAPAY_SUBSCRIPTION.COMPLETED,
  });

  const {
    lengths,
    code: { name: nameCvc, size: sizeCvc },
  } = checkTypeCard || {};

  const { cursorCardName } = cursor || {};

  const maxLengthCardNumber = Math.max(...lengths);

  return (
    <View style={styles.container}>
      {titleCardNameEnabled && (
        <Text style={styles.titleCardName}>
          {parseText(
            get(
              bindingValue,
              'titleCardName.text',
              'Card information'
            ) as string
          )}
        </Text>
      )}
      <TouchableOpacity activeOpacity={1}>
        <View style={styles.inputCardName}>
          <TextInput
            placeholder="Card holder name"
            style={styles.input}
            value={cardName}
            onChangeText={(text) => handleChange(text, CARD.CARD_NAME)}
            maxLength={30}
            autoComplete="name"
            {...(Platform.OS === 'web' && {
              onChange: (e: any) => {
                handleChangeCursor(e.target.selectionStart, CARD.CARD_NAME);
              },
              onFocus: (e: any) => {
                e.target.selectionStart = cursorCardName;
              },
              ref: cardNameRef,
            })}
          />
        </View>
      </TouchableOpacity>
      {titleCardNumberEnabled && (
        <Text style={styles.titleCardNumber}>
          {parseText(
            get(
              bindingValue,
              'titleCardNumber.text',
              'Card information'
            ) as string
          )}
        </Text>
      )}
      <View style={styles.inputCardContainer}>
        <View style={styles.inputCardImage}>
          <Image
            style={styles.cardImage}
            source={{ uri: uriImage }}
            resizeMode="cover"
          />
        </View>
        <TouchableOpacity activeOpacity={1} style={styles.inputCardNumber}>
          <View style={styles.inputCardNumber}>
            <TextInput
              placeholder="Card number"
              style={styles.input}
              value={cardNumber}
              {...(Platform.OS === 'web'
                ? { ref: cardNumberRef }
                : {
                    onChangeText: (text) =>
                      handleChange(text, CARD.CARD_NUMBER),
                  })}
              maxLength={maxLengthCardNumber}
              keyboardType="number-pad"
              autoComplete="cc-number"
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1}>
          <View style={styles.inputCardExpiration}>
            <TextInput
              placeholder="MM / YY"
              style={styles.input}
              value={cardExpiration}
              onChangeText={(text) => handleChange(text, CARD.CARD_EXPIRATION)}
              maxLength={5}
              keyboardType="number-pad"
              autoComplete="cc-exp"
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1}>
          <View style={styles.inputCardCVC}>
            <TextInput
              placeholder={nameCvc}
              style={styles.input}
              value={cardCvc}
              onChangeText={(text) => handleChange(text, CARD.CARD_CVC)}
              maxLength={sizeCvc}
              keyboardType="number-pad"
              autoComplete="cc-csc"
            />
          </View>
        </TouchableOpacity>
      </View>
      {!!status && <Text style={styles.text}>{message}</Text>}
      <View style={styles.btnCard}>
        <RNButton
          style={styles.btn}
          loading={loading}
          disabled={isDisableButton}
          onPress={handleSubmit}
          children={parseText(submitButtonText).toUpperCase()}
          labelStyle={{
            opacity: isDisableButton ? 0.6 : 1,
            fontFamily: checkFont(
              attributes.submitButton.fontFamily,
              undefined,
              target
            ),
            fontSize: attributes.submitButton.fontSize,
            ...(attributes.submitButton.fontSize && {
              lineHeight: attributes.submitButton.fontSize * 1.15,
              paddingVertical: 1.5,
            }),
            color: attributes.submitButton.color,
          }}
        />
      </View>
    </View>
  );
};

export default UnivaPayForm;
