export const LIVE_STREAM_ACTION_TYPES = {
  CreateIngestUrl: 'CreateIngestUrl',
  StopLiveStream: 'StopLiveStream',
  GetStreamUrl: 'GetStreamUrl',
  OutStream: 'OutStream',
  DescribeLiveStreamsOnlineList: 'DescribeLiveStreamsOnlineList',
  ForbidLiveStream: 'ForbidLiveStream',
  CreateLiveStreamRecordIndexFiles: 'CreateLiveStreamRecordIndexFiles',
  IncrementView: 'IncrementView',
};

export const DISPLAY_SCREEN = {
  VIEW: 'view',
  START: 'start',
};

export const STATUS_STREAM = {
  NONE: 'none',
  SUCCESS: 'success',
  FAILED: 'failed',
  DISCONNECTED: 'disconnected',
  STOP: 'stop',
  PENDING: 'pending',
  ENDED: 'ended',
};

export const STATUS_PERMISSIONS_RN = {
  GRANTED: 'granted',
  DENIED: 'denied',
  UNAVAILABLE: 'unavailable',
  BLOCKED: 'blocked',
};

export type CameraType = 'front' | 'back';

export const PERMISSIONS_LOCALE: any = {
  camera: {
    ja: 'カメラ',
    us: 'Camera',
  },
  microphone: {
    ja: 'マイク',
    us: 'Microphone',
  },
};
