import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import { SigninTorusParams } from '@common/types';
import { formatScreens } from '@common/utils';
import io from './feathers.io';
import {
  FindCollectionsRequestParams,
  FindCollectionsResult,
  GetSharedAppResult,
} from 'click-types';

// App services
export const fetchApp = async (appId: string) => {
  const res: GetSharedAppResult = await io
    .service(API_ENDPOINTS.APP)
    .get(appId);
  return { ...res, metadata: JSON.parse(res.metadata) };
};

export const fetchCollections = async (
  appId: string
): Promise<FindCollectionsResult | undefined> => {
  const findCollectionsRequestParams: FindCollectionsRequestParams = {
    appId,
  };

  const res: FindCollectionsResult = await io
    .service(API_ENDPOINTS.COLLECTIONS)
    .find({
      query: findCollectionsRequestParams,
    });

  return res;
};

// Page services
export const fetchPages = async (
  appId: string,
  params: Record<string, any>
) => {
  const res = await io.service(`v2/share/${appId}/screens`).find({
    query: {
      ...params,
      appId,
    },
  });

  return {
    data: formatScreens({ screens: res?.data }).list().toView(),
    expiresOn: res?.expiresOn,
  };
};

//TORUS
export const signinTorus = async (data: SigninTorusParams, appId: string) => {
  const { userPreview, accessToken } = await io.authenticate(
    { ...data, strategy: 'torus' },
    {
      query: {
        appId: appId,
      },
    }
  );

  return Object.assign(userPreview, { accessToken });
};
