import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  pastLocations: any[];
};

const initialState: State = {
  pastLocations: [],
};

const appSlice = createSlice({
  name: 'action',
  initialState: initialState,
  reducers: {
    setPastLocations: (state: State, action: PayloadAction<Location[]>) => {
      state.pastLocations = action.payload;
    },
  },
});

export const { setPastLocations } = appSlice.actions;
export default appSlice.reducer;
