export default {
  name: 'LivestreamChat',
  displayName: {
    en: 'LivestreamChat',
    ja: 'LivestreamChat',
  },
  defaultWidth: 255,
  defaultHeight: 315,
  minWidth: 200,
  minHeight: 200,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'onPress',
      displayName: {
        en: 'List',
        ja: 'リスト',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
    {
      name: 'channel',
      displayName: {
        en: 'Streamer ID',
        ja: 'Streamer ID',
      },
      type: 'text',
      default: '',
    },
    {
      name: 'backgroundColor',
      displayName: {
        en: 'Background Color',
        ja: 'Background Color',
      },
      type: 'color',
      default: '#9B9B9B',
    },
    {
      name: 'borderColor',
      displayName: {
        en: 'Border Color',
        ja: 'Border Color',
      },
      type: 'color',
      default: 'transparent',
      enabled: {
        border: 'true',
      },
    },
    {
      name: 'borderRadius',
      type: 'number',
      displayName: {
        en: 'Border Radius',
        ja: 'Border Radius',
      },
      enabled: {
        showProp: true,
      },
      default: 1,
    },
    {
      name: 'borderWidth',
      displayName: {
        en: 'Border Width',
        ja: 'Border Width',
      },
      type: 'number',
      default: 1,
      enabled: {
        bottomBorder: true,
      },
    },
    {
      name: 'showChat',
      displayName: {
        en: 'Show Chat',
        ja: 'テキストの入力欄表示',
      },
      type: 'boolean',
      default: 'true',
    },
    {
      name: 'showListComment',
      displayName: {
        en: 'Show List Comment',
        ja: 'コメント一覧表示',
      },
      type: 'boolean',
      default: 'true',
    },
    {
      name: 'heightFullSizeIOS',
      displayName: {
        en: 'Height Chat Full Size IOS',
        ja: 'Height Chat Full Size IOS',
      },
      disableDynamicValues: true,
      type: 'text',
    },
    {
      name: 'heightCommentIOS',
      displayName: {
        en: 'Height Comment IOS',
        ja: 'Height Comment IOS',
      },
      disableDynamicValues: true,
      type: 'text',
    },
    {
      name: 'heightFullSizeAndroid',
      displayName: {
        en: 'Height Chat Full Size Android',
        ja: 'Height Chat Full Size Android',
      },
      disableDynamicValues: true,
      type: 'text',
    },
    {
      name: 'heightCommentAndroid',
      displayName: {
        en: 'Height Comment Android',
        ja: 'Height Comment Android',
      },
      disableDynamicValues: true,
      type: 'text',
    },
    {
      name: 'maximumChat',
      displayName: {
        en: 'Maximum Chats',
        ja: 'Maximum Chats',
      },
      disableDynamicValues: true,
      type: 'text',
    },
    {
      name: 'heightFullSize',
      displayName: {
        en: 'Height Chat Full Size',
        ja: 'Height Chat Full Size',
      },
      disableDynamicValues: true,
      type: 'text',
    },
    {
      name: 'textExtend',
      displayName: {
        en: 'Extend text',
        ja: 'Extend text',
      },
      type: 'text',
      disableDynamicValues: true,
    },
    {
      name: 'textNarrow',
      displayName: {
        en: 'Narrow text',
        ja: 'Narrow text',
      },
      type: 'text',
      disableDynamicValues: true,
    },
  ],
  childComponents: [
    {
      name: 'joinColors',
      displayName: { en: 'Join colors', ja: 'Join colors' },
      props: [
        {
          name: 'textStyle',
          displayName: { en: 'Text style', ja: 'Text style' },
          type: 'textStyle',
        },
        {
          name: 'backgroundColor',
          displayName: { en: 'Background color', ja: 'Background color' },
          type: 'color',
          default: '#beacef8a',
        },
      ],
    },
    {
      name: 'commentColors',
      displayName: { en: 'Comment colors', ja: 'Comment colors' },
      props: [
        {
          name: 'textStyle',
          displayName: { en: 'Text style', ja: 'Text style' },
          type: 'textStyle',
        },
        {
          name: 'backgroundColor',
          displayName: { en: 'Background color', ja: 'Background color' },
          type: 'color',
          default: '#9B9B9B',
        },
      ],
    },
    {
      name: 'giftColors',
      displayName: { en: 'Gift colors', ja: 'Gift colors' },
      props: [
        {
          name: 'textStyle',
          displayName: { en: 'Text style', ja: 'Text style' },
          type: 'textStyle',
        },
        {
          name: 'backgroundColor',
          displayName: { en: 'Background color', ja: 'Background color' },
          type: 'color',
          default: '##e594dc8f',
        },
      ],
    },
    {
      name: 'purchaseColors',
      displayName: { en: 'Purchase colors', ja: 'Purchase colors' },
      props: [
        {
          name: 'textStyle',
          displayName: { en: 'Text style', ja: 'Text style' },
          type: 'textStyle',
        },
        {
          name: 'backgroundColor',
          displayName: { en: 'Background color', ja: 'Background color' },
          type: 'color',
          default: '#e9c76063',
        },
      ],
    },
    {
      name: 'followColors',
      displayName: { en: 'Follow colors', ja: 'Follow colors' },
      props: [
        {
          name: 'textStyle',
          displayName: { en: 'Text style', ja: 'Text style' },
          type: 'textStyle',
        },
        {
          name: 'backgroundColor',
          displayName: { en: 'Background color', ja: 'Background color' },
          type: 'color',
          default: '#95ec7d6e',
        },
      ],
    },
  ],
};
