import { profileSelector } from '@common/redux/selectors/auth';
import { pagesSelector } from '@common/redux/selectors/page';
import { checkAuthError, checkAuthSuccess } from '@common/redux/slice/auth';
import { useHistory, useLocation } from '@common/routes/hooks';
import { IAppInfo } from '@common/types';
import { getMetadataScreen } from '@common/utils';
import { some } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EmitterSubscription, Linking, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

type Props = {
  appInfo: IAppInfo;
};

let eventDeepLinkUrl: EmitterSubscription;
export const authenticationRouting = ({ appInfo }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const pages = useSelector(pagesSelector);
  const profile = useSelector(profileSelector);

  const { search } = useLocation();
  const query = useMemo(() => qs.parse(search), [search]);

  const [pageState, setPageState] = useState<{
    currentPageId: string | any;
    currentModalId: string | any;
    animation: string | any;
    modalTransition: string | any;
  }>({
    currentPageId: '',
    currentModalId: '',
    animation: '',
    modalTransition: '',
  });

  const handleUrlDeepLink = (event: { url: string | null }) => {
    if (event?.url && Platform.OS !== 'web') {
      const parsed = qs.parse(event?.url.split('?')[1]);
      const screenId = parsed?.screen;
      const streamId = parsed?.streamId;

      if (streamId && screenId) {
        history.replace({
          search: qs.stringify({
            ...query,
            ...{
              target: checkValidScreen(screenId),
            },
          }),
          state: { ...parsed },
        });
      }
    }
  };

  const findScreen = (screenId: string) => {
    if (!screenId) return;
    const currentPage = pages[screenId];

    if (currentPage) {
      const cloneCurrent = JSON.parse(JSON.stringify(currentPage));
      cloneCurrent.metadata = getMetadataScreen(cloneCurrent);
      return cloneCurrent;
    }
    return undefined;
  };

  const [loading, setLoading] = useState(false);

  const authenticationCheck = () => {
    setLoading(true);
    const { active, startHomePage } = appInfo.metadata;

    let id: string | string[];
    if (profile?.id) {
      dispatch(checkAuthSuccess(profile));
      const { target } = query;
      id = target || active;

      if (target === checkValidScreen(id)) {
        setLoading(false);
        return;
      }
    } else {
      dispatch(checkAuthError());
      id = startHomePage;
    }
    setLoading(false);

    history.replace({
      search: qs.stringify({
        ...query,
        ...{
          target: checkValidScreen(id),
        },
      }),
    });
  };

  const checkValidScreen = useCallback(
    (screenUuid: string | any) => {
      const isValid = !isEmpty(pages[screenUuid]);

      if (isValid) return screenUuid;
      else {
        return pages[Object.keys(pages)[0]].screenUuid;
      }
    },
    [pages]
  );

  useEffect(() => {
    authenticationCheck();
  }, []);

  useEffect(() => {
    if (profile?.id) {
      Linking.getInitialURL().then((url) => {
        return handleUrlDeepLink({
          url,
        });
      });

      eventDeepLinkUrl = Linking.addEventListener('url', (e) => {
        return handleUrlDeepLink(e);
      });
    }
    return () => {
      if (eventDeepLinkUrl) {
        eventDeepLinkUrl.remove();
      }
    };
  }, [profile]);

  useEffect(() => {
    const {
      target,
      transition,
      targetModal,
      modalTransition: queryModalTransition,
    } = query;

    setPageState((preState) => ({
      ...preState,
      currentPageId:
        preState.currentPageId !== target ? target : preState.currentPageId,
      currentModalId:
        preState.currentModalId !== targetModal
          ? targetModal
          : preState.currentModalId,
      animation:
        preState.animation !== transition ? transition : preState.animation,
      modalTransition: preState.modalTransition
        ? queryModalTransition
        : preState.animation,
    }));

    return;
  }, [query]);

  const currentPage = useMemo(() => {
    return findScreen(pageState.currentPageId);
  }, [pageState]);

  const currentModalPage = useMemo(() => {
    return findScreen(pageState.currentModalId);
  }, [pageState]);

  return {
    ...pageState,
    screen: currentPage,
    modalScreen: currentModalPage,
    appInfo,
    loading,
  };
};
