import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { IGroup } from '@common/types/element';
import { isEmpty, isNil, map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import createStyles from './style';
import GroupScrollableWrapper from '@common/components/Group/components/Scrollable';
import { getActions } from '@common/utils/handleActions/func/helps';

const CusGroup: FC<IGroup> = (attrs) => {
  const styles = createStyles(attrs);
  const { children, isInCustomList, scroll, onPress } = attrs;

  if (children.length == 0) return <React.Fragment />;

  const arrCom = useMemo(
    () =>
      map(children, (child) => {
        const groupActionId = attrs?.actions && Object.keys(attrs.actions)[0];

        const allowListAction =
          isEmpty(child?.actions) && !isEmpty(groupActionId);

        const selectedItem = {
          itemId: attrs.selectedItem?._id,
          tableId: attrs.selectedItem?.tableId,
          itemIndex: attrs.selectedItem?.itemIndex,
        };

        return {
          ...child,
          ...(isNil(child.actions) || isEmpty(child.actions)
            ? { actions: attrs.actions }
            : {
                actions: {
                  ...child?.actions,
                  ...attrs?.actions,
                },
              }),
          currentScroll: {
            active: scroll?.active,
            arrange: scroll?.arrange,
          },
          selectedItem,
          record: attrs.record,
          groupActionId,
          currentListIds: { ...(attrs.currentListIds || {}) },
          currentRecordIds: { ...(attrs.currentRecordIds || {}) },
          //attrs.id fix for action group not working
          currentListId: attrs?.currentListId || attrs.id,
          ...(isInCustomList && {
            initId: `${child.id}`,
          }),
          allowListAction,
          screenUuid: attrs?.screenUuid,
        };
      }),
    [children, attrs.record, attrs?.actions, attrs.selectedItem]
  );

  const layout = {
    offsetTop: attrs?.y,
    offsetLeft: attrs?.x,
    parentWidth: attrs?.width,
  };

  const emptyAction = isEmpty(attrs.actions);

  return (
    <>
      <GroupScrollableWrapper attrs={attrs} scroll={attrs.scroll}>
        {emptyAction ? (
          <View style={styles.container}>
            <ObjectRender
              keyItem={attrs?.selectedItem?.itemIndex}
              arrComp={arrCom}
              isScreen={false}
              layout={layout}
            />
          </View>
        ) : (
          <View style={styles.container}>
            <TouchableOpacity
              onPress={() => {
                onPress(getActions(attrs.actions), {});
              }}
              style={{
                minHeight: attrs.height,
                width: attrs.width,
              }}
              activeOpacity={1}
            >
              <ObjectRender
                keyItem={attrs?.selectedItem?.itemIndex}
                arrComp={arrCom}
                isScreen={false}
                layout={layout}
              />
            </TouchableOpacity>
          </View>
        )}
      </GroupScrollableWrapper>
    </>
  );
};

export default CusGroup;
