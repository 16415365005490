import React, { FC } from 'react';
import useFincode, { Props, ReceivedProps } from './hook';
import FincodeForm from './FincodeForm';

const FincodeLayout: FC<Props> = (props) => {
  return <FincodeForm {...props} />;
};

const Fincode: FC<ReceivedProps> = (props) => (
  <FincodeLayout {...useFincode(props)} />
);

export default Fincode;
