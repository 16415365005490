import { IButton } from '@nocode/types';
import { get } from 'lodash';
import queryString from 'query-string';
import React, { ComponentProps } from 'react';
import { Platform, Text } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import PointIcon from '../CustomIcon';
import { checkFont } from '../func';
import { getActions, getItemListClick, getValueBinding } from '../shared';

type Props = ComponentProps<typeof RNButton> | any;

const Button: React.FC<IButton> = (props) => {
  const {
    attributes,
    onPress,
    height,
    zIndex,
    record = null,
    data,
    isInCustomList,
    loading,
  } = props;
  const {
    type,
    icon,
    opacity,
    backgroundColor,
    fontSize,
    color,
    borderRadius,
    upperCase,
  } = attributes;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const bindingValue = getValueBinding(
    isInCustomList ? props.initId : props.id,
    isInCustomList ? props?.record : data,
    props
  );

  const textOptions = get(bindingValue, 'text', '').toString();

  const childProps: Props = {
    mode: type,
    children: textOptions
      ? upperCase
        ? textOptions.toUpperCase()
        : textOptions.charAt(0).toUpperCase() + textOptions.slice(1)
      : '',
    icon: icon,
    style: {
      opacity,
      ...(type === 'contained' && {
        backgroundColor,
      }),
      borderRadius,
      minHeight: height,
      justifyContent: 'center',
      borderColor: type === 'contained' ? backgroundColor : color,
      zIndex: zIndex || 1,
    },
    uppercase: upperCase ? upperCase : false,
    labelStyle: {
      // error font family______________________
      fontFamily: checkFont(attributes.fontFamily, undefined, target),
      fontSize,
      ...(attributes.fontSize && {
        lineHeight: attributes.fontSize * 1.15,
        paddingVertical: 1.5,
      }),
      color,
    },
    // color: type === 'contained' ? backgroundColor : color ? color : '#4259AC',
  };

  const handlePress = () => {
    if (loading) {
      return;
    }

    onPress &&
      onPress(getActions(props, 'action'), {
        itemListClick: getItemListClick(record),
      });
  };

  if (icon === 'point') {
    let copyChildProps = { ...childProps };
    delete copyChildProps.icon;
    delete copyChildProps.children;

    return (
      <RNButton
        {...copyChildProps}
        onPress={handlePress}
        icon={() => <PointIcon width={24} height={24} fill={color} />}
      >
        <Text
          {...(Platform.OS !== 'web' && {
            style: {
              fontFamily: checkFont(attributes.fontFamily),
              fontSize,
              color,
            },
          })}
        >
          {textOptions
            ? upperCase
              ? textOptions.toUpperCase()
              : textOptions.charAt(0).toUpperCase() + textOptions.slice(1)
            : ''}
        </Text>
      </RNButton>
    );
  }

  return <RNButton {...childProps} onPress={handlePress} />;
};

export default Button;
