export default {
  name: 'TabNavigator',
  displayName: {
    en: 'Bottom Bar',
    ja: 'ボトム',
  },
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 56,
  resizeX: true,
  snappingRules: {
    snap: true,
    snapType: 'screenEdge',
    lock: {
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  props: [
    {
      name: 'backgroundColor',
      displayName: {
        en: 'Fill Color',
        ja: 'Fill Color',
      },
      type: 'color',
      default: '#fff',
    },
    {
      name: 'activeColor',
      displayName: {
        en: 'Active Color',
        ja: 'アクティブ時の色',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'inactiveColor',
      displayName: {
        en: 'Inactive Color',
        ja: '非アクティブ時の色',
      },
      type: 'color',
      default: '#bbb',
    },
    {
      name: 'activeTab',
      displayName: {
        en: 'Active Tab',
        ja: 'アクティブタブ',
      },
      default: 0,
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.bottom.firstTab',
            value: 0,
          },
          {
            label: 'canvas.menuRight.elementTab.bottom.secondTab',
            value: 1,
          },
          {
            label: 'canvas.menuRight.elementTab.bottom.thirdTab',
            value: 2,
          },
          {
            label: 'canvas.menuRight.elementTab.bottom.fourthTab',
            value: 3,
          },
          {
            label: 'canvas.menuRight.elementTab.bottom.fifthTab',
            value: 4,
          },
        ],
      },
    },
    {
      name: 'positioning',
      editable: false,
      type: 'text',
      default: 'fixedTop',
    },
  ],
  childComponents: [
    {
      name: 'tab0',
      displayName: {
        en: 'First Tab',
        ja: '１番目のタブ',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'home',
        },
        {
          name: 'label',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Home',
          styles: {
            fontFamily: '@body',
            fontWeight: '400',
            fontSize: 11,
          },
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
    {
      name: 'tab1',
      displayName: {
        en: 'Second Tab',
        ja: '２番目のタブ',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'search',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'label',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Search',
          styles: {
            fontFamily: '@body',
            fontWeight: '400',
            fontSize: 11,
          },
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
    {
      name: 'tab2',
      displayName: {
        en: 'Third Tab',
        ja: '３番目のタブ',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'people',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'label',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Team',
          styles: {
            fontFamily: '@body',
            fontWeight: '400',
            fontSize: 11,
          },
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
    {
      name: 'tab3',
      displayName: {
        en: 'Fourth Tab',
        ja: '４番目のタブ',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'account-circle',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'label',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Profile',
          styles: {
            fontFamily: '@body',
            fontWeight: '400',
            fontSize: 11,
          },
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
    {
      name: 'tab4',
      displayName: {
        en: 'Fifth Tab',
        ja: '５番目のタブ',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'more-horiz',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'label',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'More',
          styles: {
            fontFamily: '@body',
            fontWeight: '400',
            fontSize: 11,
          },
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
  ],
};
