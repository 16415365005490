import { API_BASE_URL, API_ENDPOINTS } from '@common/constants/apiEndpoint';
import BaseComponent from '@nocode/types/base.type';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { getValueBinding } from '../shared';
import EventSource from 'react-native-sse';

const INIT_MESSAGE_ID = 'init';
enum GptConversationServiceTypeEnum {
  dify = 'dify',
  openAi = 'openAI',
}
type GptConversationModel = {
  apiUrl: string;
  openAiModel?: string;
  serviceType: GptConversationServiceTypeEnum;
};
type TextStyle = {
  fontSize: number;
  color: string;
  fontFamily: string;
  backgroundColor: string;
  lineHeight: number;
};
export interface IGptConversation extends BaseComponent {
  attributes: {
    backgroundColor: string;
    fontFamily: string;
    fontSize: number;
    fontWeight: string;
    lineHeight: number;
    opacity: number;
    textAlignment: string;
    borderRadius: number;
    sendButton: {
      icon: string;
      iconEnableOutline: boolean;
    } & TextStyle;
    advanceSetting: {
      aiName: string | any;
      username: string | any;
      aiColor: string;
      userColor: string;
    };
  };
  data: Record<string, any>;
  appId: string;
}
export type GptConversationBindingValue = {
  placeholder: string;
  initPrompt: string;
  'advanceSetting.aiName': string;
  'advanceSetting.username': string;
  'sendButton.text': string;
  initMessage: string;
};
export type IMessage = {
  id: string;
  senderType: 'user' | 'ai' | 'error';
  messageType: 'text' | 'url';
  message: string;
};
type MessageQuery = { role: 'user' | 'assistant' | 'system'; content: string };
type CreateChatMessageData = {
  query?: string;
  messages?: MessageQuery[];
};
type CreateChatMessageCallBacks = {
  onResource?: (url: string) => void;
  onAnswer?: (formattedAnswer: string) => void;
  onDone?: (data: { answer: string; error: string }) => void;
};
type CreateChatMessageRequest = CreateChatMessageData & {
  appId: string;
  username: string;
  conversationId?: string | null;
};
type ResponseMessage = {
  conversationId: string | null;
  answer?: string;
  error?: string;
  resources?: { url: string }[];
  isEnd?: boolean;
};
export const useGptConversation = (props: IGptConversation) => {
  const { data, appId } = props;
  const bindingValue = getValueBinding(
    props.id,
    data,
    props
  ) as GptConversationBindingValue;

  const username = get(bindingValue, 'advanceSetting.username')?.toString();
  const initPrompt = get(bindingValue, 'initPrompt', '')?.toString();
  const initMessage = get(bindingValue, 'initMessage', '')?.toString();
  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;

  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [aiRespondingText, setAiRespondingText] = useState<string | null>(null);
  const [aiResponding, setAiResponding] = useState(false);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [initializing, setInitializing] = useState(true);
  const [gptConfig, setGptConfig] = useState<GptConversationModel>();
  const [messagesQuery, setMessagesQuery] = useState<MessageQuery[]>([]);

  const messageRef = useRef<any>();

  const scrollToBottom = () => {
    messageRef?.current?.scrollToEnd({
      animated: true,
    });
  };

  const addMessage = (
    message: Pick<IMessage, 'senderType' | 'message'> &
      Partial<Pick<IMessage, 'messageType'>>
  ) => {
    setMessages((old) => [
      ...old,
      {
        ...message,
        messageType: message.messageType ?? 'text',
        id: Date.now().toString(),
      },
    ]);
  };
  const addInitMessage = (message: string) => {
    setMessages([
      {
        message,
        id: INIT_MESSAGE_ID,
        messageType: 'text',
        senderType: 'ai',
      },
    ]);
  };

  const addMessageQuery = (data: MessageQuery) => {
    if (gptConfig?.serviceType !== GptConversationServiceTypeEnum.openAi) {
      return messagesQuery;
    }
    messagesQuery.push(data);
    setMessagesQuery(messagesQuery);
    return messagesQuery;
  };
  const handleChunkData = (
    chunk: string,
    onAnswer: ((formattedAnswer: string) => void) | undefined,
    onResource: ((url: string) => void) | undefined
  ) => {
    let aiRespondingTempt = '';
    let errorRespondingTempt = '';
    try {
      const jsonData = JSON.parse(chunk) as ResponseMessage;
      if (jsonData.answer) {
        const formattedAnswer = jsonData.answer.replace(/<br>/g, '\n');
        onAnswer?.(formattedAnswer);
        aiRespondingTempt += formattedAnswer;
      }
      if (jsonData.error) {
        const formattedError = jsonData.error.replace(/<br>/g, '\n');
        errorRespondingTempt += formattedError;
      }
      if (jsonData.conversationId) {
        setConversationId(jsonData.conversationId);
      }
      if (jsonData.resources && jsonData.resources.length > 0) {
        jsonData.resources.forEach((resource: { url: string }) => {
          onResource?.(resource.url || '');
        });
      }
      return { aiRespondingTempt, errorRespondingTempt, jsonData };
    } catch (e) {
      console.error('JSON の解析エラー:', e);
    }
    return null;
  };

  const handleAiResponseMobile = async (
    body: CreateChatMessageRequest,
    callbacks?: CreateChatMessageCallBacks
  ) => {
    let aiRespondingTempt = '';
    let errorRespondingTempt = '';
    setAiResponding(true);
    const es = new EventSource(
      `${API_BASE_URL}/${API_ENDPOINTS.GPT_CONVERSATION_CHAT_MESSAGE}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // debug: true,
        body: JSON.stringify(body),
        timeoutBeforeConnection: 100,
        pollingInterval: 0, // Time (ms) between reconnections. If set to 0, reconnections will be disabled. Default: 5000
      }
    );
    const onDone = () => {
      callbacks?.onDone?.({
        answer: aiRespondingTempt,
        error: errorRespondingTempt,
      });
      setAiResponding(false);
      es.removeAllEventListeners();
      es.close();
    };
    es.addEventListener('open', (event) => {
      // console.log('===open event', event);
    });

    es.addEventListener('message', (event) => {
      const data = event.data;
      if (!data) {
        return;
      }
      const chunkResponse = handleChunkData(
        data,
        callbacks?.onAnswer,
        callbacks?.onResource
      );
      if (chunkResponse) {
        aiRespondingTempt += chunkResponse.aiRespondingTempt;
        errorRespondingTempt += chunkResponse.errorRespondingTempt;
        if (chunkResponse.jsonData.isEnd) {
          onDone();
        }
        return;
      }
      onDone();
    });

    es.addEventListener('error', (event) => {
      if (event.type === 'error' || event.type === 'exception') {
        errorRespondingTempt += event.message;
      }
      onDone();
    });

    es.addEventListener('close', (event) => {
      console.log('Close SSE connection.');
      onDone();
    });
  };

  const getAIResponse = async (
    data: CreateChatMessageData,
    callbacks?: CreateChatMessageCallBacks
  ) => {
    const { messages, query } = data;
    try {
      if (!messages?.length && !query) {
        return;
      }
      const body: CreateChatMessageRequest = {
        appId,
        query,
        username,
        conversationId,
        messages,
      };
      handleAiResponseMobile(body, callbacks);
      return;
    } catch (error) {
      setAiResponding(false);
      throw error;
    }
  };

  const onSubmit = async () => {
    const content = query;
    const messages = addMessageQuery({
      content,
      role: 'user',
    });
    // ユーザーの質問を表示
    addMessage({
      senderType: 'user',
      message: content,
    });
    // 入力フィールドをクリア
    setQuery('');
    try {
      await getAIResponse(
        {
          query: content,
          messages,
        },
        {
          onDone: ({ answer, error }) => {
            setAiRespondingText(null);
            if (error) {
              addMessage({
                senderType: 'error',
                message: error,
              });
              return;
            }
            addMessage({
              senderType: 'ai',
              message: answer,
            });
            addMessageQuery({
              content: answer,
              role: 'assistant',
            });
          },
          onResource: (url) => {
            addMessage({
              senderType: 'ai',
              message: url,
              messageType: 'url',
            });
          },
          onAnswer: (answer) => {
            setAiRespondingText((old) => `${old || ''}${answer}`);
            // スクロールを下に
            scrollToBottom();
          },
        }
      );
    } catch (error: any) {
      console.error('===error', error);
      addMessage({
        senderType: 'error',
        message: error.message || '',
      });
      // スクロールを下に
      scrollToBottom();
    }
  };

  const getGptConfig = async () => {
    const res = await fetch(
      `${API_BASE_URL}/${API_ENDPOINTS.GPT_CONVERSATION_CHAT_MESSAGE}/?appId=${appId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (!res.ok) {
      console.log('get config error');
      return;
    }
    const data = (await res.json()) as GptConversationModel;
    setGptConfig(data);
    return data;
  };

  const handleInit = async () => {
    if (
      initPrompt &&
      gptConfig?.serviceType === GptConversationServiceTypeEnum.openAi
    ) {
      addMessageQuery({
        content: initPrompt,
        role: 'system',
      });
    }
  };

  useEffect(() => {
    if (!isCanvas) {
      getGptConfig();
    }
  }, []);

  useEffect(() => {
    if (gptConfig && username) {
      handleInit().finally(() => {
        setInitializing(false);
      });
    }
  }, [gptConfig, username]);

  useEffect(() => {
    if (initMessage) {
      addInitMessage(initMessage);
    }
  }, [initMessage]);

  return {
    ...props,
    bindingValue,
    onSubmit,
    setQuery,
    query,
    messages,
    aiRespondingText,
    aiResponding,
    messageRef,
    initializing,
  };
};

export type UseGptConversation = ReturnType<typeof useGptConversation>;
