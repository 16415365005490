import { Platform } from 'react-native';
import queryString from 'query-string';
import { getListItems } from '../func';

export const getInitValueAvatarList = (dataBinding: any[], items: any[]) => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  let len = 5;

  if (Platform.OS !== 'web') {
    // App
    return dataBinding;
  } else {
    // web
    if (target) {
      // preview
      return dataBinding;
    } else {
      // client
      return getListItems(items, len);
    }
  }
};
