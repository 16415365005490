import React, { FC } from 'react';
import { ISelect } from '@common/types/element';

import { View } from 'react-native';
import CustomPicker from './Picker';
import createStyles from './style';
import connectorInput from '../ConnectorInput';

const Dropdown: FC<ISelect> = (attributes) => {
  const styles = createStyles(attributes);

  return (
    <View style={[styles.container, { width: attributes.width }]}>
      <CustomPicker {...attributes} />
    </View>
  );
};

export default connectorInput(Dropdown);
