import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ExternalDatabases = {
  externalCollections: {
    data: any[];
    databaseUuid: string;
    dataRoot: any[];
  };
};

type IndexExternal = {
  index: string;
};

type IdRecord = {
  id: string;
};
type Record = {
  record: any;
};

type State = {
  externalCollections: any[];
  indexExternal: string;
  recordId: any;
  externalRecord: any[];
};

const initialState: State = {
  externalCollections: [],
  indexExternal: '',
  recordId: '',
  externalRecord: [],
};

const appSlice = createSlice({
  name: 'externalCollection',
  initialState: initialState,
  reducers: {
    updateExternal: (
      state: State,
      action: PayloadAction<ExternalDatabases>
    ) => {
      if (action.payload) {
        const databaseId = action.payload.externalCollections.databaseUuid;
        const index = state.externalCollections.findIndex((data: any) => {
          return data.databaseUuid === databaseId;
        });

        if (index >= 0) {
          state.externalCollections[index] = action.payload.externalCollections;
        } else {
          state.externalCollections.push(action.payload.externalCollections);
        }
      }
    },
    setIndexExternal: (state: State, action: PayloadAction<IndexExternal>) => {
      if (action.payload) {
        state.indexExternal = action.payload.index;
      }
    },
    setCurrentRerordExternal: (
      state: State,
      action: PayloadAction<IdRecord>
    ) => {
      if (action.payload) {
        state.recordId = action.payload.id;
      }
    },
    setRerordExternal: (state: State, action: PayloadAction<Record>) => {
      if (action.payload) {
        state.externalRecord = action.payload.record;
      }
    },
  },
});

export const {
  updateExternal,
  setIndexExternal,
  setCurrentRerordExternal,
  setRerordExternal,
} = appSlice.actions;
export default appSlice.reducer;
