import { ISwitch } from '@nocode/types';
import { get, isEmpty } from 'lodash';
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { View } from 'react-native';
import { Switch as RNSwitch } from 'react-native-paper';
import {
  getConfig,
  getItemListClick,
  getPathLibrary,
  getValueBindingComponent,
  isCheckColor,
} from '../shared';

type Props = ComponentProps<typeof RNSwitch>;

const Switch: React.FC<ISwitch> = (attrs) => {
  const {
    attributes,
    onPress,
    changeInput,
    valueInputs,
    height,
    actions,
    data,
    record,
    initId = '',
    id,
    isInCustomList,
    ...props
  } = attrs;
  const [notBindingValue, setNotBindingValue] = useState<boolean>(false);
  const [binding, setBinding] = useState<boolean>(true);

  const isActive = useMemo(() => {
    const paths = getPathLibrary(getConfig(attrs));
    const value = getValueBindingComponent(attrs, paths[0]);
    const defaultValue = binding ? value : notBindingValue;

    return defaultValue;
  }, [attrs, notBindingValue, binding]);

  const thumbColorOn = attributes.thumbColorOn
    ? isCheckColor(attributes.thumbColorOn)
      ? attributes.thumbColorOn
      : '#0000FF'
    : '#0000FF';
  const thumbColorOff = attributes.thumbColorOff
    ? isCheckColor(attributes.thumbColorOff)
      ? attributes.thumbColorOff
      : '#FF0000'
    : '#FF0000';
  const trackColorOn = attributes.trackColorOn
    ? isCheckColor(attributes.trackColorOn)
      ? attributes.trackColorOn
      : '#008000'
    : '#008000';
  const trackColorOff = attributes.trackColorOff
    ? isCheckColor(attributes.trackColorOff)
      ? attributes.trackColorOff
      : '#FFFF00'
    : '#FFFF00';

  const thumbColor = isActive ? thumbColorOn : thumbColorOff;

  let childProps: Props = {
    value: isActive,
    color: thumbColor ? thumbColor : '',
    thumbColor: thumbColor ? thumbColor : '',
    trackColor: {
      false: trackColorOff,
      true: trackColorOn,
    },
    ios_backgroundColor: trackColorOff,
  };

  const handleSwitch = (actionProps: Record<string, any>) => {
    if (isEmpty(actionProps)) {
      setNotBindingValue(!notBindingValue);
      setBinding(false);
      return;
    }
    setBinding(true);
    onPress(actionProps.actionId, {
      itemListClick: getItemListClick(record),
    });
  };

  const handlePress = useCallback(async () => {
    changeInput && changeInput(!valueInputs);
    const { onActions, offActions } = attributes;

    if (!onPress) return;

    if (isActive === false && onActions) {
      await handleSwitch(onActions);
      return;
    }

    if (isActive === true && offActions) {
      await handleSwitch(offActions);
      return;
    }

    return handleSwitch({});
  }, [valueInputs, isActive]);

  return (
    <View style={{ height }}>
      <RNSwitch onValueChange={handlePress} {...childProps} />
    </View>
  );
};

export default Switch;
