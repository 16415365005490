import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDatabase } from 'click-types';
import { isEmpty } from 'lodash';

type StatePage = {
  collections: AppDatabase[];
  currentRecord: Record<string, any>;
  databaseRefresh: Record<string, any>;
  web3Config: Record<
    string,
    Record<'address' | 'token_address' | 'network' | 'apiToken', string>
  >;
};

const initialState: StatePage = {
  collections: [],
  currentRecord: {},
  web3Config: {},
  databaseRefresh: {},
};

const databaseSlice = createSlice({
  name: 'database',
  initialState: initialState,
  reducers: {
    setDatabaseRefresh: (
      state: StatePage,
      action: PayloadAction<Record<string, any>>
    ) => {
      const { databaseId } = action.payload;
      if (!databaseId) return;
      state.databaseRefresh[databaseId] = action.payload;
    },

    setCurrentRecordIds: (
      state: StatePage,
      action: PayloadAction<Record<string, any>>
    ) => {
      if (isEmpty(action.payload)) return;
      state.currentRecord = { ...state.currentRecord, ...action.payload };
    },
    setWeb3Config: (
      state: StatePage,
      action: PayloadAction<
        Record<
          string,
          Record<'address' | 'token_address' | 'network' | 'apiToken', string>
        >
      >
    ) => {
      if (isEmpty(action.payload)) return;
      state.web3Config = { ...state.web3Config, ...action.payload };
    },
    updateItemDataSource: (state: StatePage, action: PayloadAction<any>) => {
      // TODO: Implement liverhouse-v3
      // const record: IRecord = action.payload;
      // const databaseId = record?.databaseId;
      // const recordId = record?._id;
      // if (databaseId && recordId) {
      //   const index = findIndex(state.dataSource[databaseId], {
      //     _id: recordId,
      //   });
      //   if (index >= 0) {
      //     state.dataSource[databaseId][index] = record;
      //   }
      // }
    },
    setCollections: (state: StatePage, action: PayloadAction<any>) => {
      state.collections = action.payload;
    },
  },
});

export const {
  setDatabaseRefresh,
  setCurrentRecordIds,
  setWeb3Config,
  updateItemDataSource,
  setCollections,
} = databaseSlice.actions;
export default databaseSlice.reducer;
