import { IChipList } from '@nocode/types';
import { get } from 'lodash';
import React from 'react';
import { Image, View } from 'react-native';
import { Chip, Paragraph } from 'react-native-paper';
import { defaultURL } from '../../../utils/common.constant';
import {
  getActions,
  getImageCardCover,
  getItemListClick,
  getValueBinding,
  isCheckColor,
} from '../../shared';
import createStyles from '../style';

type Props = {
  attrs: IChipList;
  active: boolean;
  index: number;
  item: any;
  onPress?: any;
};

const ChipAvatar = ({ attrs, item }: Pick<Props, 'attrs' | 'item'>) => {
  const styles = createStyles(attrs);
  const { cardCover } = attrs.attributes;
  const placeholderImageUrl = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImage',
    null
  );
  const placeholderImageEnabled = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImageEnabled',
    false
  );
  const imageURL = getImageCardCover(item, cardCover);

  return (
    <View style={styles.avatar}>
      <Image
        style={{
          height: '100%',
          width: '100%',
          borderRadius: cardCover.borderRadius,
        }}
        defaultSource={{
          uri: placeholderImageEnabled ? placeholderImageUrl : defaultURL,
        }}
        source={{
          uri: imageURL,
        }}
      />
    </View>
  );
};

const ChipItem = ({ attrs, index, item, onPress, active }: Props) => {
  const styles = createStyles(attrs);

  const {
    closeIcon = { enabled: false },
    title = { enabled: false },
    cardCover = { enabled: false },
  } = attrs.attributes;

  const bindingValue = getValueBinding('', item, attrs);

  const backgroundColor = isCheckColor(attrs.attributes.backgroundColor)
    ? attrs.attributes.backgroundColor
    : '#ffffff';
  const selectedColor = isCheckColor(attrs.attributes.selectedColor)
    ? attrs.attributes.selectedColor
    : '#ffffff';
  const color = isCheckColor(attrs.attributes.color)
    ? attrs.attributes.color
    : '#000000';
  const selectedFontColor = isCheckColor(attrs.attributes.selectedFontColor)
    ? attrs.attributes.selectedFontColor
    : '#000000';
  return (
    <Chip
      {...(closeIcon.enabled && closeIcon.icon !== ''
        ? {
            closeIcon: closeIcon.icon,
            onClose: () => {
              onPress(getActions(attrs, 'closeIcon'), {
                record: item,
                indexRecord: index,
              });
            },
            selectedColor: isCheckColor(attrs.attributes.iconColor)
              ? attrs.attributes.iconColor
              : '#000000',
          }
        : {})}
      onPress={() =>
        onPress(getActions(attrs, 'onPress'), {
          record: item,
          indexRecord: index,
        })
      }
      style={[
        styles.chipContent,
        {
          backgroundColor: active ? selectedColor : backgroundColor,
        },
      ]}
      textStyle={styles.text}
      // avatar={
      //   cardCover.enabled ? <ChipAvatar attrs={attrs} item={item} /> : <></>
      // }
      avatar={<ChipAvatar attrs={attrs} item={item} />}
    >
      {title.enabled && (
        <Paragraph
          style={[styles.text, { color: active ? selectedFontColor : color }]}
        >
          {get(bindingValue, 'title.text')}
        </Paragraph>
      )}
    </Chip>
  );
};

export default ChipItem;
