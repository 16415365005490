export default {
  name: 'AppBar',
  displayName: {
    en: 'Top Bar',
    ja: 'トップ',
  },
  componentType: 'appBar',
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 56,
  snappingRules: {
    snap: true,
    snapType: 'screenEdge',
    lock: {
      left: 0,
      right: 0,
      top: 20,
    },
  },
  resizeX: true,
  props: [
    // {
    //   name: 'barType',
    //   displayName: 'App Bar Type',
    //   type: 'text',
    //   default: 'solid',
    //   control: {
    //     type: 'menu',
    //     options: [
    //       {
    //         label: 'Solid Color',
    //         value: 'solid',
    //       },
    //       {
    //         label: 'Translucent',
    //         value: 'translucent',
    //       },
    //       {
    //         label: 'Background Image',
    //         value: 'backgroundImage',
    //       },
    //     ],
    //   },
    // },
    // {
    //   name: 'backgroundImage',
    //   displayName: 'Image',
    //   type: 'image',
    //   enabled: {
    //     barType: 'backgroundImage',
    //   },
    // },
    // {
    //   name: 'backgroundColor',
    //   displayName: 'Fill Color',
    //   type: 'color',
    //   default: '@primary',
    //   enabled: {
    //     barType: 'solid',
    //   },
    // },
    // {
    //   name: 'translucentColor',
    //   displayName: 'Base Translucent Color',
    //   type: 'color',
    //   default: '@primary',
    //   enabled: {
    //     barType: 'translucent',
    //   },
    // },
    // {
    //   name: 'color',
    //   displayName: 'Icon Color',
    //   type: 'color',
    //   default: '@contrast:backgroundColor',
    // },
    // {
    //   name: 'shadow',
    //   displayName: 'Shadow',
    //   type: 'boolean',
    //   default: true,
    // },
    // {
    //   name: 'bottomBorder',
    //   displayName: 'Bottom Border',
    //   type: 'boolean',
    //   default: false,
    // },
    // {
    //   name: 'borderColor',
    //   displayName: 'Border Color',
    //   type: 'color',
    //   default: '@primaryDark',
    //   enabled: {
    //     bottomBorder: true,
    //   },
    // },
    // {
    //   name: 'borderWidth',
    //   displayName: 'Border Width',
    //   type: 'number',
    //   default: 1,
    //   control: {
    //     type: 'slider',
    //     max: 5,
    //     min: 1,
    //   },
    //   enabled: {
    //     bottomBorder: true,
    //   },
    // },
    // {
    //   name: 'positioning',
    //   editable: false,
    //   type: 'text',
    //   default: 'fixedTop',
    // },
    // {
    //   name: 'v2',
    //   editable: false,
    //   type: 'boolean',
    //   default: true,
    // },
  ],
  childComponents: [
    {
      name: 'leftIcon',
      displayName: {
        en: 'Left Icon',
        ja: '左アイコン',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'arrow-back',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      actions: true,
      dataBindings: true,
      dataBindingDataTypes: ['text'],
      props: [
        // {
        //   name: 'titleType',
        //   displayName: 'Title Type',
        //   type: 'text',
        //   default: 'text',
        //   control: {
        //     type: 'menu',
        //     options: [
        //       {
        //         label: 'Logo',
        //         value: 'logo',
        //       },
        //       {
        //         label: 'Text',
        //         value: 'text',
        //       },
        //     ],
        //   },
        // },
        // {
        //   name: 'logoImage',
        //   displayName: 'Image',
        //   type: 'image',
        //   enabled: {
        //     titleType: 'logo',
        //   },
        // },
        // {
        //   name: 'logoSize',
        //   displayName: 'Logo Size',
        //   type: 'number',
        //   default: 50,
        //   control: {
        //     type: 'slider',
        //     max: 100,
        //     min: 30,
        //   },
        //   enabled: {
        //     titleType: 'logo',
        //   },
        // },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Screen Title',
          styles: {
            fontFamily: '@heading',
            color: '#fff',
            fontWeight: '500',
          },
          disableDynamicValues: true,
          libraryEditStyles: { fontWeight: '500' },
          enabled: {
            titleType: ['logo', 'CLICK_INTERNAL_!previous'],
          },
        },
        // {
        //   name: 'align',
        //   displayName: 'Align',
        //   type: 'text',
        //   default: 'center',
        //   control: {
        //     type: 'menu',
        //     options: [
        //       {
        //         label: 'Left',
        //         value: 'left',
        //       },
        //       {
        //         label: 'Center',
        //         value: 'center',
        //       },
        //     ],
        //   },
        // },
      ],
    },
    {
      name: 'rightIcon1',
      displayName: {
        en: 'Right Icon 1',
        ja: '右アイコン1',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'arrow-back',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
    {
      name: 'rightIcon2',
      displayName: {
        en: 'Right Icon 2',
        ja: '右アイコン2',
      },
      actions: true,
      props: [
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'arrow-back',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'action',
          type: 'action',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
        },
      ],
    },
  ],
};
