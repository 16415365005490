import { createBrowserHistory, createMemoryHistory } from 'history';
import { Platform } from 'react-native';
import store from '@common/redux/store';
import { clone } from 'lodash';
import { setPastLocations } from '@common/redux/slice/locationHistory';
import qs from 'query-string';

const history =
  Platform.OS == 'web' ? createBrowserHistory() : createMemoryHistory();

function updatePastLocations(location: any, action: any) {
  const state: any = store.getState();
  const dispatch = store.dispatch;

  let pastLocations = clone(state.locationHistory.pastLocations);

  switch (action) {
    case 'PUSH':
      // first location when app loads and when pushing onto history
      pastLocations.push(location);

      break;
    case 'REPLACE':
      // only when using history.replace
      if (pastLocations.length > 0) {
        let past = qs.parse(pastLocations[pastLocations.length - 1]);
        let current = qs.parse(location);
        if (past.target == current.target && pastLocations.length > 2) {
          pastLocations.pop();
        } else {
          pastLocations[pastLocations.length - 1] = location;
        }
      } else {
        pastLocations.push(location);
      }

      break;
    case 'POP': {
      // happens when using the back button, or forward button
      pastLocations.pop();
      // location according to pastLocations
      const appLocation: any = pastLocations[pastLocations.length - 1];
      if (!(appLocation && appLocation.key === location.key)) {
        // If the current location doesn't match what the app thinks is the current location,
        // blow up the app history.
        pastLocations = [location];
      }

      break;
    }
    default:
  }

  dispatch(setPastLocations(pastLocations));
}
history.listen(updatePastLocations);

export default history;
