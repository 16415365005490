export default {
  name: 'ChangeQuantity',
  displayName: {
    en: 'ChangeQuantity',
    ja: 'プラグイン名',
  },
  minWidth: 100,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'value',
      type: 'number',
      role: 'formValue',
    },
    {
      name: 'defaultQuantity',
      displayName: {
        en: 'Quantity',
        ja: '定量的に',
      },
      type: 'text',
      default: '1',
    },
    {
      name: 'maximum',
      displayName: {
        en: 'Maximum',
        ja: '最大',
      },
      type: 'text',
      default: '10',
    },
    {
      name: 'minimum',
      displayName: {
        en: 'Minimum',
        ja: '最小',
      },
      type: 'text',
      default: '1',
    },
    {
      name: 'backgroundColor',
      type: 'color',
      displayName: {
        en: 'BackgroundColor',
        ja: 'BackgroundColor',
      },
      default: '#fff',
    },
    {
      name: 'color',
      type: 'color',
      displayName: {
        en: 'Text Color',
        ja: 'Text Color',
      },
      default: '#000',
    },
    {
      name: 'borderRadius',
      default: 5,
    },
  ],
};
