import { appErrorSelector } from '@common/redux/selectors/app';
import { resetError } from '@common/redux/slice/app';
import history from '@common/routes/history';
import queryString from 'query-string';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';

const ErrorComponent = () => {
  const paramsString = history.location.search.split('/')[0];

  const paramsJson = queryString.parse(paramsString);

  const insets = useSafeAreaInsets();

  const error = useSelector(appErrorSelector);
  const dispatch = useDispatch();

  const isVisible = useMemo(() => error?.isError, [error]);
  const timeCheck = 3000;

  const handleResetError = () => {
    dispatch(resetError());
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (isVisible) {
        handleResetError();
      }
    }, timeCheck);
    return () => {
      clearInterval(timer);
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: 'absolute',
      bottom:
        Platform.OS === 'web'
          ? paramsJson?.device === 'iphonePro'
            ? 25
            : 0
          : 0 + insets.bottom,
      backgroundColor: 'black',
      flexDirection: 'row',
      width: '100%',
      padding: 10,
    },
    text: { color: 'white' },
    closeButton: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
    },
  });

  const text = error.messages.reduce(
    (pre: string, curr: Record<string, any>) => pre + curr?.message || '',
    ''
  );

  return (
    <View style={styles.container}>
      <Text style={[styles.text, { flexGrow: 1 }]}>{text}</Text>

      <TouchableOpacity style={styles.closeButton} onPress={handleResetError}>
        <Text style={styles.text}>X</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ErrorComponent;
