import { checkLocationSuccess } from '@common/redux/slice/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

const useMapLocation = ({ isUseMap }: { isUseMap: boolean }) => {
  const dispatch = useDispatch();
  const [initializedMap, setInitializedMap] = useState<{
    isReady: boolean;
    position: any;
    error: any;
  }>({
    isReady: false,
    position: null,
    error: null,
  });
  const status = useMemo(() => isUseMap, [isUseMap]);

  const watchPosition = function () {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
      });
    });
  };

  useEffect(() => {
    //realtime location
    if (status) {
      setInitializedMap((pre) => ({ ...pre, isReady: false }));

      watchPosition()
        .then((position: any) => {
          if (position) {
            if (position.coords) {
              dispatch(
                checkLocationSuccess({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                })
              );
              setInitializedMap((pre) => ({
                ...pre,
                isReady: true,
                position: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
              }));
            }
          }
        })
        .catch((err) => {
          setInitializedMap((pre) => ({
            ...pre,
            isReady: true,
            error: err.message,
          }));
        });
    } else {
      setInitializedMap((pre) => ({
        ...pre,
        isReady: true,
      }));
    }
  }, [status]);

  return initializedMap;
};

export default useMapLocation;
