import { IRecord } from '@common/types';
import { get } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { Platform, View } from 'react-native';
import { defaultURL } from '../../../utils/common.constant';
import { convertTextAttributes } from '../../shared';
import AvatarList from '../Avatar';
import IconList from '../Icon';
import ImageList from '../Image';
import Toggle from '../Toggle';

type Props = {
  attributes: Record<string, any>;
  record: IRecord;
  mode: 'leftSection' | 'rightSection' | 'icon1' | 'icon2';
  handlePress?: () => any;
  externalCollection?: any;
};

const LeftSection = ({ attributes, record, mode, handlePress }: Props) => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;

  const getImage = (property: any, type: 'avatar' | 'image') => {
    if (property?.imageUrl === undefined) return;

    const imageBinding = get(record, `${mode}.imageUrl`);
    switch (property.imageUrl?.imageType) {
      case 'internal':
        return get(imageBinding, 'url', null);

      default:
        return get(
          record,
          `${mode}.avatarUrl`,
          imageBinding ||
            convertTextAttributes(
              type == 'image'
                ? property?.imageUrl?.imageUrl
                : property?.avatarUrl
            )
        );
    }
  };

  const renderItem = (sectionValue: any) => {
    const placeholderImage =
      get(sectionValue, 'imageUrl.binding.options.placeholderImageEnabled') &&
      get(sectionValue, 'imageUrl.imageType') !== 'uploaded'
        ? get(sectionValue, 'imageUrl.binding.options.placeholderImage')
        : isCanvas
        ? defaultURL
        : null;

    switch (sectionValue.type) {
      case 'avatar':
        const value = getImage(sectionValue, 'avatar');

        return <AvatarList avatarURL={value} placeholder={placeholderImage} />;
      case 'image':
        const imageUrl = getImage(sectionValue, 'image');

        return <ImageList imageURL={imageUrl} placeholder={placeholderImage} />;
      case 'toggle':
        return (
          <Toggle
            activeIcon={sectionValue.activeIcon}
            activeColor={sectionValue.activeColor}
            inactiveColor={sectionValue.inactiveColor}
            inactiveIcon={sectionValue.inactiveIcon}
          />
        );
      default:
        return (
          <IconList
            icon={sectionValue.icon}
            color={sectionValue.iconColor}
            handlePress={handlePress}
          />
        );
    }
  };

  return (
    <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {renderItem(attributes)}
    </View>
  );
};

export default LeftSection;
