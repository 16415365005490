export default {
  name: 'Line',
  displayName: {
    en: 'Line',
    ja: '動作ボタン',
  },
  icon: 'icon.png',
  defaultWidth: 56,
  defaultHeight: 1,
  props: [
    // {
    //   name: 'nameIcon',
    //   displayName: {
    //     en: '',
    //     ja: '',
    //   },
    //   type: 'icon',
    //   nameIcon: 'add',
    // },
    // {
    //   name: 'action',
    //   type: 'action',
    //   displayName: {
    //     en: 'ActionButton',
    //     ja: 'ClickFlow',
    //   },
    // },
  ],
  resizeX: false,
  resizeY: false,
};
