import { loadPages } from '@common/redux/slice/page';
import { fetchPages } from '@common/services';
import { IAppInfo, IPage } from '@common/types';
import { appUseMap } from '@common/utils/component';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { TOKEN_KEY } from '@common/constants';
// import { chunk } from 'lodash';

type APIResponse = {
  data: any;
  error: string | null;
  isLoading: boolean;
  isUseMap: boolean;
};

const EXPIRIES = 'cacheExpiresOn';
const SCREEN_LOCAL = 'screenLocal';

export const getAllPages = (appId: string, appInfo: IAppInfo | undefined) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState<APIResponse>({
    data: [],
    error: null,
    isLoading: true,
    isUseMap: false,
  });

  const _fetchPages = useCallback(async () => {
    setResponse((prev) => ({ ...prev, isLoading: true }));
    try {
      const params = {};

      // const expiresOnLocal = await AsyncStorage.getItem(EXPIRIES);
      // const expiresOn = appInfo?.cacheExpiresOn;
      // const keys = await AsyncStorage.getAllKeys();
      let pages: any[] = [];
      if (appInfo) {
        const pageData = await fetchPages(appId, params);
        pages = pageData.data;

        // if (expiresOnLocal !== expiresOn) {
        //   console.log('fetch api ---- ');
        //   const pageData = await fetchPages(appId, params);
        //   pages = pageData.data;

        //   // remove all token before saving
        //   const keyNoToken = keys.filter((i) => i != TOKEN_KEY);
        //   AsyncStorage.multiRemove(keyNoToken);
        //   // save pages to localstorage
        //   await AsyncStorage.setItem(EXPIRIES, pageData.expiresOn);
        //   const chunkPages = chunk(pages, 20);
        //   for (let i = 0; i < chunkPages.length; i++) {
        //     let screenLocal = 'screenLocal' + i;
        //     let page = chunkPages[i];
        //     //save first user
        //     await AsyncStorage.setItem(screenLocal, JSON.stringify(page));
        //   }
        // } else {
        //   console.log('get local ---- ');
        //   for (let key of keys) {
        //     if (key.includes('screenLocal')) {
        //       let val = (await AsyncStorage.getItem(key)) || '[]';
        //       // parse JSON
        //       let page = JSON.parse(val);
        //       pages.push(...page);
        //     }
        //   }
        // }
      }
      const isUseMap = appUseMap(pages);

      dispatch(
        loadPages(
          pages.reduce(
            (acc, page: IPage) => ({ ...acc, [page.screenUuid]: page }),
            {}
          )
        )
      );

      setResponse((prev) => ({
        ...prev,
        isUseMap,
        isLoading: false,
        data: pages,
      }));
    } catch (error: any) {
      setResponse((prev) => ({
        ...prev,
        isLoading: false,
        error: error.message || 'get data error',
      }));
    }
  }, [appId, appInfo]);

  useEffect(() => {
    _fetchPages();
  }, [_fetchPages]);

  return response;
};
