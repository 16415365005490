import React, { memo } from 'react';
import { Text, View } from 'react-native';
import Icon from '../AppBar/Icon';
import BrandIcon from './BrandIcon';
import { CardInfo } from './hook';

interface Props {
  titleEnabled?: boolean;
  titleText: string;
  card: CardInfo;
}
export const CardSaved: React.FC<Props> = ({
  titleEnabled,
  titleText,
  card,
}) => {
  return (
    <View
      style={{
        width: '100%',
        alignContent: 'center',
      }}
    >
      {titleEnabled ? <Text>{titleText}</Text> : <React.Fragment />}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 12,
        }}
      >
        <BrandIcon brand={card.brand} />
        <View style={{ marginRight: 5 }}></View>
        {Array(12)
          .fill(1)
          .map((value, index) => {
            return (
              <Icon
                name={'checkbox-blank-circle'}
                key={index.toString()}
                size={12}
                style={{
                  marginRight: index % 4 === 3 ? 5 : 0,
                }}
              />
            );
          })}
        <Text style={{ fontSize: 16 }}>{card.cardNumber}</Text>
      </View>
      {card.text.message ? (
        <Text
          style={{
            color: card.text.status === 'successful' ? 'green' : 'red',
          }}
        >
          {card.text.message}
        </Text>
      ) : (
        <React.Fragment />
      )}
    </View>
  );
};
