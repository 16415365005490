import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, ['width', 'height', 'zIndex']),
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
  });
};

export default createStyles;
