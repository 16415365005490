import { PlanPermissionResponse } from '@common/types/plan';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = PlanPermissionResponse;

const initialState: InitialState = {
  capacity: {
    current: 0,
    max: 1,
  },
  numberOfApps: {
    current: 0,
    max: 1,
  },
  numberOfMembers: {
    current: 0,
    max: 1,
  },
  numberOfRecords: {
    current: 0,
    max: 1,
  },
  zapier: {
    current: 0,
    max: 1,
  },
};

const { reducer, actions } = createSlice({
  name: 'plan-permission',
  initialState,
  reducers: {
    setPlanPermission: (
      state: InitialState,
      action: PayloadAction<Partial<PlanPermissionResponse>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setPlanPermission } = actions;

export default reducer;
