import { get, reduce, last, isEmpty } from 'lodash';
import { MarkerType } from '../../../types/map.type';
import { convertTextAttributes } from '../../shared';

export const getCurrentLocationStatus = (attrs: any) => {
  const makerType = get<'simple' | 'multiple'>(attrs, 'markerType', 'multiple');

  let path = 'markers.currentLocation';

  if (makerType === 'simple') {
    path = 'marker.currentLocation';
  }

  return get(attrs, path);
};

export const stringToJson = (str: any) => {
  try {
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return [];
  }
};

export const convertLat = (num: number) => {
  const remainder = num % 180;
  if (remainder > 90) {
    return remainder - 180;
  } else if (remainder < -90) {
    return remainder + 180;
  } else {
    return remainder;
  }
};

const getValueMarker = (
  record: Record<string, any> = {},
  markerType: string
) => {
  // const ignoreKey = markerType === 'simple' ? 'markers' : 'marker';

  return reduce(
    Object.keys(record),
    (pre: Record<string, any>, curr: string) => {
      const splitKeys: string[] = curr.split('.') || [];

      return {
        ...pre,
        ...{
          [last(splitKeys) || '']: record[curr],
        },
      };
    },
    {}
  );
};

const getImageMarker = (
  imageType: string,
  source: Record<string, any>,
  markerData: Record<string, any>,
  imagePath: string,
  mode: string
) => {
  if (!source || source?.markerSource === 'default' || !imageType) return;

  switch (imageType) {
    case 'internal':
      return get(
        markerData,
        imagePath,
        get(markerData, `${mode}.${imagePath}`)
      );

    case 'url':
      return {
        url:
          get(
            markerData,
            'imageUrl',
            convertTextAttributes(get(source, 'imageUrl'))
          ) ||
          get(
            markerData,
            imagePath,
            get(
              markerData,
              `${mode}.${imagePath}`,
              convertTextAttributes(get(source, imagePath))
            )
          ),
      };

    default:
      return convertTextAttributes(get(source, 'imageUrl'));
  }
};

export const convertMarker = (
  record: Record<string, any>,
  attributes: Record<string, any>
) => {
  try {
    const { markerType = 'simple' } = attributes;
    const mode = markerType === 'multiple' ? 'markers' : 'marker';
    const imagePath =
      markerType === 'simple' ? 'markerImage' : 'listMarkerImage';
    const markerData = getValueMarker(record, markerType);
    const markerSource = get(attributes, mode);
    const { imageType = 'uploaded' } = get(markerSource, imagePath, {});
    const { markerTypeOfPin = 'address' } = markerSource;

    return {
      ...(record?.id && { id: record?.id }),
      ...(markerTypeOfPin === 'address'
        ? {
            markerAddress: get(
              markerData,
              'markerAddress',
              get(
                markerData,
                'marker.markerAddress',
                convertTextAttributes(get(markerSource, 'markerAddress'))
              )
            ),
          }
        : {
            markerGeometry: {
              lat: Number(
                get(
                  markerData,
                  'markerY',
                  get(
                    markerData,
                    `${mode}.markerY`,
                    convertTextAttributes(get(markerSource, 'markerY'))
                  )
                )
              ),
              lng: Number(
                get(
                  markerData,
                  'markerX',
                  get(
                    markerData,
                    `${mode}.markerX`,
                    convertTextAttributes(get(markerSource, 'markerX'))
                  )
                )
              ),
            },
          }),
      imageMarker: getImageMarker(
        imageType,
        markerSource,
        markerData,
        imagePath,
        mode
      ),
      selectedAddressMarker: get(
        markerData,
        'selectedAddressMarker',
        convertTextAttributes(get(markerSource, 'selectedAddressMarker'))
      ),
      distanceSearch: get(record, 'markers.distanceSearch'),
      record,
    };
  } catch (error) {
    console.log('error', error);
  }
};

export const getDistanceFromCurrent = (mk1: MarkerType, mk2: MarkerType) => {
  const Radius = 6371;
  const rLat1 = mk1.lat * (Math.PI / 180);
  const rLat2 = mk2.lat * (Math.PI / 180);
  const diffLat = rLat2 - rLat1;
  const diffLng = (mk2.lng - mk1.lng) * (Math.PI / 180);

  const distance =
    2 *
    Radius *
    Math.asin(
      Math.sqrt(
        Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
          Math.cos(rLat1) *
            Math.cos(rLat2) *
            Math.sin(diffLng / 2) *
            Math.sin(diffLng / 2)
      )
    );
  return distance;
};

export const getDistance = (mk1: any, mk2: any) => {
  const Radius = 6371;
  const rLat1 = mk1.lat * (Math.PI / 180);
  const rLat2 = mk2.lat * (Math.PI / 180);
  const diffLat = rLat2 - rLat1;
  const diffLng = (mk2.lng - mk1.lng) * (Math.PI / 180);
  const distance =
    2 *
    Radius *
    Math.asin(
      Math.sqrt(
        Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
          Math.cos(rLat1) *
            Math.cos(rLat2) *
            Math.sin(diffLng / 2) *
            Math.sin(diffLng / 2)
      )
    );
  return distance;
};

export function convertKmToM(km: number) {
  return km * 1000;
}
