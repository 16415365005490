import { ICardList } from '@nocode/types';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import CardItem from './Card';
import { getItemList } from './func';

const CardList = (attrs: ICardList) => {
  const { page, totalPage, onLoadMore, initializeList, isLoadMore } = attrs;
  const data = getItemList(attrs);
  const tableId = get(attrs, 'databaseOptions.tableId');
  const isShowFooter = totalPage > page;
  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;

  const emptyList = (target && !tableId) || (!tableId && Platform.OS !== 'web');

  if (emptyList)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attrs.zIndex,
        }}
      >
        <EmptyList attributes={attrs} />
      </View>
    );

  if (initializeList) {
    return <Loading />;
  }

  const ListFooterComponent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (isShowFooter) {
            onLoadMore();
          }
        }}
      >
        {isLoadMore ? (
          <Loading />
        ) : (
          <Text
            style={{
              fontSize: 14,
              textDecorationLine: 'underline',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            もっとロード
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attrs.zIndex,
          ...(attrs.isWeb && {
            height: attrs.height,
            overflowX: 'hidden',
            overflowY: 'visible',
          }),
        }}
      >
        {data.map((item: any, index: number) => {
          return (
            <CardItem
              item={item}
              key={index}
              index={index}
              attrs={attrs}
              onPress={attrs.onPress}
              isWeb={attrs.isWeb}
            />
          );
        })}
      </View>

      {isShowFooter ? <ListFooterComponent /> : <React.Fragment />}
    </>
  );
};

export default CardList;
