import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApp } from '@common/types';

type State = {
  values: Obj;
  app?: IApp;
  loseConnect?: boolean;
};
type Obj = { [key: string]: any };

const initialState: State = {
  values: {},
  loseConnect: false,
};
export type PermissionType = {
  microphone: string | undefined;
  camera: string | undefined;
};

type Action = {
  objectId: string;
  value: Obj;
};

const appSlice = createSlice({
  name: 'liveStream',
  initialState: initialState,
  reducers: {
    changeValueLiveStream: (state: State, action: PayloadAction<Action>) => {
      if (action.payload) {
        state.values = {
          ...state.values,
          [action.payload.objectId]: {
            ...state.values[action.payload.objectId],
            ...action.payload.value,
          },
        };
      }
    },
    setValuesLiveStream: (state: State, action: PayloadAction<any>) => {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    },
    setLoseConnect: (state: State, action: PayloadAction<any>) => {
      state.loseConnect = action.payload;
    },
  },
});

export const { changeValueLiveStream, setValuesLiveStream, setLoseConnect } =
  appSlice.actions;

export default appSlice.reducer;
