import { IIconButton } from '@nocode/types';
import React, { ComponentProps } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { IconButton as RNIconButton } from 'react-native-paper';
import PointIcon from '../CustomIcon';
import { getActions, getItemListClick, isCheckColor } from '../shared';

type Props = ComponentProps<typeof RNIconButton>;

const Icon: React.FC<IIconButton> = (props) => {
  const { width, height, attributes, onPress } = props;
  const page = StyleSheet.create({
    icon: {
      margin: 0,
      width,
      height,
      zIndex: attributes.zIndex,
    },
  });

  const isColor = isCheckColor(
    attributes.iconColor ? attributes.iconColor.trim() : attributes.iconColor
  );

  const childProps: Props = {
    size: attributes.size,
    icon: attributes.iconName,
    color: isColor
      ? attributes.iconColor
        ? attributes.iconColor.trim()
        : attributes.iconColor
      : '#4259AC',
    onPress: () => {
      onPress(getActions(props, 'onPress'), {
        itemListClick: getItemListClick(props?.record),
      });
    },
  };

  if (attributes.iconName === 'point') {
    return (
      <TouchableOpacity onPress={childProps.onPress}>
        <PointIcon
          width={width}
          height={height}
          fill={attributes.iconColor.trim()}
        />
      </TouchableOpacity>
    );
  }
  return <RNIconButton style={page.icon} {...childProps} />;
};

export default Icon;
