import { ActionStatus } from '@common/constants/shared';
import { setWeb3Config } from '@common/redux/slice/database';
import { removeValueBindingScreen } from '@common/redux/slice/page';
import store from '@common/redux/store';
import history from '@common/routes/history';
import { IPage } from '@common/types/index';
import { isEmpty } from 'lodash';
import qs from 'query-string';
import { Platform } from 'react-native';
import { ActionResponse } from '../excuteAction';

function goBack() {
  const state: any = store.getState();

  const pastLocations = state.locationHistory.pastLocations;
  if (Platform.OS === 'web') {
    const current = history.length;
    if (pastLocations.length > 1) {
      history.replace(pastLocations[pastLocations.length - 2]);
    } else {
      history.goBack();
    }
  } else {
    history.goBack();
  }
}

const removeBindingPage = (page: IPage) => {
  if (!page) return;
  const dispatch = store.dispatch;
  dispatch(removeValueBindingScreen(page?.screenUuid));
};

export const executeNavigate: (
  action: any,
  metadata: any
) => Promise<ActionResponse> = async (action: any, metadata: any) => {
  try {
    const target = action.options?.target;

    if (target === 'navigation.back') {
      goBack();
    }
    const dispatch = store.dispatch;
    const search = history.location.search;
    const state: any = store.getState();
    const { currentRecordIds, web3Config } = metadata;

    const query = qs.parse(search);

    const pageInfo = state.pageInfo?.pages[target];

    if (!pageInfo)
      return { status: ActionStatus.FAILED, message: 'page not found !' };

    let targetModal = pageInfo?.isModal ? pageInfo.screenUuid : '';

    const transition = action?.options?.transition || 'TRANSITION_NONE';

    if (
      targetModal &&
      !(
        transition === 'TRANSITION_SLIDE_DOWN' ||
        transition === 'TRANSITION_SLIDE_RIGHT' ||
        transition === 'TRANSITION_SLIDE_LEFT' ||
        transition === 'TRANSITION_SLIDE_UP'
      )
    ) {
      history.push({
        search: qs.stringify({
          ...query,
          ...{ targetModal: targetModal, modalTransition: transition },
        }),
      });
    } else if (target) {
      history.push({
        search: qs.stringify({
          ...query,
          ...{
            target: target,
            targetModal: targetModal,
            transition: transition,
          },
        }),
      });
    }

    !isEmpty(web3Config) && dispatch(setWeb3Config(web3Config));
    removeBindingPage(pageInfo);

    return {
      status: ActionStatus.SUCCESS,
      ...(!isEmpty(currentRecordIds) && { currentRecordIds }),
    };
  } catch (error: any) {
    return { status: ActionStatus.FAILED, message: error.message };
  }
};
