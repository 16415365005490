export default {
  name: 'UnivaPay',
  displayName: {
    en: 'UnivaPay',
    ja: 'UnivaPay',
  },
  icon: './icon.png',
  defaultWidth: 330,
  defaultHeight: 246,

  props: [
    {
      namespace: 'univaPay',
      name: 'Settings',
      displayName: {
        en: 'Settings',
        ja: 'Settings',
      },
      type: 'payment',
    },
    {
      name: 'successActions',
      displayName: {
        en: 'ClickFlow when UnivaPay Payment is paid successful',
        ja: '決済成功時のClickFlow',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: 'ClickFlow when UnivaPay Payment is paid failed',
        ja: '決済失敗時のClickFlow',
      },
      type: 'action',
    },
  ],
  childComponents: [
    {
      name: 'email',
      displayName: {
        en: 'Email Field',
        ja: 'メールアドレス',
      },
      props: [
        {
          name: 'emailBuyer',
          displayName: {
            en: 'Buyer email',
            ja: '購入者のメールアドレス',
          },
          type: 'text',
          default: '',
        },
      ],
    },
    {
      name: 'paymentOptions',
      displayName: {
        en: 'Payment Amount',
        ja: '支払い金額',
      },
      enabled: { 'saveCardOptions.saveCardMode': 'saveCardAndPayment' },
      props: [
        {
          name: 'paymentAmount',
          displayName: {
            en: 'Payment Amount',
            ja: '支払い金額',
          },
          type: 'number',
          default: 0,
        },
        {
          name: 'typeCurrency',
          displayName: {
            en: 'Currency Type',
            ja: '通貨',
          },
          type: 'text',
          default: 'jpy',
          control: {
            type: 'menu',
            options: [
              {
                label: 'JPY',
                value: 'jpy',
              },
              {
                label: 'USD',
                value: 'usd',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'titleCardName',
      displayName: {
        en: 'Title Card Name',
        ja: 'タイトル Card Name',
      },
      // type: 'text',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Card number information',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#000000',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#fff',
          enabled: {
            background: true,
          },
        },
        {
          name: 'fontSize',
        },
        {
          name: 'titleLineNum',
        },
        {
          name: 'fontStyleItalic',
        },
        {
          name: 'textDecorationUnderline',
        },
        {
          name: 'textDecorationLineThrough',
        },
        {
          name: 'fontWeightBold',
        },
      ],
    },
    {
      name: 'titleCardNumber',
      displayName: {
        en: 'Title Card Number',
        ja: 'タイトル Card Number',
      },
      // type: 'text',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Card number information',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#000000',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#fff',
          enabled: {
            background: true,
          },
        },
        {
          name: 'fontSize',
        },
        {
          name: 'titleLineNum',
        },
        {
          name: 'fontStyleItalic',
        },
        {
          name: 'textDecorationUnderline',
        },
        {
          name: 'textDecorationLineThrough',
        },
        {
          name: 'fontWeightBold',
        },
      ],
    },
    {
      name: 'submitButton',
      displayName: {
        en: 'Submit Button',
        ja: '送信ボタン',
      },
      // type: 'text',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Pay Now',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'fontSize',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#fff',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#34A0DD',
        },
        {
          name: 'borderRadius',
          default: 100,
        },
      ],
    },
    {
      name: 'saveCardOptions',
      displayName: {
        en: 'Save card(not payment)',
        ja: 'カード情報の保存のみ(決済なし)',
      },
      props: [
        {
          name: 'saveCardMode',
          displayName: {
            en: 'Please choose',
            ja: '選択してください',
          },
          type: 'text',
          default: 'saveCardAndPayment',
          control: {
            type: 'menu',
            options: [
              {
                label: '支払い＆カード保存オプション',
                value: 'saveCardAndPayment',
              },
              {
                label: 'カード保存のみ',
                value: 'onlySaveCard',
              },
            ],
          },
        },
      ],
    },
  ],
};
