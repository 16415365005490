import { Button, Input, Popconfirm } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

const SearchComponent: FC<Record<string, any>> = ({
  disableDeleteButton,
  disableSearch,
  handleSearch,
  handleDelete,
  handleOpenDownloadModal,
  selectable,
  enableDownload,
  selectedRowKeys,
}) => {
  const { messages } = useIntl();

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
        marginBottom: 5,
      }}
    >
      {selectable && (
        <Popconfirm
          title={String(messages['database.table.delete.confirm']).replace(
            '{count}',
            selectedRowKeys.length.toString()
          )}
          onConfirm={handleDelete}
          okText={messages['database.table.delete.yes']}
          cancelText={messages['database.table.delete.no']}
        >
          <Button disabled={disableDeleteButton} danger type="primary">
            {messages['database.table.delete.button']}
          </Button>
        </Popconfirm>
      )}

      {enableDownload && (
        <Button
          type="primary"
          style={{ marginLeft: 5 }}
          onClick={handleOpenDownloadModal}
        >
          {messages['database.table.download.button']}
        </Button>
      )}

      {!disableSearch && (
        <Input.Search
          style={{ width: 300, marginLeft: 5 }}
          placeholder={String(messages['database.table.search.placeholder'])}
          onSearch={handleSearch}
        />
      )}
    </div>
  );
};

export default SearchComponent;
