import { setForgotPassword } from '@common/redux/slice/action';
import store from '@common/redux/store';
import { MetadataAction } from '@common/types/action';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';

const actionType = {
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
};

export const forgotPassword: (
  action: Record<string, any>,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: Record<string, any>,
  metadata: MetadataAction
) => {
  const dispatch = store.dispatch;

  if (action?.type === actionType.FORGOT_PASSWORD && !!action?.isStatus) {
    return {
      status: action.isStatus,
    };
  }

  dispatch(
    setForgotPassword({
      state: true,
      animation: 'fadeInUpBig',
    })
  );

  return {
    status: 'FAILED',
  };
};
