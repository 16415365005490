import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import {
  View,
  Text,
  Platform,
  FlatList,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import queryString from 'query-string';
import { getItemList } from '../CardList/func';
import { get, isEmpty } from 'lodash';
import EmptyList from '../EmptyList';
import { checkFont } from '../func';
import { getPlaceholder, imageUrl } from '../../utils/handleCardDetail';
import { defaultURL } from '../../utils/common.constant';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import Loading from '../Loading';

const CROWN_1 = require('./crown1.png').default;
const CROWN_2 = require('./crown2.png').default;
const CROWN_3 = require('./crown3.png').default;
const SMILE_ICON = require('./smile.png').default;

export const RANKING_LIST = 'rankingList';

const RankingList: React.FC<any> = (props) => {
  const {
    onPress,
    initializeList,
    dataBinding = [],
    width,
    height,
    attributes = {},
    onLoadMore,
  } = props;
  const {
    backgroundColor,
    opacity,
    fontSize,
    fontFamily,
    lineHeight,
    color,
    items,
    classify,
    avatarImage,
  } = attributes;

  const [page, setPage] = useState<any>(1);
  const flatListRef: any = useRef();
  const elementId = 'flat-list' + '-' + props?.id;

  const tableId = get(props, 'attributes.items.source.tableId');
  const maximum = get(props, 'attributes.items.source.options.limit', null);

  const cardItemsData: any = useMemo(() => {
    return getItemList(props, RANKING_LIST);
  }, [props]);

  const handlePress = useCallback(
    (item: Record<string, any>) => {
      const options = {
        itemListClick: getItemListClick(item.record),
      };
      onPress && onPress(getActions(props, 'onPress'), options);
    },
    [props]
  );

  const getInitValues = () => {
    if (Platform.OS !== 'web') {
      // App
      if (dataBinding) {
        return Object.keys(dataBinding)?.length === 0 ? items : dataBinding;
      }
      return items;
    } else {
      // web
      const search = queryString.parse(window?.location?.search);
      const target = search?.target;
      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return items;
      }
    }
  };

  const getCrownImage = useCallback(
    (index) => {
      switch (index) {
        case 0:
          return CROWN_1;
        case 1:
          return CROWN_2;
        case 2:
          return CROWN_3;
        default:
          return '';
      }
    },
    [CROWN_1, CROWN_2, CROWN_3]
  );

  const listData = getInitValues();

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;

  if (target && !tableId && !props?.loading)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: props.zIndex,
        }}
      >
        <EmptyList attributes={props} />
      </View>
    );

  const isFooter =
    cardItemsData.length < props.total || cardItemsData.length < maximum;

  const ListFooterComponent = () => {
    return props.loading ? (
      <View
        style={{
          display: 'flex',
          height: 50,
          justifyContent: 'center',
          marginBottom: 100,
        }}
      >
        <ActivityIndicator size="small" color="#1976d2" />
      </View>
    ) : (
      <View>
        {isFooter ? (
          <TouchableOpacity
            style={{
              height: 50,
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 100,
            }}
            onPress={onLoadMore}
          >
            <Text
              style={{
                fontSize: 14,
                textDecorationLine: 'underline',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              numberOfLines={1}
            >
              もっとロード
            </Text>
          </TouchableOpacity>
        ) : (
          <View
            style={{
              height: 150,
            }}
          ></View>
        )}
      </View>
    );
  };

  useEffect(() => {
    setPage(1);
    flatListRef.current?.scrollToOffset({ animated: false, offset: 0, y: 0 });
  }, [elementId]);

  useEffect(() => {
    flatListRef.current?.scrollToOffset({ animated: false, offset: 0, y: 0 });
    if (props?.hasAction) {
      props.setLoadMore && props.setLoadMore(1);
      setPage(1);
    }
  }, [props?.hasAction]);

  return initializeList ? (
    <Loading />
  ) : (
    <View
      style={{
        backgroundColor,
        opacity,
        height,
        width,
        display: 'flex',
      }}
    >
      <FlatList
        ref={flatListRef}
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        maxToRenderPerBatch={4}
        scrollEnabled={true}
        data={Array.isArray(listData) ? listData : []}
        ListFooterComponent={ListFooterComponent}
        renderItem={({ item, index }) => {
          const bindingValue = getValueBinding('', item, props);
          return (
            <TouchableOpacity
              style={{
                width: '100%',
                height: 58,
                borderTopWidth: 1,
                borderColor: '#BDBDBD',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onPress={() =>
                handlePress({
                  record: item,
                  indexRecord: index,
                })
              }
            >
              <View
                style={{
                  position: 'relative',
                  width: 40,
                  height: 30,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              >
                {index === 0 || index === 1 || index === 2 ? (
                  <Image
                    style={{
                      height: 30,
                      width: 30,
                    }}
                    source={{
                      uri: getCrownImage(index),
                    }}
                    resizeMode={'contain'}
                  />
                ) : (
                  <Text
                    style={{
                      position: 'absolute',
                      zIndex: 100000,
                      top: 7,
                      left: 10,
                      lineHeight,
                      fontFamily: checkFont(fontFamily),
                    }}
                  >
                    {index + 1}
                  </Text>
                )}
              </View>
              {avatarImage?.enabled ? (
                <Image
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 15,
                    marginRight: 10,
                    borderWidth: 1,
                    borderColor: '#707070',
                  }}
                  source={{
                    uri:
                      imageUrl(avatarImage, item, 'avatarImage') ||
                      getPlaceholder(avatarImage) ||
                      defaultURL,
                  }}
                />
              ) : null}
              <View>
                <Text
                  style={{
                    fontSize,
                    color,
                    width: width - 110,
                    lineHeight,
                    fontFamily: checkFont(fontFamily),
                  }}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {get(bindingValue, 'title.text')}
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {classify ? (
                    <Image
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        marginRight: 10,
                      }}
                      source={{
                        uri: SMILE_ICON,
                      }}
                    />
                  ) : null}

                  <Text
                    style={{
                      fontSize,
                      color: index === 0 ? 'red' : color,
                      maxWidth: '100%',
                      lineHeight,
                      fontFamily: checkFont(fontFamily),
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {get(bindingValue, 'subTitle.text')}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};

export default RankingList;
