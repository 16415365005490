import { ANIMATION_MODE, desktopWidth } from '@common/constants/shared';
import { IApp, IAppInfo, IPage } from '@common/types';
import { filter, get, map, sortBy } from 'lodash';
import queryString from 'query-string';

export const getPaddingBottomScreen = (screen: IPage, originY: number) => {
  const sortArrComp = sortBy(screen.metadata, 'y').reverse();
  const lastFixedObj = sortArrComp.find(
    (o) => o.fixPosition == 'top' || o.fixPosition == 'bottom'
  );

  const lastAbsoluteObj = sortArrComp.find(
    (o) => o.fixPosition == 'none' || !o.fixPosition
  );

  let paddingBottom = 10;
  if (lastAbsoluteObj && lastFixedObj) {
    if (lastFixedObj.y * originY > (screen.height * originY) / 2) {
      paddingBottom =
        screen.height -
        lastFixedObj.y -
        lastFixedObj.height +
        Math.abs(
          lastFixedObj.y -
            lastAbsoluteObj.y -
            lastAbsoluteObj.height +
            lastFixedObj.height
        );
    }
  }

  return paddingBottom;
};

export const getAttributesNormal = (screen: IPage) => {
  const arrComp = screen.metadata;

  return filter(arrComp, (o) => !o?.fixPosition || o.fixPosition == 'none');
};

export const getAttributesFixed = (screen: IPage) => {
  const arrComp = screen.metadata;

  return filter(
    arrComp,
    (o) => o.fixPosition == 'top' || o.fixPosition == 'bottom'
  );
};

export const checkMobileLayout = (dimension: any) => {
  return dimension.width < desktopWidth;
};

export const copyToClipboard = (value: string) => {
  if (value) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator?.clipboard?.writeText(value).then(() => alert('Link copied!'));
    } else {
      const linkCopy = document.createElement('textarea');
      linkCopy.value = value;
      document.body.appendChild(linkCopy);
      linkCopy.focus();
      linkCopy.select();
      document.execCommand('copy');
      linkCopy.remove();
      alert('Link copied!');
    }
  } else {
    alert('error ?');
  }
};

export const animatedScreen = (value: any) => {
  let transition = typeof value == 'string' ? value : 'TRANSITION_NONE';
  switch (transition) {
    case 'TRANSITION_FLOAT_RIGHT':
      return ANIMATION_MODE.floatLeft;
    case 'TRANSITION_FLOAT_UP':
      return ANIMATION_MODE.floatUp;
    case 'TRANSITION_FLOAT_LEFT':
      return ANIMATION_MODE.floatRight;
    case 'TRANSITION_FLOAT_DOWN':
      return ANIMATION_MODE.floatDown;
    case 'TRANSITION_SLIDE_LEFT':
      return ANIMATION_MODE.slideRight;
    case 'TRANSITION_SLIDE_RIGHT':
      return ANIMATION_MODE.slideLeft;
    case 'TRANSITION_SLIDE_UP':
      return ANIMATION_MODE.slideUp;
    case 'TRANSITION_SLIDE_DOWN':
      return ANIMATION_MODE.slideDown;
    default:
      return ANIMATION_MODE.none;
  }
};

export const formatScreens = ({ screens = [], screen }: any) => {
  return {
    list: () => ({
      toView: () =>
        map(screens, (s) => ({
          ...s,
          attributes: JSON.parse(get(s, 'attributes', '{}')),
          metadata: JSON.parse(get(s, 'metadata')),
        })),
      toMutate: () =>
        map(screens, (s) => ({
          ...s,
          attributes: JSON.stringify(get(s, 'attributes', {})),
          metadata: JSON.stringify(get(s, 'metadata')),
        })),
    }),
    single: () => ({
      toView: () => ({
        ...screen,
        attributes: JSON.parse(get(screen, 'attributes', '{}')),
        metadata: JSON.parse(get(screen, 'metadata')),
      }),
      toPatch: () => ({
        ...screen,
        attributes: JSON.stringify(get(screen, 'attributes', {})),
        metadata: JSON.stringify(get(screen, 'metadata')),
      }),
    }),
  };
};

export const formatApp = (app: IApp): IAppInfo => ({
  ...app,
  metadata: JSON.parse(app.metadata),
});

export const isCanvas = () => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  // on canvas
  if (!target) {
    return true;
  }
  return false;
};

export const getMetadataScreen = (screen: IPage) => {
  return (
    screen.metadata?.length &&
    screen.metadata.map((item: Record<string, any>, index: number) => {
      item.zIndex = index + 1;
      if (item?.children) {
        item.children.map((child: Record<string, any>, i: number) => {
          child.zIndex = index + i + 2;
          return child;
        });
      }
      return item;
    })
  );
};
