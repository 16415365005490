import React, { ComponentProps } from 'react';
import { IconButton } from 'react-native-paper';
import PointIcon from '../CustomIcon';
import { getActions, getItemListClick, isCheckColor } from '../shared';

type Props = ComponentProps<typeof IconButton> | any;

const ActionButton: React.FC<Props> = (props) => {
  const { attributes, onPress } = props;
  const { nameIcon, backgroundColor, color, fontSize, opacity, zIndex } =
    attributes;

  const handlePress = () => {
    onPress(getActions(props, 'action'), {
      itemListClick: getItemListClick(props.record),
    });
  };

  const childProps: Props = {
    icon:
      nameIcon === 'point'
        ? () => <PointIcon width={24} height={24} fill={color} />
        : nameIcon,
    style: {
      opacity,
      borderRadius: 28,
      backgroundColor: isCheckColor(backgroundColor)
        ? backgroundColor
        : '#4259AC',
      margin: 0,
      width: 56,
      height: 56,
      zIndex,
    },
    color: isCheckColor(color) ? color : '#fff',
    size: fontSize,
  };

  return <IconButton onPress={handlePress} {...childProps} />;
};

export default ActionButton;
