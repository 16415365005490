import React, { FC, useRef } from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { GestureResponderEvent, TouchableOpacity, View } from 'react-native';
import { isEmpty } from 'lodash';

import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { IRectangle } from '@common/types/element';
import {
  getActions,
  getItemListClick,
} from '@common/utils/handleActions/func/helps';
import createStyles from './style';

const Rectangle: FC<IRectangle> = (attrs) => {
  const touchRef = useRef(null);

  const styles = createStyles(attrs);

  const hasAction = !isEmpty(attrs.actions);

  const layout = {
    offsetTop: attrs?.y,
    offsetLeft: attrs?.x,
    parentWidth: attrs?.width,
  };

  if (hasAction) {
    const handlePress = (e: GestureResponderEvent) => {
      let arrayAction = getActions(attrs.actions);
      const options = {
        itemListClick: getItemListClick(attrs?.record),
      };

      attrs.onPress && attrs.onPress(arrayAction, options);
    };

    if (attrs?.isBgGradient) {
      return (
        <LinearGradient
          colors={[
            attrs?.bgGradientFirst || attrs.backgroundColor,
            attrs?.bgGradientSecond || attrs.backgroundColor,
          ]}
          style={styles.container}
          start={{
            x: attrs?.startX ? attrs?.startX : 0,
            y: attrs?.startY ? attrs?.startY : 0,
          }}
          end={{
            x: attrs?.endX ? attrs?.endX : 1,
            y: attrs?.endY ? attrs?.endY : 0,
          }}
        >
          <TouchableOpacity
            activeOpacity={1}
            ref={touchRef}
            onPress={handlePress}
          >
            {attrs?.children && (
              <ObjectRender
                arrComp={attrs?.children}
                isScreen={false}
                layout={{
                  offsetTop: attrs?.y,
                  offsetLeft: attrs?.x,
                  parentWidth: attrs?.width,
                }}
              />
            )}
          </TouchableOpacity>
        </LinearGradient>
      );
    }

    return (
      <TouchableOpacity
        style={styles.container}
        activeOpacity={1}
        ref={touchRef}
        onPress={handlePress}
      >
        {attrs?.children && (
          <ObjectRender
            arrComp={attrs?.children}
            isScreen={false}
            layout={layout}
          />
        )}
      </TouchableOpacity>
    );
  }

  if (attrs?.isBgGradient) {
    return (
      <LinearGradient
        colors={[
          attrs?.bgGradientFirst || attrs.backgroundColor,
          attrs?.bgGradientSecond || attrs.backgroundColor,
        ]}
        style={styles.container}
        start={{
          x: attrs?.startX ? attrs?.startX : 0,
          y: attrs?.startY ? attrs?.startY : 0,
        }}
        end={{
          x: attrs?.endX ? attrs?.endX : 1,
          y: attrs?.endY ? attrs?.endY : 0,
        }}
      >
        {attrs?.children && (
          <ObjectRender
            arrComp={attrs?.children}
            isScreen={false}
            layout={{
              offsetTop: attrs?.y,
              offsetLeft: attrs?.x,
              parentWidth: attrs?.width,
            }}
          />
        )}
      </LinearGradient>
    );
  }

  return (
    <View style={styles.container}>
      <ObjectRender
        keyItem={attrs?.selectedItem?.itemIndex}
        arrComp={attrs?.children || []}
        isScreen={false}
        layout={layout}
      />
    </View>
  );
};

export default Rectangle;
