import { FincodeAppOrderEntity } from '@common/hooks/useFincode';
import { get } from 'lodash';
import { useState } from 'react';
import { Linking, Platform } from 'react-native';
import {
  getActions,
  getItemListClick,
  getValueBinding,
  sleep,
} from '../shared';
import { PayPayBindingValue, PayPayProps } from './type';

const FINCODE_ORDER_ID_NAME = 'fincodeOrderId'; // get from config file

export const usePayPay = (props: PayPayProps) => {
  const {
    onPress,
    isConnected,
    isInCustomList,
    attributes,
    testModeShopId,
    appid,
    changeInput,
    executePayment,
    getPaymentStatus,
  } = props;
  const [executePaymentLoading, setExecutePaymentLoading] = useState(false);

  const bindingValue = getValueBinding(
    isInCustomList ? props.initId : props.id,
    isInCustomList ? props?.record : props.data,
    props
  ) as PayPayBindingValue;
  const amount = +get(bindingValue, 'paymentAmount', 0);
  const testModeEnabled = get(bindingValue, 'testModeEnabled', false);
  const testMode = testModeEnabled ? { shopId: testModeShopId } : undefined;

  const isCanvas =
    window?.location?.href &&
    (window.location.href.split('/').includes('canvas') ||
      window.location.href.split('/').includes('view'));

  const handleAction = async (id: string, data: any = {}) => {
    const arrayAction = getActions(props, id);
    return await onPress(arrayAction, data);
  };
  const handleSuccessAction = async (orderId?: string) => {
    if (attributes?.successActions) {
      await handleAction('successActions', {
        ...attributes?.successActions,
        actionData: {
          [FINCODE_ORDER_ID_NAME]: orderId,
          itemListClick: getItemListClick(props.record),
        },
      });
    }
  };
  const handleFailureAction = async () => {
    if (attributes?.failedActions) {
      await handleAction('failedActions', attributes?.failedActions);
    }
  };
  const waitPaymentSuccess = async (orderId: string) => {
    let payment: FincodeAppOrderEntity;
    return new Promise(async (resolve, reject) => {
      do {
        payment = await getPaymentStatus(orderId);
        if (payment.paymentStatus === 'CAPTURED') {
          return resolve(true);
        }
        if (payment.paymentStatus === 'CANCELED') {
          return reject(payment.cancelReason || 'Unknown error');
        }
        await sleep(3000);
      } while (true);
    });
  };

  const openWebview = async (url: string) => {
    if (Platform.OS === 'web') {
      setTimeout(() => {
        window.open(url, '_blank');
      }, 100);
      return;
    }
    try {
      await Linking.openURL(url);
    } catch (err) {
      alert('Please try again!');
    }
  };

  const createPayment = async () => {
    if (!amount || amount < 0 || !isConnected) {
      return;
    }
    try {
      setExecutePaymentLoading(true);
      const order = await executePayment(
        {
          payType: 'Paypay',
          amount,
          appId: appid,
          redirectType: Platform.OS === 'web' ? '1' : '2',
        },
        testMode
      );

      if (order?.code_url) {
        const url = order.code_url;
        openWebview(url);
        await waitPaymentSuccess(order.id);
      }
      if (order?.id) {
        await changeInput({
          [FINCODE_ORDER_ID_NAME]: order.id,
        });
      }

      if (attributes?.successActions) {
        await handleSuccessAction(order?.id);
      }
    } catch (error) {
      console.error('===PayPay=== error', error);
      await handleFailureAction();
    } finally {
      setExecutePaymentLoading(false);
    }
  };

  return { bindingValue, executePaymentLoading, createPayment };
};

export type Props = ReturnType<typeof usePayPay>;
