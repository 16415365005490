import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { checkStyles } from '../func';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import { StopWatchBindingType, StopWatchType } from './type';

const Timer: React.FC<StopWatchType> = (props) => {
  const {
    attributes,
    onPress,
    height,
    width,
    zIndex,
    record = null,
    isInCustomList,
    data,
    loading,
    loadingAction,
  } = props;
  const { type, opacity } = attributes;

  const bindingValue = getValueBinding(
    isInCustomList ? props.initId : props.id,
    isInCustomList ? props?.record : data,
    props
  ) as StopWatchBindingType;

  const [remainTime, setRemainTime] = useState(0);

  const timeValue = +get(bindingValue, 'time', 0); // seconds
  const intervalCount = +get(bindingValue, 'intervalCount', 0);

  const isCanvas =
    window?.location?.href &&
    (window.location.href.split('/').includes('canvas') ||
      window.location.href.split('/').includes('view'));

  const runAction = () => {
    onPress &&
      onPress(getActions(props, 'action'), {
        itemListClick: getItemListClick(record),
      });
  };

  // update remainTime every 1 second
  const updateRemainTime = (timeValue: number) => {
    setRemainTime(Math.round(timeValue / 1000));
    const remainInterval = setInterval(() => {
      setRemainTime((old) => {
        const newValue = old - 1;
        return newValue < 0 ? 0 : newValue;
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(remainInterval);
    }, timeValue);
    return remainInterval;
  };

  useEffect(() => {
    if (loading || loadingAction || isCanvas) {
      return;
    }
    if (timeValue > 0) {
      const timeValueInMs = timeValue * 1000; // millisecond
      switch (type) {
        case 'timeout': {
          const remainInterval = updateRemainTime(timeValueInMs);
          const timeout = setTimeout(() => {
            runAction();
            clearInterval(remainInterval);
          }, timeValueInMs);
          return () => {
            clearTimeout(timeout);
            clearInterval(remainInterval);
          };
        }
        case 'interval': {
          if (intervalCount > 0) {
            let intervalRemainCount = intervalCount;
            let remainInterval = updateRemainTime(timeValueInMs);
            const interval = setInterval(() => {
              runAction();
              intervalRemainCount = intervalRemainCount - 1;
              if (intervalRemainCount <= 0) {
                clearInterval(interval);
                return;
              }
              clearInterval(remainInterval);
              remainInterval = updateRemainTime(timeValueInMs);
            }, timeValueInMs);
            return () => {
              clearInterval(interval);
              clearInterval(remainInterval);
            };
          }
        }
      }
    }
  }, [timeValue, intervalCount]);

  return (
    <View style={{ opacity, height, width, zIndex }}>
      <Text style={checkStyles(attributes)}>{remainTime}</Text>
    </View>
  );
};

export default Timer;
