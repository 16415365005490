import { signOut } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import { IAction } from '@common/types/';
import io from '@common/services/featherIO/feathers.io';
import { ActionResponse } from '../excuteAction';
import { unregisterFCMUser } from './FCMregister';
import { ActionStatus } from '@common/constants/shared';

export const logOut: (action: IAction) => Promise<ActionResponse> = async (
  action: IAction
) => {
  const dispatch = store.dispatch;

  try {
    await io.logout();
    await unregisterFCMUser();

    dispatch(signOut({}));
    return {
      status: ActionStatus.SUCCESS,
      message: 'Logout successful !',
    };
  } catch (e) {
    return {
      status: ActionStatus.FAILED,
      message: 'Logout failed !',
    };
  }
};
