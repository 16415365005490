export default {
  name: 'Timer',
  defaultWidth: 80,
  defaultHeight: 24,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'type',
      displayName: {
        en: 'Type',
        ja: 'タイプ',
      },
      type: 'text',
      default: 'contained',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.timer.countDown',
            value: 'timeout',
          },
          {
            label: 'canvas.menuRight.elementTab.timer.loop',
            value: 'interval',
          },
        ],
      },
    },
    {
      name: 'intervalCount',
      displayName: {
        en: 'Interval Count',
        ja: '繰り返し回数',
      },
      type: 'number',
      enabled: {
        type: 'interval',
      },
    },
    {
      name: 'time',
      displayName: {
        en: 'Time',
        ja: '時間(秒)',
      },
      type: 'number',
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Action',
        ja: 'Action',
      },
    },
  ],
};
