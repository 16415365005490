export default {
  name: 'CardList',
  displayName: {
    en: 'Card List',
    ja: 'カードリスト',
  },
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 528,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'sortList',
      displayName: {
        en: 'Sorting',
        ja: '並び替え',
      },
      type: 'sort',
    },
    {
      name: 'maximumItems',
      displayName: {
        en: 'Maximum number of items',
        ja: '上限',
      },
      type: 'maximumItems',
    },
    {
      name: 'columnCount',
      displayName: {
        en: 'Columns',
        ja: '列',
      },
      type: 'number',
      default: 2,
      control: {
        type: 'menu',
        options: [
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
        ],
      },
    },
    // {
    //   name: 'cardLayout',
    //   displayName: 'Layout',
    //   type: 'text',
    //   default: 'masonry',
    //   control: {
    //     type: 'menu',
    //     options: [
    //       {
    //         label: 'Grid',
    //         value: 'grid',
    //       },
    //       {
    //         label: 'Masonry',
    //         value: 'masonry',
    //       },
    //     ],
    //   },
    //   enabled: {
    //     columnCount: 2,
    //   },
    // },
    {
      name: 'onPress',
      displayName: {
        en: 'List',
        ja: 'リスト',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
  ],
  childComponents: [
    // {
    //   name: 'listHeader',
    //   displayName: 'List Header',
    //   props: [
    //     {
    //       name: 'enabled',
    //       type: 'boolean',
    //       default: false,
    //     },
    //     {
    //       name: 'header',
    //       displayName: 'Title',
    //       type: 'text',
    //       default: 'Title',
    //       styles: {
    //         fontFamily: '@heading',
    //         fontWeight: '600',
    //         color: '@text',
    //         fontSize: '18',
    //       },
    //     },
    //   ],
    // },
    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Title',
          styles: {
            fontFamily: '@body',
            color: '@text',
            fontWeight: '500',
            fontSize: '20',
            textAlignment: 'left',
          },
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'titleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'subTitle',
      displayName: {
        en: 'Sub Title',
        ja: 'サブタイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Sub Title',
          styles: {
            fontFamily: '@body',
            color: '@text',
            fontWeight: '400',
            fontSize: '14',
            textAlignment: 'left',
          },
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'subtitleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'subTitle2',
      displayName: {
        en: 'Sub Title 2',
        ja: 'サブタイトル2',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Sub Title 2',
          styles: {
            fontFamily: '@body',
            color: '@text',
            fontWeight: '400',
            fontSize: '14',
            textAlignment: 'left',
          },
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'subtitle2LineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      displayName: {
        en: 'Image',
        ja: '画像',
      },
      name: 'cardCover',
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'image',
          displayName: {
            en: 'Image Source',
            ja: '画像ソース',
          },
          type: 'image',
        },
        // {
        //   name: 'enabled',
        //   type: 'boolean',
        //   default: 'true',
        // },
        // {
        //   name: 'position',
        //   displayName: 'Position',
        //   type: 'text',
        //   default: 'top',
        //   control: {
        //     type: 'menu',
        //     options: [
        //       {
        //         label: 'Image Top',
        //         value: 'top',
        //       },
        //       {
        //         label: 'Image Middle',
        //         value: 'middle',
        //       },
        //       {
        //         label: 'Image Right',
        //         value: 'right',
        //       },
        //     ],
        //   },
        // },
        // {
        //   name: 'shape',
        //   displayName: 'Shape',
        //   type: 'text',
        //   default: 'landscape',
        //   control: {
        //     type: 'menu',
        //     options: [
        //       {
        //         label: 'Square',
        //         value: 'square',
        //       },
        //       {
        //         label: 'Portrait',
        //         value: 'portrait',
        //       },
        //       {
        //         label: 'Landscape',
        //         value: 'landscape',
        //       },
        //     ],
        //   },
        // },
      ],
    },
    // {
    //   name: 'body',
    //   displayName: 'Body',
    //   role: 'listItem',
    //   reference: 'items',
    //   props: [
    //     {
    //       name: 'text',
    //             displayName: {
    //   en: 'Text',
    //   ja: 'テキスト',
    // },
    //       type: 'text',
    //       default: 'Body text lorem ipsum doler',
    //       styles: {
    //         fontFamily: '@body',
    //         color: '@text',
    //         fontWeight: '400',
    //         fontSize: '16',
    //         textAlignment: 'left',
    //       },
    //     },
    //     {
    //       name: 'enabled',
    //       type: 'boolean',
    //       default: 'true',
    //     },
    //   ],
    // },
    // {
    //   name: 'cardStyles',
    //   displayName: 'Card Styles',
    //   role: 'listItem',
    //   reference: 'items',
    //   props: [
    //     {
    //       name: 'background',
    //       displayName: 'Background',
    //       type: 'boolean',
    //       default: true,
    //     },
    //     {
    //       name: 'backgroundColor',
    //       displayName: 'Background Color',
    //       type: 'color',
    //       default: '#FFFFFF',
    //       enabled: {
    //         background: true,
    //       },
    //     },
    //     {
    //       name: 'border',
    //       displayName: 'Border',
    //       type: 'boolean',
    //       default: false,
    //     },
    //     {
    //       name: 'borderColor',
    //       displayName: 'Border Color',
    //       type: 'color',
    //       default: '#e0e0e0',
    //       enabled: {
    //         border: true,
    //       },
    //     },
    //     {
    //       name: 'borderSize',
    //       displayName: 'Border Size',
    //       type: 'number',
    //       default: 1,
    //       control: {
    //         type: 'slider',
    //         max: 3,
    //         min: 1,
    //       },
    //       enabled: {
    //         border: true,
    //       },
    //     },
    //     {
    //       name: 'rounding',
    //       displayName: 'Rounding',
    //       type: 'number',
    //       default: 1,
    //       control: {
    //         type: 'slider',
    //         max: 60,
    //         min: 0,
    //       },
    //     },
    //     {
    //       name: 'shadow',
    //       displayName: 'Shadow',
    //       type: 'boolean',
    //       default: true,
    //     },
    //   ],
    // },
    {
      name: 'button1',
      displayName: {
        en: 'Button 1',
        ja: 'ボタン1',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Button 1',
          styles: {
            fontFamily: '@body',
            color: '@contrast:backgroundColor',
            fontWeight: '500',
          },
        },
        // {
        //   name: 'background',
        //   displayName: 'Button Background',
        //   type: 'boolean',
        //   default: true,
        // },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '@primary',
          enabled: {
            background: true,
          },
        },
        // {
        //   name: 'border',
        //   displayName: 'Button Border',
        //   type: 'boolean',
        //   default: false,
        // },
        {
          name: 'borderColor',
          displayName: {
            en: 'Border Color',
            ja: 'Border Color',
          },
          type: 'color',
          default: '@primary',
          enabled: {
            border: true,
          },
        },
        // {
        //   name: 'borderSize',
        //   displayName: 'Border Size',
        //   type: 'number',
        //   default: 1,
        //   control: {
        //     type: 'slider',
        //     max: 3,
        //     min: 1,
        //   },
        //   enabled: {
        //     border: true,
        //   },
        // },
        // {
        //   name: 'rounding',
        //   displayName: 'Button Rounding',
        //   type: 'number',
        //   default: 4,
        //   control: {
        //     type: 'slider',
        //     max: 18,
        //     min: 0,
        //   },
        // },
        {
          name: 'onPress',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
          type: 'action',
        },
      ],
    },
    // {
    //   name: 'button2',
    //   displayName: 'Second Button',
    //   role: 'listItem',
    //   reference: 'items',
    //   props: [
    //     {
    //       name: 'enabled',
    //       type: 'boolean',
    //       default: false,
    //     },
    //     {
    //       name: 'text',
    //             displayName: {
    //   en: 'Text',
    //   ja: 'テキスト',
    // },
    //       type: 'text',
    //       default: 'Button 2',
    //       styles: {
    //         fontFamily: '@body',
    //         color: '@contrast:backgroundColor',
    //         fontWeight: '500',
    //       },
    //     },
    //     {
    //       name: 'background',
    //       displayName: 'Button Background',
    //       type: 'boolean',
    //       default: true,
    //     },
    //     {
    //       name: 'backgroundColor',
    //       displayName: 'Background Color',
    //       type: 'color',
    //       default: '@primary',
    //       enabled: {
    //         background: true,
    //       },
    //     },
    //     {
    //       name: 'border',
    //       displayName: 'Button Border',
    //       type: 'boolean',
    //       default: false,
    //     },
    //     {
    //       name: 'borderColor',
    //       displayName: 'Border Color',
    //       type: 'color',
    //       default: '@primary',
    //       enabled: {
    //         border: true,
    //       },
    //     },
    //     {
    //       name: 'borderSize',
    //       displayName: 'Border Size',
    //       type: 'number',
    //       default: 1,
    //       control: {
    //         type: 'slider',
    //         max: 3,
    //         min: 1,
    //       },
    //       enabled: {
    //         border: true,
    //       },
    //     },
    //     {
    //       name: 'rounding',
    //       displayName: 'Button Rounding',
    //       type: 'number',
    //       default: 4,
    //       control: {
    //         type: 'slider',
    //         max: 18,
    //         min: 0,
    //       },
    //     },
    //     {
    //       name: 'onPress',
    //       displayName: 'ClickFlow',
    //       type: 'action',
    //     },
    //   ],
    // },
    {
      name: 'icon1',
      displayName: {
        en: 'Icon 1',
        ja: 'アイコン1',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'iconType',
          displayName: {
            en: 'Type',
            ja: 'タイプ',
          },
          type: 'text',
          default: 'icon',
          control: {
            type: 'menu',
            options: [
              {
                label: 'Icon',
                value: 'icon',
              },
              // {
              //   label: 'Toggle',
              //   value: 'toggle',
              // },
            ],
          },
        },
        {
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          name: 'icon',
          type: 'icon',
          default: 'favorite',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'onPress',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
          type: 'action',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'color',
          displayName: {
            en: 'Text Color',
            ja: 'テキスト色',
          },
          type: 'color',
          default: '#ccc',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'input',
          displayName: {
            en: 'What does this toggle?',
            ja: 'データを選択してください',
          },
          type: 'boolean',
          role: 'autosaveInput',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeIcon',
          displayName: {
            en: 'Active Icon',
            ja: 'アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeColor',
          displayName: {
            en: 'Active Color',
            ja: 'アクティブ時の色',
          },
          type: 'color',
          default: '@primary',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveIcon',
          displayName: {
            en: 'Inactive Icon',
            ja: '非アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box-outline-blank',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveColor',
          displayName: {
            en: 'Inactive Color',
            ja: '非アクティブ時の色',
          },
          type: 'color',
          default: '#bbb',
          enabled: {
            iconType: 'toggle',
          },
        },
        // {
        //   name: 'activeActions',
        //   displayName: {
        //     en: 'Additional actions only when toggle is turned ON',
        //     ja: 'Additional actions only when toggle is turned ON',
        //   },
        //   type: 'action',
        //   enabled: {
        //     iconType: 'toggle',
        //   },
        // },
        // {
        //   name: 'inactiveActions',
        //   displayName: {
        //     en: 'Additional actions only when toggle is turned OFF',
        //     ja: 'Additional actions only when toggle is turned OFF',
        //   },
        //   type: 'action',
        //   enabled: {
        //     iconType: 'toggle',
        //   },
        // },
      ],
    },
    {
      name: 'icon2',
      displayName: {
        en: 'Icon 2',
        ja: 'アイコン2',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'iconType',
          displayName: {
            en: 'Type',
            ja: 'タイプ',
          },
          type: 'text',
          default: 'icon',
          control: {
            type: 'menu',
            options: [
              {
                label: 'Icon',
                value: 'icon',
              },
              // {
              //   label: 'Toggle',
              //   value: 'toggle',
              // },
            ],
          },
        },
        {
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          name: 'icon',
          type: 'icon',
          default: 'share',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'onPress',
          displayName: {
            en: 'ClickFlow',
            ja: 'ClickFlow',
          },
          type: 'action',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'color',
          displayName: {
            en: 'Text Color',
            ja: 'テキスト色',
          },
          type: 'color',
          default: '#ccc',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'input',
          displayName: {
            en: 'What does this toggle?',
            ja: 'データを選択してください',
          },
          type: 'boolean',
          role: 'autosaveInput',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeIcon',
          displayName: {
            en: 'Active Icon',
            ja: 'アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeColor',
          displayName: {
            en: 'Active Color',
            ja: 'アクティブ時の色',
          },
          type: 'color',
          default: '@primary',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveIcon',
          displayName: {
            en: 'Inactive Icon',
            ja: '非アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box-outline-blank',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveColor',
          displayName: {
            en: 'Inactive Color',
            ja: '非アクティブ時の色',
          },
          type: 'color',
          default: '#bbb',
          enabled: {
            iconType: 'toggle',
          },
        },
        // {
        //   name: 'activeActions',
        //   displayName: {
        //     en: 'Additional actions only when toggle is turned ON',
        //     ja: 'Additional actions only when toggle is turned ON',
        //   },
        //   type: 'action',
        //   enabled: {
        //     iconType: 'toggle',
        //   },
        // },
        // {
        //   name: 'inactiveActions',
        //   displayName: {
        //     en: 'Additional actions only when toggle is turned OFF',
        //     ja: 'Additional actions only when toggle is turned OFF',
        //   },
        //   type: 'action',
        //   enabled: {
        //     iconType: 'toggle',
        //   },
        // },
      ],
    },
  ],
};
