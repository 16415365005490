export default {
  name: 'Blocto',
  displayName: {
    en: 'Blocto',
    ja: 'Blocto',
  },
  defaultWidth: 155,
  defaultHeight: 44,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'blocto',
    },
    {
      name: 'initEmail',
      displayName: {
        en: 'Init email',
        ja: 'Init email',
      },
      type: 'text',
      default: '',
    },
    {
      name: 'primaryColor',
      displayName: {
        en: 'Button Color',
        ja: 'Button Color',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Connect success',
        ja: 'Connect success',
      },
    },
  ],
  childComponents: [
    {
      name: 'button',
      displayName: {
        en: 'Button',
        ja: 'Button',
      },
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Connect',
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'add',
        },
      ],
    },
  ],
};
