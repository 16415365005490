import { useCallback, useState } from 'react';
import api from '@common/configs/api';
import {
  CameraType,
  LIVE_STREAM_ACTION_TYPES,
} from '@common/constants/livestream.constant';
import { AxiosResponse } from 'axios';
import { ILiveStreamDetail, StreamInfo } from '@common/types';
import { useDispatch, useSelector } from 'react-redux';
import { setLoseConnect } from '@common/redux/slice/liveStream';
import { Alert, Platform } from 'react-native';
import { appLanguageSelector } from '@common/redux/selectors/language';
import { appInfoSelector } from '@common/redux/selectors/app';
import { setValueInput } from '@common/redux/slice/formInputs';
import { getValueFields } from '@common/redux/selectors/formInputs';

const useLiveStream = (attributes?: any) => {
  const dispatch = useDispatch();
  const { locale } = useSelector(appLanguageSelector);
  const [cameraType, setCameraType] = useState<CameraType>('back');
  const [muteVoice, setMuteVoice] = useState(false);
  const [streaming, setStreaming] = useState<boolean>(false);
  const formInputValues = useSelector(getValueFields);

  const appInfo = useSelector(appInfoSelector);

  const getLiveStream = useCallback(
    async ({ streamId, accessToken, userRecordId }) => {
      try {
        const res: AxiosResponse<ILiveStreamDetail> = await api({
          method: 'post',
          url: `/livestreams`,
          data: {
            streamId,
            userRecordId,
            accessToken,
            appId: appInfo?.id,
            Action: LIVE_STREAM_ACTION_TYPES.GetStreamUrl,
          },
        });
        return res.data;
      } catch (error) {
        console.log('=== getLivestream error', streamId, error);
        if (Platform.OS === 'web') {
          alert(
            ' フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
          );
        } else {
          Alert.alert(
            '',
            'フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
          );
        }
      }
    },
    []
  );

  const getLiveStreamDetail = useCallback(async ({ streamId }) => {
    try {
      const res: AxiosResponse<StreamInfo> = await api({
        method: 'get',
        url: `/livestreams/${streamId}`,
        params: {
          appId: appInfo?.id,
        },
      });
      return res.data;
    } catch (err) {
      if (Platform.OS === 'web') {
        alert(
          ' フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
        );
      } else {
        Alert.alert(
          '',
          'フォローができませんでした。電波状況が良好なエリアで再度実行してください。'
        );
      }
    }
  }, []);

  const saveStreamingVideo = useCallback(
    async ({ streamId, accessToken, userRecordId }) => {
      try {
        const res: AxiosResponse<ILiveStreamDetail> = await api({
          method: 'post',
          url: `/livestreams`,
          data: {
            streamId,
            userRecordId,
            accessToken,
            appId: appInfo?.id,
            Action: LIVE_STREAM_ACTION_TYPES.CreateLiveStreamRecordIndexFiles,
          },
        });
        return res.data;
      } catch {}
    },
    []
  );

  const createLiveStreamNew = useCallback(
    async ({ infoUser, title }) => {
      try {
        const { accessToken, ...owner } = infoUser;
        const metadata = {
          title,
        };
        const res: AxiosResponse<ILiveStreamDetail> = await api({
          method: 'post',
          url: `/livestreams`,
          data: {
            accessToken: infoUser.accessToken,
            appId: appInfo?.id,
            userRecordId: infoUser.id,
            title,
            metadata,
            Action: LIVE_STREAM_ACTION_TYPES.CreateIngestUrl,
          },
        });

        return res.data;
      } catch (err: any) {
        let errorMsg =
          '配信を開始できませんでした。電波状況が良好なエリアで再度実行してください。';
        try {
          if (err?.response?.data?.data[locale]) {
            errorMsg = err?.response?.data?.data[locale];
          }
        } finally {
          if (Platform.OS === 'web') {
            alert(errorMsg);
          } else {
            Alert.alert(
              '',
              errorMsg,
              [
                {
                  text: 'はい',
                  onPress: () => {
                    handleFinishLive();
                    attributes.onPress();
                  },
                },
              ],
              {
                cancelable: false,
              }
            );
          }
        }
      }
    },
    [attributes]
  );

  const pushView = useCallback(async ({ infoUser, streamId }) => {
    try {
      const { accessToken, ...owner } = infoUser;
      const res: AxiosResponse<ILiveStreamDetail> = await api({
        method: 'post',
        url: `/livestreams`,
        data: {
          streamId,
          appId: appInfo?.id,
          accessToken: infoUser.accessToken,
          userRecordId: infoUser.id,
          Action: LIVE_STREAM_ACTION_TYPES.IncrementView,
        },
      });

      return res.data;
    } catch (err) {
      console.error('pushView ERROR', err);
    }
  }, []);

  const getMoreVideos = useCallback(async ({ appId, videos }) => {
    try {
      const res: AxiosResponse<ILiveStreamDetail> = await api({
        method: 'get',
        url: `/livestreams?appId=${appId}&next=[${videos}]`,
      });
      return res.data;
    } catch (err) {}
  }, []);

  const stopLiveStream = useCallback(async ({ infoUser, streamId }) => {
    try {
      const res: AxiosResponse<ILiveStreamDetail> = await api({
        method: 'post',
        url: `/livestreams`,
        data: {
          accessToken: infoUser.accessToken,
          appId: appInfo?.id,
          streamId: streamId,
          userRecordId: infoUser.id,
          Action: LIVE_STREAM_ACTION_TYPES.StopLiveStream,
        },
      });
      return res?.data;
    } catch (err: any) {}
  }, []);

  const changeValue = useCallback(
    (value: Record<string, number | string>) => {
      dispatch(
        setValueInput({
          [attributes.id]: {
            ...(formInputValues[attributes.id] || {}),
            ...value,
          },
        })
      );
    },
    [dispatch, attributes, formInputValues]
  );

  const convertTimeLive = useCallback((timeLiveStream) => {
    let h = 0;
    let m = 0;
    let s = 0;

    let mTemp = 0;

    mTemp = Math.floor(timeLiveStream / 60);

    h = Math.floor(mTemp / 60);
    m = mTemp % 60;
    s = timeLiveStream % 60;

    const convertTime = (n: number) => (n < 10 ? `0${n}` : n);

    return `${convertTime(h)}:${convertTime(m)}:${convertTime(s)}`;
  }, []);

  const handleFinishLive = useCallback(() => {
    setStreaming(false);
    dispatch(setLoseConnect(false));
  }, []);

  const handleStopLiveStream = useCallback(() => {
    setStreaming(false);
  }, [dispatch]);

  const changeCameraType = useCallback(
    (cameraType) => {
      if (cameraType === 'front') {
        setCameraType('back');
      } else {
        setCameraType('front');
      }
    },
    [setCameraType]
  );

  const onMuteVoice = useCallback(() => {
    setMuteVoice((prev) => !prev);
  }, [setMuteVoice]);

  return {
    locale,
    dispatch,
    pushView,
    streaming,
    muteVoice,
    cameraType,
    changeValue,
    onMuteVoice,
    setStreaming,
    getMoreVideos,
    setCameraType,
    getLiveStream,
    stopLiveStream,
    convertTimeLive,
    changeCameraType,
    handleFinishLive,
    saveStreamingVideo,
    getLiveStreamDetail,
    createLiveStreamNew,
    handleStopLiveStream,
    appInfo,
  };
};

export default useLiveStream;
