import axiosInstance from '@common/services/axiosIntance/axiosService';
import { Platform } from 'react-native';

export type BuildV2Output = {
  schemeDeepLinkApp: string;
};
export const useLineLoginAction = () => {
  const getLatestBuildApp = async (
    appId: string
  ): Promise<BuildV2Output | null> => {
    return await axiosInstance({
      method: 'GET',
      url: 'build-v2-output/app',
      params: {
        appId,
        platform: Platform.OS,
      },
    })
      .then((r) => r.data)
      .catch((e) => {
        return null;
      });
  };

  return { getLatestBuildApp };
};
export type LineLoginActionType = ReturnType<typeof useLineLoginAction>;
