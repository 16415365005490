import { IPage } from '@common/types/element';
import { Platform, StatusBar as StatusBarRN, StyleSheet } from 'react-native';

const createStyle = (screen: IPage) => {
  return StyleSheet.create({
    container: {
      backgroundColor: screen?.attributes?.backgroundColor,
      flex: 1,
      overflow: 'hidden',
    },
    safeViewContainer: {
      flex: 1,
      ...(Platform.OS === 'ios'
        ? { marginTop: StatusBarRN.currentHeight }
        : {}),
    },
  });
};

export default createStyle;
