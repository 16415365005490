import { Dimensions, StyleSheet } from 'react-native';
const createStyles = () => {
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  return StyleSheet.create({
    webContainer: {
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    imageContainer: {
      width: 236,
      height: 154,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#cdd6dd',
      marginBottom: 30,
      borderRadius: 3,
    },
    previewImageContainer: {
      width: 236,
      height: 154,
    },
    previewImage: {
      width: 236,
      height: 154,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
    },
    videoContainer: {
      width: windowWidth,
      top: '30%',
      position: 'absolute',
      backgroundColor: '#cdd6dd',
    },
    input: {
      height: 38,
      width: 271,
      margin: 8,
      borderWidth: 1,
      borderRadius: 10,
      textAlign: 'center',
      marginBottom: 50,
    },
    buttonLive: {
      padding: 15,
      height: 59,
      width: 279,
      borderRadius: 25,
      backgroundColor: '#fca138',
      textAlign: 'center',
    },
    buttonEnd: {
      padding: 3,
      height: 25,
      width: 61,
      borderRadius: 10,
      backgroundColor: '#fff',
      textAlign: 'center',
    },
    textButton: {
      color: '#fff',
      fontSize: 20,
    },
    textBold: {
      fontWeight: '600',
      color: '#fff',
      fontSize: 12,
    },
    textViewer: {
      fontWeight: '600',
      color: '#fff',
      fontSize: 12,
      marginLeft: 3,
    },
    timeCount: {
      color: '#fff',
      marginLeft: 20,
      marginBottom: 5,
      fontWeight: '600',
      fontSize: 12,
    },
    containerViewer: {
      padding: 9,
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#a1a3a4',
      borderRadius: 5,
      width: 228.47,
      height: 33,
    },
    imageBottom: {
      width: 124,
      height: 135,
      justifyContent: 'center',
      backgroundColor: '#bac0c4',
      borderRadius: 10,
      borderWidth: 2,
      borderColor: '#ffd798',
      marginLeft: 5,
    },
    viewLiveStream: {
      width: windowWidth,
      height: windowHeight,
      backgroundColor: '#000000',
    },
    viewLiveStreamControl: {
      position: 'absolute',
      left: 5,
      top: 20,
      zIndex: 999,
      width: '80%',
      height: '10%',
    },
    viewLiveStreamUserInfo: {
      width: 166,
      height: 45,
      backgroundColor: '#b8c0c7',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 5,
      padding: 8,
    },
    viewLiveStreamText: {
      fontSize: 10,
      fontWeight: 'bold',
      color: '#fff',
    },
    viewLiveStreamButton: {
      backgroundColor: '#ffbc00',
      borderRadius: 10,
      alignContent: 'center',
      padding: 4,
    },
    viewLiveStreamViewer: {
      top: 5,
      backgroundColor: '#b8c0c7',
      width: 78,
      height: 19,
      borderRadius: 10,
      justifyContent: 'space-around',
      flexDirection: 'row',
      alignItems: 'center',
    },
    viewLiveStreamTextViewer: {
      fontSize: 10,
      fontWeight: 'bold',
      color: '#fff',
      paddingRight: 15,
    },
    viewLiveStreamButtonBack: {
      position: 'absolute',
      right: 20,
      top: 20,
      zIndex: 999,
    },
  });
};

export default createStyles;
