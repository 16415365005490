import { IAction } from '@common/types/';
import { MetadataAction } from '@common/types/action';
import { get, isEmpty } from 'lodash';
import { Alert, Linking, Platform } from 'react-native';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';
import { ActionResponse } from '../excuteAction';
import { ActionStatus } from '@common/constants/shared';

const isMobile = ['tablet', 'mobile'];

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

export const externalLink: (
  action: IAction,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (action: IAction, metadata) => {
  try {
    const { actionItem = {} } = metadata;
    const listSchemeLink: any[] = [];
    const url = get(action, 'response.value', '');

    if (typeof url !== 'string')
      return {
        status: ActionStatus.FAILED,
      };
    switch (actionItem.type) {
      case 'tel':
        if (url.trim()) {
          const regExCheckingNum = /^[0-9 + -]*$/;
          const checkingNum = regExCheckingNum.test(url.trim());
          const isSupported = await Linking.canOpenURL(`tel:${url.trim()}`);
          if (isSupported && checkingNum) {
            Platform.OS === 'web'
              ? window.open(`tel://${url.trim()}`, '_top')
              : await Linking.openURL(`tel:${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          }
        }
        break;

      case 'sms':
        if (url.trim()) {
          const validRegex =
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
          const validSms = validRegex.test(url);
          const isSupported = await Linking.canOpenURL(`sms:${url.trim()}`);
          if (isSupported && validSms) {
            Platform.OS === 'web'
              ? window.open(`sms:${url.trim()}`, '_top')
              : await Linking.openURL(`sms:${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          }
        }
        break;

      case 'mailto':
        if (url.trim()) {
          const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          const validMail = validRegex.test(url);
          const isSupported = await Linking.canOpenURL(`mailto:${url.trim()}`);
          if (isSupported && validMail) {
            Platform.OS === 'web'
              ? window.open(`mailto:${url.trim()}`, '_top')
              : await Linking.openURL(`mailto:${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          }
        }
        break;

      case 'deeplink':
        if (url.trim()) {
          const pattern = /^((http|https|www):\/\/)/;
          const patternScheme = /^(.*?)\:\/\//;
          const scheme = url.split(patternScheme)[1];
          const isScheme =
            !isEmpty(listSchemeLink) && listSchemeLink.includes(scheme);
          const isContains = pattern.test(url);
          if (isContains || !isScheme) {
            Alert.alert(
              'Something went wrong',
              `Something went wrong when open this URL: ${url.trim()} `
            );
            break;
          }
          const isSupported = await Linking.canOpenURL(`${url.trim()}`);
          if (isSupported && !isContains && isScheme) {
            await Linking.openURL(`${url.trim()}`);
            return {
              status: 'SUCCEED',
            };
          } else {
            Alert.alert(
              'Something went wrong',
              `Don't know how to open this URL: ${url.trim()}`
            );
          }
        } else {
          Alert.alert('Something went wrong', "Don't have any url to open");
        }
        break;
      default:
        const expression =
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);

        const pattern = /^((http|https):\/\/)/;
        const isContains = pattern.test(url);
        let _url = '';
        if (url) {
          if (isContains && url && url.match(regex)) {
            _url = `${url}`;
          } else {
            const convertUrl = 'http://' + url;
            _url = convertUrl;
          }

          try {
            if (Platform.OS === 'web') {
              const deviceType = getDeviceType();
              const available = await Linking.canOpenURL(_url);

              if (available) {
                await Linking.openURL(_url);
                isMobile.includes(deviceType) && window.open(_url, '_top');
              }
            } else {
              if (await InAppBrowser.isAvailable()) {
                InAppBrowser.close();
              }
              await InAppBrowser.open(_url);
            }
          } catch (err) {
            console.log('err', err);
            await Linking.openURL(_url);
          }

          return {
            status: 'SUCCEED',
          };
        }
        break;
    }

    return {
      status: 'FAILED',
    };
  } catch (err) {
    console.log('err', err);

    return {
      status: 'FAILED',
    };
  }
};
