export default {
  name: 'CustomButton',
  displayName: {
    en: 'Button',
    ja: 'ボタン',
  },
  icon: './icon.png',
  defaultWidth: 155,
  defaultHeight: 25,
  resizeX: true,
  resizeY: true,
  props: [
    // {
    //   name: 'visibility',
    //   displayName: {
    //     en: 'Will be visible only when...',
    //     ja: '以下の条件で表示',
    //   },
    //   type: 'visibility',
    // },
    {
      name: 'type',
      displayName: {
        en: 'Type',
        ja: 'タイプ',
      },
      type: 'text',
      default: 'contained',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.button.type.text',
            value: 'text',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.outline',
            value: 'outlined',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.button',
            value: 'contained',
          },
        ],
      },
    },
    {
      name: 'text',
      displayName: {
        en: 'Text',
        ja: 'テキスト',
      },
      type: 'text',
      default: 'Button',
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'icon',
      displayName: {
        en: 'Icon',
        ja: 'アイコン',
      },
      type: 'icon',
      default: 'add',
    },
    {
      name: 'primaryColor',
      displayName: {
        en: 'Button Color',
        ja: 'Button Color',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'contrastColor',
      displayName: {
        en: 'Icon & Text Color',
        ja: 'アイコンの色',
      },
      type: 'color',
      default: '@contrast:primaryColor',
      enabled: {
        type: 'contained',
      },
    },
    {
      name: 'upperCase',
      displayName: {
        en: 'Uppercase',
        ja: '大文字',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Button',
        ja: 'ClickFlow',
      },
    },
  ],
  // childComponents: [
  //   {
  //     name: 'replaceButton',
  //     displayName: {
  //       en: 'Replace Button',
  //       ja: 'Replace Button',
  //     },
  //     role: 'item',
  //     reference: 'visibility',
  //     props: [
  //       {
  //         name: 'enabled',
  //         type: 'boolean',
  //         default: false,
  //       },
  //       {
  //         name: 'preview',
  //         displayName: {
  //           en: 'Preview',
  //           ja: 'Preview',
  //         },
  //         type: 'boolean',
  //         default: false,
  //       },
  //       {
  //         name: 'type',
  //         displayName: {
  //           en: 'Type',
  //           ja: 'タイプ',
  //         },
  //         type: 'text',
  //         default: 'contained',
  //         control: {
  //           type: 'menu',
  //           options: [
  //             {
  //               label: 'canvas.menuRight.elementTab.button.type.text',
  //               value: 'text',
  //             },
  //             {
  //               label: 'canvas.menuRight.elementTab.button.text.outline',
  //               value: 'outlined',
  //             },
  //             {
  //               label: 'canvas.menuRight.elementTab.button.text.button',
  //               value: 'contained',
  //             },
  //           ],
  //         },
  //       },
  //       {
  //         name: 'text',
  //         displayName: {
  //           en: 'Text',
  //           ja: 'テキスト',
  //         },
  //         type: 'text',
  //         default: 'Button',
  //       },
  //       {
  //         name: 'icon',
  //         displayName: {
  //           en: 'Icon',
  //           ja: 'アイコン',
  //         },
  //         type: 'icon',
  //         default: 'add',
  //       },
  //       {
  //         name: 'backgroundColor',
  //         displayName: 'Background Color',
  //         type: 'color',
  //         default: '#34A0DD',
  //       },
  //       {
  //         name: 'upperCase',
  //         displayName: {
  //           en: 'Uppercase',
  //           ja: '大文字',
  //         },
  //         type: 'boolean',
  //         default: true,
  //       },
  //       {
  //         name: 'action',
  //         type: 'action',
  //         displayName: {
  //           en: 'Button',
  //           ja: 'ClickFlow',
  //         },
  //       },
  //     ],
  //   },
  // ],
};
