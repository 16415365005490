import { get, isEqual, isUndefined } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { View, Platform } from 'react-native';
import { BottomNavigation, IconButton, Text } from 'react-native-paper';
import { ITabNavigator } from '../../types';
import PointIcon from '../CustomIcon';
import { getActions, getValueBinding, isCheckColor } from '../shared';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import queryString from 'query-string';
const hasPaddingBottom = ['iphonePro', 'iphoneProMax', 'iphone11'];
const tabKeys = ['tab0', 'tab1', 'tab2', 'tab3', 'tab4'];

const TabNavigator: FC<ITabNavigator> = (props) => {
  const { originX, attributes, onPress, devicePrev, data = {} } = props;
  const { activeTab } = attributes;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const isCanvas = !target && Platform.OS === 'web';

  const newOriginX = originX < 1.5 ? originX : 1;
  const isItemList = !!props?.record;

  const bindingValue = getValueBinding(
    isItemList ? props.initId : props.id,
    isItemList ? props?.record : data,
    props
  );

  const [index, setIndex] = useState<number>(0);
  const tabs = tabKeys.map((key: string) => {
    const tabValue = get(attributes, key);
    return {
      ...tabValue,
      label: get(bindingValue, `${key}.label`),
    };
  });

  const handlePress = (id: string) => {
    const arrayAction = getActions(props, id) || [];
    onPress(arrayAction, {});
  };

  const routes = useMemo(() => {
    return tabs
      .filter((o) => o.enabled || isUndefined(o.enabled))
      .map((item, index) => {
        const tabIndex = [...tabs].findIndex((e) => isEqual(e, item));
        return {
          key: 'tab' + tabIndex,
          title: item.label,
          icon: item.icon,
          tab: 'tab' + tabIndex,
        };
      });
  }, [tabs]);

  const handleOnTabPress = ({ route }: Record<string, any>) => {
    if (!onPress) return;
    handlePress(route.tab);
  };

  const listComponent = useMemo(() => {
    const result: Record<string, any> = {};
    routes.forEach((item) => {
      result[item.tab] = () => (
        <View>
          <Text style={{ fontSize: attributes.fontSize * newOriginX || 14 }}>
            {!window?.location?.origin?.includes('http') ? item.title : ''}
          </Text>
        </View>
      );
    });

    return result;
  }, [routes]);

  const renderScene = () => {
    if (isCanvas) return;
    return BottomNavigation.SceneMap({
      ...listComponent,
    });
  };

  const indexActive = routes.length - 1 < index ? 0 : index;

  const renderLabel = (props: {
    route: Record<string, any>;
    color: string;
  }) => {
    return (
      <Text
        style={{
          fontSize: attributes.fontSize * newOriginX || 14,
          color: props.color,
          textAlign: 'center',
          marginBottom: -5,
        }}
        numberOfLines={1}
      >
        {props.route.title}
      </Text>
    );
  };

  const renderIcon = (props: {
    route: Record<string, any>;
    focused: boolean;
    color: string;
  }) => {
    if (props.route.icon === 'point') {
      return <PointIcon width={24} height={24} fill={props.color} />;
    }

    return (
      <IconButton
        style={{
          justifyContent: 'space-between',
          margin: 0,
        }}
        size={24}
        icon={props.route.icon}
        color={props.color}
      />
    );
  };

  useEffect(() => {
    const indexTab = routes.findIndex((o) => o.tab === 'tab' + activeTab);
    if (indexTab !== -1) setIndex(indexTab);
    else setIndex(0);
  }, [routes, activeTab]);

  return (
    <SafeAreaInsetsContext.Consumer>
      {(insets) => {
        return (
          <BottomNavigation
            navigationState={{
              index: indexActive,
              routes: routes,
            }}
            shifting={false}
            renderIcon={renderIcon}
            onIndexChange={setIndex}
            renderScene={renderScene}
            renderLabel={renderLabel}
            onTabPress={handleOnTabPress}
            activeColor={
              isCheckColor(attributes.activeColor)
                ? attributes.activeColor
                : '#FFF'
            }
            inactiveColor={
              isCheckColor(attributes.inactiveColor)
                ? attributes.inactiveColor
                : '#bbb'
            }
            style={{
              opacity: attributes.opacity,
              borderTopColor: isCheckColor(attributes.borderColor)
                ? attributes.borderColor
                : '#000',
              borderTopWidth:
                attributes.borderStyle === 'none' ? 0 : attributes.borderWidth,
              borderStyle:
                attributes.borderStyle === 'none'
                  ? undefined
                  : attributes.borderStyle,
            }}
            barStyle={{
              backgroundColor: isCheckColor(attributes.backgroundColor)
                ? attributes.backgroundColor
                : '#4259AC',
              paddingBottom: insets?.bottom,
            }}
          />
        );
      }}
    </SafeAreaInsetsContext.Consumer>
  );
};

export default TabNavigator;
