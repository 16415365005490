import { useEffect, useState } from 'react';
import io from '@common/services/featherIO/feathers.io';
import { useDispatch } from 'react-redux';
import {
  checkAuthError,
  checkAuthSuccess,
  signOut,
} from '@common/redux/slice/auth';

const useSocketConnection = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await io
        .reAuthenticate()
        .then((data) => {
          const { userPreview: auth, accessToken } = data;
          if (auth) {
            console.log('Check Auth Success!');
            dispatch(
              checkAuthSuccess(Object.assign({}, auth, { accessToken }))
            );
          } else {
            console.log('Check Auth Error: Default Token!');
            dispatch(checkAuthError());
          }
        })
        .catch(async (error) => {
          await io.logout();
          dispatch(checkAuthError());
          console.log('Check Auth Error: ', error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [dispatch]);

  useEffect(() => {
    io.on('authFailure', () => {
      dispatch(signOut({}));
      io.logout();
    });

    return () => {
      io.removeListener('authFailure', () => {});
    };
  }, [dispatch]);

  return {
    loading,
  };
};

export default useSocketConnection;
