import React, { useState, useEffect, useRef, useMemo } from 'react';
import { View, FlatList, ActivityIndicator, Platform } from 'react-native';
import queryString from 'query-string';

import { IHorizontalCardList } from '@nocode/types';
import CardItem from './CardItem';
import { getItemList } from '../CardList/func';
import { isEmpty, get } from 'lodash';
import EmptyList from '../EmptyList';
import Loading from '../Loading';

export const HORIZONTAL_CARD_LIST = 'horizontalCardList';

export type CardDetail = {
  title: string;
  subTitle: string;
  subTitle2: { icon: string; text: string };
  tag: { icon: string; text: string };
  footer: string;
  _id: string;
};

const HorizontalCardList: React.FC<IHorizontalCardList> = (attribute) => {
  const { page = 1, totalPage, onLoadMore } = attribute;

  const flatListRef: any = useRef();
  const { initializeList } = attribute;

  const [heightCard, setHeightCard] = useState<number>();

  const cardItemsData: any = useMemo(() => {
    return getItemList(attribute, HORIZONTAL_CARD_LIST);
  }, [attribute]);

  const tableId = get(attribute, 'attributes.items.source.tableId');

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;

  if (target && !tableId && !attribute?.isLoadMore)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attribute.zIndex,
        }}
      >
        <EmptyList attributes={attribute} />
      </View>
    );

  const elementId = 'flat-list' + '-' + attribute.id;

  useEffect(() => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });

    if (Platform.OS !== 'web') return;

    const flatListId = document.getElementById(elementId);

    setHeightCard(flatListId?.clientHeight);
  }, [elementId]);

  useEffect(() => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0, x: 0 });

    if (attribute?.hasAction) {
      // attribute.setLoadMore && attribute.setLoadMore(1);
    }
  }, [attribute?.hasAction]);

  const getMargin = () => {
    const shadowSize = attribute?.attributes.shadow?.size || 0;
    const shadowX = attribute?.attributes.shadow?.x || 0;
    const shadowY = attribute?.attributes.shadow?.y || 0;
    const shadowBlur = attribute?.attributes.shadow?.blur || 0;
    if (!shadowBlur || !shadowSize) {
      return;
    }
    const getOffSet = (number: number) => (number > 0 ? number : 0);

    return {
      marginBottom: getOffSet(shadowSize + shadowY),
      marginTop: getOffSet(shadowSize - shadowY),
      marginLeft: getOffSet(shadowSize - shadowX),
      marginRight: getOffSet(shadowSize + shadowX),
    };
  };

  const cardMargin = getMargin();

  const onEndReached = () => {
    if (attribute?.isLoadMore || page >= totalPage) return;
    onLoadMore && onLoadMore();
  };

  return initializeList ? (
    <Loading />
  ) : (
    <FlatList
      nativeID={elementId}
      data={cardItemsData}
      horizontal
      // pagingEnabled={Platform.OS === 'web'}
      scrollEnabled
      snapToAlignment="start"
      scrollEventThrottle={16}
      decelerationRate={'normal'}
      bounces={false}
      showsHorizontalScrollIndicator={false}
      ref={flatListRef}
      onEndReachedThreshold={0.1}
      ItemSeparatorComponent={({ highlighted }) => (
        <View
          style={[
            { marginLeft: attribute.attributes.gap },
            highlighted && { marginLeft: 0 },
          ]}
        />
      )}
      {...(attribute?.isLoadMore && {
        ListFooterComponent: (
          <View
            style={{
              display: 'flex',
              height: attribute.height,
              justifyContent: 'center',
            }}
          >
            <ActivityIndicator size="small" color="#1976d2" />
          </View>
        ),
      })}
      onEndReached={onEndReached}
      renderItem={({ item, index }) => (
        <CardItem
          isLast={cardItemsData?.length === index + 1}
          index={index}
          cardItem={item}
          obj={attribute}
          onPress={attribute?.onPress}
          heightCard={heightCard}
          cardMargin={cardMargin}
        />
      )}
      keyExtractor={(item, index) => `${(item?._id, index)}`}
    />
  );
};

export default HorizontalCardList;
