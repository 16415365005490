import LineIcon from '@nocode/components/LineLogin/LineIcon';
import createStyles from '@nocode/components/LineLogin/style';
import { ILineButton } from '@nocode/types';
import { get } from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Linking,
  Platform,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';
import Config from 'react-native-config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getActions, getValueBinding } from '../shared';
import { BuildV2Output } from '@common/hooks/useLineLoginAction';

const LineLogin: React.FC<ILineButton> = (props) => {
  const lineTokenUrl = 'https://access.line.me/oauth2/v2.1/authorize';
  const {
    attributes: { socialConfig, textError },
    dependencies,
    id,
    data,
    getLatestBuildApp,
  } = props;
  const appInfor = get(dependencies, 'appInfor');

  const [latestBuildApp, setLatestBuildApp] = useState<BuildV2Output | null>(
    null
  );

  const styles = createStyles(props.attributes);
  const currentUrl = window?.location?.href?.toString();

  if (
    !currentUrl?.includes('canvas') &&
    (!socialConfig || !socialConfig.clientId || !socialConfig.clientSecret)
  ) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>{textError?.en}</Text>
      </View>
    );
  }
  const bindingValue = getValueBinding(id, data, props);

  const onPressLine = async () => {
    try {
      const actionId = getActions(props, 'action');

      if (!actionId) return;

      await AsyncStorage.setItem(
        'penddingActions',
        JSON.stringify({
          objectId: props.id,
          actionId,
          arrayAction: props.actions[actionId]?.actions,
          screenUuid: props?.screenUuid,
        })
      );

      if (Platform.OS === 'web') {
        const isLineBrowser = navigator.userAgent.indexOf('Line/') !== -1;
        if (!isLineBrowser) {
          let currentUrl = location.href;
          const url = `${lineTokenUrl}?response_type=code&client_id=${socialConfig?.clientId}&redirect_uri=${socialConfig?.redirectUri}&state=${currentUrl}&scope=${socialConfig?.scope}`;
          Linking.openURL(url);
          return;
        }
        let currentUrl = location.pathname;

        const url = `${lineTokenUrl}?response_type=code&client_id=${socialConfig?.clientId}&redirect_uri=${socialConfig?.redirectUri}&state=${currentUrl}&scope=${socialConfig?.scope}`;
        Linking.openURL(url);
      } else {
        const scheme =
          Config.SCHEME_DEEP_LINK_APP || latestBuildApp?.schemeDeepLinkApp;

        const url = `${lineTokenUrl}?response_type=code&client_id=${socialConfig?.clientId}&redirect_uri=${socialConfig?.redirectUri}&state=${scheme}&scope=${socialConfig?.scope}`;

        Linking.openURL(url);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      getLatestBuildApp(appInfor.appId).then(setLatestBuildApp);
    }
  }, []);

  return (
    <TouchableHighlight onPress={onPressLine}>
      <View style={styles.container}>
        <LineIcon />
        <Text style={styles.text}>{get(bindingValue, 'text')}</Text>
      </View>
    </TouchableHighlight>
  );
};

export default LineLogin;
