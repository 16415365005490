import { loadApp } from '@common/redux/slice/app';
import { setCollections } from '@common/redux/slice/database';
import { fetchApp, fetchCollections } from '@common/services';
import { IAppInfo } from '@common/types';
import { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-native';
import Config from 'react-native-config';
import { useDispatch } from 'react-redux';
type Props = {
  appId: string;
  isShareScreen?: boolean;
};

type APIResponse<T> = {
  data: T | undefined;
  error: string | null;
  isLoading: boolean;
};

const useAppDetail = ({ appId, isShareScreen }: Props) => {
  const dispatch = useDispatch();

  const [response, setResponse] = useState<APIResponse<IAppInfo>>({
    data: undefined,
    error: null,
    isLoading: true,
  });

  const _fetchData = useCallback(async () => {
    setResponse({ ...response, ...{ isLoading: true } });
    try {
      const appInfor = await fetchApp(appId);

      const collections = await fetchCollections(appId);

      dispatch(
        loadApp({
          ...appInfor,
          APP_VERSION: Config?.APP_VERSION,
          APP_NAME: Config?.APP_NAME,
        })
      );

      dispatch(setCollections(collections));
      setResponse({ ...response, ...{ isLoading: false, data: appInfor } });
    } catch (error) {
      Alert.alert(
        '',
        'アプリが起動できません。電波状況が良好なエリアで再度実行してください。'
      );
      setResponse({
        ...response,
        ...{ isLoading: false, error: 'get data error' },
      });
    }
  }, [isShareScreen, appId]);

  useEffect(() => {
    _fetchData();
  }, [_fetchData]);

  return { ...response };
};

export default useAppDetail;
