export default {
  name: 'RankingList',
  displayName: {
    en: 'RankingList',
    ja: 'プラグイン名',
  },
  icon: 'example-thumbnail.png',
  defaultWidth: 375,
  defaultHeight: 400,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },

    {
      name: 'color',
      displayName: {
        en: 'color',
        ja: 'color',
      },
      type: 'color',
      default: 'black',
    },
    {
      name: 'classify',
      displayName: {
        en: 'Ranking classify',
        ja: 'ランキング分類',
      },
      type: 'boolean',
      default: false,
      control: {
        type: 'menu',
        options: [
          {
            label: 'Gilfter',
            value: true,
          },
          {
            label: 'Liver',
            value: false,
          },
        ],
      },
    },
    {
      name: 'backgroundColor',
      type: 'color',
      displayName: {
        en: 'BackgroundColor',
        ja: 'BackgroundColor',
      },
      default: '#fff',
    },
    {
      name: 'onPress',
      displayName: {
        en: 'Button',
        ja: 'Button',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
  ],
  childComponents: [
    {
      name: 'title',
      displayName: { en: 'Title', ja: 'タイトル' },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'text',
          default: 'Title',
        },
      ],
    },
    {
      name: 'subTitle',
      displayName: { en: 'Content', ja: 'コンテンツ' },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'text',
          default: 'Content',
        },
      ],
    },
    {
      displayName: {
        en: 'Avatar Image',
        ja: 'アバター画像',
      },
      name: 'avatarImage',
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'image',
          displayName: {
            en: 'Image Source',
            ja: '画像ソース',
          },
          type: 'image',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
      ],
    },
  ],
};
