import appConfig from '../../appConfig.json';

const API_BASE_URL = appConfig.baseUrl;

export const STAMP_BACKBOARD_OPTIONS = [
  {
    name: 'default',
    placeholder: `${API_BASE_URL}/digishot/digishot/shotview/r/default/koto/base01.png`,
    fileType: 'image',
  },
  {
    name: 'mikosea',
    placeholder:
      'https://mikosea.s3.ap-northeast-1.amazonaws.com/common/stamp_backboard.png',
    fileType: 'image',
  },
];

export const STAMP_IMPRINT_OPTIONS = [
  {
    name: 'default',
    placeholder: `${API_BASE_URL}/digishot/digishot/shotview/r/default/koto/imprint01.png`,
    fileType: 'image',
  },
  {
    name: 'mikosea',
    placeholder: 'https://mikosea.io/imprint_mikosea01.png',
    fileType: 'image',
  },
  {
    name: 'ok-video',
    placeholder:
      'https://mikosea.s3.ap-northeast-1.amazonaws.com/common/imprint01.mp4',
    fileType: 'video',
  },
];
