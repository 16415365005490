import { get, isEmpty } from 'lodash';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { getActions, getItemListClick, getValueBinding } from '../../shared';
import AvatarImage from './AvatarImage';
import useAvatar, { Props, ReceivedProps } from './hook';

const AvatarItemLayout: React.FC<Props> = (props) => {
  const {
    attrs,
    getTextStyles,
    styles,
    urlImage,
    titleText,
    subTitleText,
    item,
    index,
    onPress,
    placeholderImage,
    isCanvas,
  } = props;
  const { attributes } = attrs;

  const { title, cardCover, opacity, backgroundColor, subTitle } = attributes;
  const {
    borderColor,
    borderStyle,
    borderWidth,
    borderRadius,
    size,
    image,
    backgroundColor: backgroundImage,
    // onPress,
  } = cardCover || {};

  const { enabled = false, titleLineNum, position = 'bottom' } = title || {};
  const {
    enabled: subTitleEnabled = false,
    subTitleLineNum,
    backgroundColor: backgroundColorSubTitle,
    borderRadius: borderRadiusSubTitle,
  } = subTitle || {};
  const lineHeightSubTitle =
    borderRadiusSubTitle >= subTitle?.fontSize * 1.15
      ? subTitle?.fontSize * 1.15
      : borderRadiusSubTitle;

  const handlePress = (id: string, item: Record<string, any>) => {
    const options = {
      itemListClick: getItemListClick(item.record),
    };

    onPress && onPress(getActions(attrs, id), options);
  };

  const bindingValue = getValueBinding('', item, attrs);

  return (
    <TouchableOpacity
      onPress={() => {
        handlePress('onPress', {
          record: item,
          indexRecord: index,
        });
      }}
    >
      <View
        style={[
          {
            opacity,
            backgroundColor,
            flexDirection: position === 'top' ? 'column-reverse' : 'column',
            marginRight: attributes.gap,
            width: size,
          },
          styles.cardWrapper,
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            handlePress('cardCover', {
              record: item,
              indexRecord: index,
            });
          }}
        >
          <AvatarImage
            borderColor={borderColor}
            borderRadius={borderRadius}
            backgroundImage={backgroundImage}
            borderStyle={borderStyle}
            borderWidth={borderWidth}
            size={size}
            url={urlImage}
            imageResize={image?.imageResize || 'cover'}
            styles={styles}
            placeholderImage={placeholderImage}
          />
        </TouchableOpacity>
        <View>
          {enabled && !isEmpty(titleText) && (
            <Text
              style={[
                {
                  ...getTextStyles(title),
                },
                styles.cardContent,
              ]}
              numberOfLines={title && titleLineNum > 1 ? 500 : 1}
              onPress={() => {
                handlePress('title', {
                  record: item,
                  indexRecord: index,
                });
              }}
            >
              {isCanvas ? titleText : get(bindingValue, 'title.text')}
            </Text>
          )}

          {subTitleEnabled && !isEmpty(subTitleText) && (
            <Text
              style={[
                {
                  ...getTextStyles(subTitle),
                  backgroundColor: backgroundColorSubTitle,
                  borderRadius: lineHeightSubTitle,
                  overflow: 'hidden',
                },
                styles.cardContent,
              ]}
              numberOfLines={subTitle && subTitleLineNum > 1 ? 500 : 1}
              onPress={() => {
                handlePress('subTitle', {
                  record: item,
                  indexRecord: index,
                });
              }}
            >
              {isCanvas ? subTitleText : get(bindingValue, 'subTitle.text')}
            </Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const AvatarItem: React.FC<ReceivedProps> = (props) => (
  <AvatarItemLayout {...useAvatar(props)} />
);

export default React.memo(AvatarItem);
