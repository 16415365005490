import { IAvatarList } from '@nocode/types';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

import {
  checkFont,
  checkFontWeight,
  getTextDecorationLine,
  parseText,
} from '../../func';
import { getImageCardCover } from '../../shared';
import createStyles from './style';

export type ReceivedProps = {
  attrs: IAvatarList;
  index: number;
  item: any;
  onPress?: (id: string, item: Record<string, any>) => void;
  isWeb?: boolean;
  records: Record<string, any>;
  isCanvas?: boolean;
};

const useAvatar = (props: ReceivedProps) => {
  const styles = createStyles();
  const { attrs, item }: Record<string, any> = props;
  const { attributes } = attrs;
  const { cardCover } = attributes;

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;

  const imageType = cardCover?.image?.imageType;
  const placeholderImageUrl =
    attributes?.cardCover?.image?.binding?.options?.placeholderImage;

  const placeholderImageEnabled =
    !!attributes?.cardCover?.image?.binding?.options?.placeholderImageEnabled;

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  const getTextStyles = (styles: any) => {
    const {
      textDecorationUnderline,
      textDecorationLineThrough,
      fontFamily,
      fontWeightBold,
      fontSize,
      color,
      textAlignment,
      fontStyleItalic,
    } = styles || {};
    const textStyle = getTextDecorationLine({
      textDecoration: textDecorationUnderline,
      linethrough: textDecorationLineThrough,
    });

    return {
      ...(!fontWeightBold &&
        !fontStyleItalic && {
          fontFamily: checkFont(fontFamily, fontWeightBold, target),
        }),
      fontSize: fontSize,
      color: color,
      textAlign: textAlignment,
      textDecorationLine: textStyle,
      fontStyle: (fontStyleItalic ? 'italic' : 'normal') as 'normal' | 'italic',
      fontWeight: checkFontWeight(fontWeightBold, fontFamily),
    };
  };

  const urlImage = getImageCardCover(item, cardCover);
  const titleText = parseText(item?.title);
  const subTitleText = parseText(item?.subTitle);

  const handlePress = (id: string, item: Record<string, any>) => {};

  return {
    ...props,
    getTextStyles,
    styles,
    target,
    urlImage,
    titleText,
    subTitleText,
    placeholderImage,
    handlePress,
  };
};

export type Props = ReturnType<typeof useAvatar>;

export default useAvatar;
