import React, {
  FC,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { View } from 'react-native-animatable';

import createStyles from '@common/components/LiveStreamCamera/WebSource/style';
import {
  getLivestreamChannel,
  handleLivestreamSocket,
  livestreamSocket,
} from '@common/configs/livestream-socket';
import Item from '@common/components/LiveStreamCamera/MobileSource/Viewer/Item';
import useLiveStream from '@common/hooks/useLiveStream';
import { useLocation } from '@common/routes/hooks';
import { LIVE_STREAM_ACTION_TYPES } from '@common/constants/livestream.constant';
import { ILiveStreamDetail } from '@common/types/element';

const ViewLiveStream: FC<any> = (props) => {
  const { profile, streamId } = props;
  const styles = createStyles();
  const playerRef = useRef<RefObject<HTMLVideoElement>>();
  const location = useLocation();
  const { changeValue, pushView, getLiveStream } = useLiveStream(props);
  const [liveStreamDetail, setLiveStreamDetail] = useState<ILiveStreamDetail>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    let recordIdSelected = streamId || location?.state?.streamId;

    if (recordIdSelected) {
      getLiveStream({
        streamId: recordIdSelected,
        accessToken: profile?.accessToken,
        userRecordId: profile?.id,
      }).then((data: any) => {
        setLiveStreamDetail(data);
        if (data?.streamInfo) {
          setIsSuccess(data?.urls ? true : false);
        } else {
          setIsSuccess(false);
        }
      });
    }
  }, [
    location?.state?.streamId,
    setLiveStreamDetail,
    props?.streamId,
    props.record,
    streamId,
  ]);

  useEffect(() => {
    if (liveStreamDetail && isSuccess) {
      if (
        typeof liveStreamDetail === 'object' &&
        Object.keys(liveStreamDetail).length < 1
      ) {
        setIsSuccess(false);
      } else {
        const owner: any =
          liveStreamDetail?.streamInfo?.is_pushed_notification || {};

        changeValue({
          id: liveStreamDetail?.streamInfo?.stream_id,
          streamer: owner?.id,
        });
      }
    }
  }, [liveStreamDetail, isSuccess]);

  useEffect(() => {
    const streamId = liveStreamDetail?.streamInfo?.stream_id;
    if (streamId && isSuccess) {
      pushView({ infoUser: profile, streamId }).then((data: any) => {
        changeValue({
          totalView: data?.total_view || 0,
        });
      });

      handleLivestreamSocket(streamId, (socket: SocketIOClient.Socket) => {
        socket.emit('get', 'livestreams', streamId, (data: any) => {
          data?.totalView &&
            changeValue({
              totalView: data?.totalView,
            });
        });
        socket.on(getLivestreamChannel(streamId), (_data: any) => {
          const { totalView, action, data } = _data;
          if (
            action === LIVE_STREAM_ACTION_TYPES.StopLiveStream &&
            data?.streamId === streamId
          ) {
            setIsSuccess(false);
            return;
          }

          totalView && changeValue({ totalView });
        });
      });
    }
  }, [liveStreamDetail?.streamInfo?.stream_id, isSuccess]);

  useEffect(() => {
    return () => {
      livestreamSocket?.removeAllListeners();
      livestreamSocket?.close();
      changeValue({
        totalView: 0,
      });
    };
  }, []);

  // set video
  const Hls = useMemo(() => {
    if (liveStreamDetail?.urls?.M3U8) {
      let url = liveStreamDetail.urls?.M3U8;
      url = url.replace('http:', 'https:');
      return (
        <ReactHlsPlayer
          playerRef={playerRef.current as any}
          src={url}
          autoPlay={true}
          controls={true}
          muted={false}
          width="100%"
          height="100%"
        />
      );
    }
    return <View></View>;
  }, [liveStreamDetail?.urls?.M3U8]);

  return (
    <View style={styles.viewLiveStream}>
      {Hls}
      <Item
        item={{ id: `${props.id}_item_livestream` }}
        attrs={props}
        index={0}
        isActive={true}
      />
    </View>
  );
};

export default ViewLiveStream;
// export default memo(ViewLiveStream, (prev, next) => {
//   if (isCanvas()) {
//     return false;
//   }

//   const con =
//     prev.id === next.id &&
//     prev.attrs?.streamerId === next.attrs?.streamerId &&
//     prev?.streamId === next?.streamId;
//   return con;
// });
