import { RootState } from '../store';

export const actionSelector = (state: RootState) => state.action;

export const forgotPassword = (state: RootState) => state.action.forgotPassword;

export const resetPassword = (state: RootState) => state.action.resetPassword;

export const loadingActionsSelector = (state: RootState) =>
  state.action.loadingActions;
