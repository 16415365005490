import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { IRectangle } from '@common/types/element';
import { getActions } from '@common/utils/handleActions/func/helps';
import { isEmpty } from 'lodash';
import React, { FC, useRef } from 'react';
import {
  GestureResponderEvent,
  Platform,
  TouchableOpacity,
} from 'react-native';
import createStyles from './style';
// import { useSelector } from 'react-redux';
// import { getDataSourceStore } from '@common/redux/selectors/database';

const InvisibleShape: FC<IRectangle> = (attrs) => {
  // const dataSource = useSelector(getDataSourceStore);
  const touchRef = useRef(null);

  // const parentRecord = useMemo(() => {
  //   if (!attrs.parentListDatabaseUuid || !attrs.parentListItemId)
  //     return undefined;
  //   const parentDB = dataSource[attrs.parentListDatabaseUuid];

  //   const parentData = find(
  //     parentDB,
  //     (item: Record<string, any>) => item._id === attrs.parentListItemId
  //   );
  //   return parentData;
  // }, [attrs, dataSource]);

  const styles = createStyles(attrs);

  const hasAction = !isEmpty(attrs.actions);

  if (hasAction) {
    const handlePress = (e: GestureResponderEvent) => {
      if (Platform.OS === 'web') {
        if (
          (touchRef.current as any) === (e.target as any) &&
          attrs.onPress(getActions(attrs.actions))
        )
          attrs.onPress(getActions(attrs.actions));
      } else {
        attrs.onPress ? attrs.onPress(getActions(attrs.actions)) : {};
      }
    };

    return (
      <TouchableOpacity
        style={styles.container}
        activeOpacity={1}
        ref={touchRef}
        onPress={handlePress}
      >
        <ObjectRender
          keyItem={attrs?.selectedItem?.itemIndex}
          arrComp={attrs?.children || []}
          isScreen={false}
          layout={{
            offsetTop: attrs?.y,
            offsetLeft: attrs?.x,
            parentWidth: attrs?.width,
          }}
          // parentRecord={parentRecord}
        />
      </TouchableOpacity>
    );
  }

  return <></>;
};

export default InvisibleShape;
