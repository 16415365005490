import { get } from 'lodash';
import queryString from 'query-string';
import { useRef } from 'react';
import { Dimensions, Platform } from 'react-native';

import { IVideo } from '@nocode/types';
import { getItemListClick, getValueBinding } from '../shared';
import defaultImage from './VideoPlayholder';

const checkIsCanvas = () => {
  const isWeb = Platform.OS === 'web';

  if (!isWeb) return false;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  return !target;
};

export const useVideoHook = (props: IVideo) => {
  const {
    attributes,
    width,
    height,
    isInCustomList,
    onPress,
    record,
    data,
    id,
    initId,
  } = props;
  const {
    backgroundColor,
    opacity,
    borderColor,
    borderRadius,
    borderStyle,
    borderWidth,
    successActions,
    action,
  } = attributes;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  let actionVideoPlayed = false;
  let actionVideoEnded = false;
  const isCanvas = checkIsCanvas();

  const bindingValue = getValueBinding(
    isInCustomList ? initId : id,
    isInCustomList ? record : data,
    // overwrite preview.image
    {
      ...props,
      attributes: {
        ...attributes,
        preview: {
          ...attributes.preview,
          image: attributes.preview?.imageUrl || attributes.preview?.image,
        },
      },
    }
  );
  const { showVideoControl, autoPlay, loop, muted, videoSource, fullscreen } =
    bindingValue as Pick<
      IVideo['attributes'],
      | 'autoPlay'
      | 'showVideoControl'
      | 'loop'
      | 'muted'
      | 'videoSource'
      | 'fullscreen'
    >;

  const usePreview = get(bindingValue, 'preview.enabled', false);
  const previewImage = usePreview
    ? get(bindingValue, 'preview.image', defaultImage)?.toString()
    : defaultImage;
  const poster = usePreview ? previewImage : undefined;

  const onVideoPlayed = () => {
    if (action && !actionVideoPlayed) {
      onPress(action?.actionId, {
        itemListClick: getItemListClick(record),
      });
      actionVideoPlayed = true;
    }
  };

  const onVideoEnded = () => {
    if (successActions && !actionVideoEnded) {
      onPress(successActions?.actionId, {
        itemListClick: getItemListClick(record),
      });
      actionVideoEnded = true;
    }
  };

  return {
    onVideoPlayed,
    onVideoEnded,
    style: {
      container: {
        width: fullscreen && !isCanvas ? Dimensions.get('window').width : width,
        height:
          fullscreen && !isCanvas ? Dimensions.get('window').height : height,
        backgroundColor,
        opacity,
        ...(borderStyle === 'none'
          ? {}
          : {
              borderColor,
              borderRadius,
              borderStyle,
              borderWidth,
            }),
      },
      video: {
        ...((fullscreen
          ? {
              position: 'absolute',
            }
          : {}) as { position: 'absolute' | 'relative' | undefined }),
        width: '100%',
        height: '100%',
        backgroundColor,
      },
    },
    poster,
    previewImage,
    videoRef,
    videoOptions: {
      showVideoControl: !!showVideoControl,
      autoPlay: !!autoPlay,
      loop: !!loop,
      muted: !!muted,
      videoSource,
      fullscreen: !!fullscreen,
    },
  };
};
