import io from 'socket.io-client';
import appConfig from '../../appConfig.json';

export let appDataBaseSocket: SocketIOClient.Socket;
export const getAppDatabaseChannel = (
  appDatabaseId?: string | number | undefined
) => (appDatabaseId ? `appdatabase_${appDatabaseId}` : null);

export const SOCKET_STATE = {
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  NOT_CONNECTED: 'not-connected',
  DISCONNECTED: 'disconnected',
};

export const handleAppdataBaseSocket = (channel: any, callback: any) => {
  if (appDataBaseSocket?.connected) {
    callback && callback();
    return;
  }

  appDataBaseSocket = io(appConfig.baseUrl, {
    query: {
      channel, // required name 'channel'
    },
    transports: ['websocket'],
    transportOptions: {
      polling: {
        extraHeaders: {},
      },
    },
  });

  callback && callback();
};
