import { IPage } from '@common/types/element';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

type Props = { screen: IPage; dependencies: Record<string, any> };

function useScreenAction({ screen, dependencies }: Props) {
  const messages = useIntl();

  useEffect(() => {
    // action page
    const {
      attributes: { onVisit, componentActions },
    } = screen;

    if (onVisit && onVisit?.actionId) {
      const arrayAction = get(componentActions, `${onVisit?.actionId}.actions`);

      actionPromise({
        actionId: onVisit?.actionId,
        clientValues: dependencies,
        screenUuid: screen.screenUuid,
        screenAction: true,
        messages,
        arrayAction,
      });
    }
  }, [screen.screenUuid]);
}

export default useScreenAction;
