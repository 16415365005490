import { convertTextAttributes } from '@nocode/components/shared';
import { get } from 'lodash';

export const imageUrl = (image: any, item: any, key?: any) => {
  const isAvtImage = key ? 'avatarImage' : 'cardCover';
  const imageType = get(image, `image.imageType`);

  switch (imageType) {
    case 'internal':
      const imageBinding = get(item, `${isAvtImage}.image`);
      return get(imageBinding, 'url', null);

    case 'uploaded':
      return convertTextAttributes(get(image, 'imageUrl', null));

    default:
      const imageUrl = get(item, `${isAvtImage}.image`, null);
      if (!imageUrl) {
        return get(
          item,
          `${isAvtImage}.imageUrl`,
          convertTextAttributes(get(image, 'imageUrl', null))
        );
      }
      return get(item, `${isAvtImage}.image`, null);
  }
};
export const getPlaceholder = (image: any) => {
  const imageType = get(image, `image.imageType`);

  const placeholderImageUrl = get(
    image,
    'image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    image,
    'image.binding.options.placeholderImageEnabled',
    false
  );

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  return placeholderImage;
};
