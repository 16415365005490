import { Modal } from "antd";
import styled from "styled-components";
import { theme } from '@common/configs/theme';

export default styled.div`
  background-color: #ffffff;
  .database-container {
    display: flex;

    .left-menu-database::-webkit-scrollbar {
      display: none;
    }

    .left-menu-database {
      width: 250px;
      border-right: 1px solid #eaeaea;
      height: calc(100vh - 50px);
      overflow-y: auto;
      padding-bottom: 15px;

      .hidden-icon {
        svg {
          display: none;
        }
      }

      .database-collections {
        height: calc(100% - 51px);
        overflow-y: auto;
        .database-sub {
          display: flex;
          justify-content: center;
          border-bottom: 1px solid #eaeaea;
          padding-top: 20px;
          font-size: 16px;
          padding-bottom: 18px;
        }

        .add-table-database {
          padding: 0 15px;
          margin-top: 30px;
        }

        .ant-collapse {
          .ant-collapse-item {
            &.ant-collapse-item-active {
              border-bottom: 1px solid #746af8;
              .ant-collapse-header {
                background-color: #ddedff;
              }
            }

            .ant-collapse-header {
              .panel-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin-left: 40px;
                margin-right: 35px;

                .ant-badge > sup {
                  background-color: ${theme.colors.primary};
                }
              }
            }

            .ant-collapse-content {
              .ant-collapse-content-box {
                margin: 0;
                padding: 8px 16px 16px;

                .anticon-delete {
                  color: ${theme.colors.primary};
                  cursor: pointer;
                }

                .ant-list-item {
                  padding-right: 0;
                  border-bottom: unset;
                  transition: background-color 0.3s;

                  &:hover {
                    background-color: #f5f5f5;
                  }

                  &.btn-add-field {
                    justify-content: unset;
                    cursor: pointer;

                    &:hover {
                      background-color: #fafafa;
                    }

                    .anticon-plus {
                      color: ${theme.colors.primary};
                      margin: 0 8px;

                      & > svg {
                        padding: 1px;
                        border: 1px dashed ${theme.colors.primary};
                      }
                    }
                  }

                  .ant-list-item-meta {
                    .anticon {
                      margin: 0 8px;
                    }
                    .ant-list-item-meta-title {
                      white-space: nowrap;
                      overflow-x: scroll;
                      ::-webkit-scrollbar {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .database-progress {
        padding-top: 10px;
        cursor: pointer;
        .database-progress-inner {
          padding: 0 16px;
          flex: 1;
          .info-progress {
            display: flex;
            justify-content: space-between;
            font-family: "Noto Sans CJK JP";
            .text {
              font-weight: 700;
              font-size: 12px;
              color: #092636;
            }
            .records {
              font-weight: 700;
              font-size: 12px;
            }
          }
        }
      }
    }

    .main-content-database {
      display: flex;
      flex-direction: column;
      width: calc(100vw - 250px);
      height: calc(100vh - 50px);

      .db-header {
        padding: 10px 15px 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .text-header {
          font-size: 24px;
          font-weight: 500;
        }

        .control-header {
          .ant-switch {
            height: 23px;

            .ant-switch-inner {
              display: flex;
            }
          }

          .btn-upload {
            .ant-upload-list {
              display: none;
            }
          }

          .ant-upload {
            width: 100%;
            outline: none;
          }
        }
      }

      .db-content {
        flex: 1;

        .ant-table-body {
          overflow-y: auto !important;
        }

        .ant-btn-group {
          width: 100%;
          .firstButton {
            border-radius: 2px 0 0 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .secondButton {
            border-radius: 0 2px 2px 0;
            margin-left: -1px;
            min-width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          button {
            &:hover {
              z-index: 2;
            }
          }
        }

        .react-resizable {
          position: relative;
          background-clip: padding-box;
        }

        .react-resizable-handle {
          position: absolute;
          right: -5px;
          bottom: 0;
          z-index: 1;
          width: 10px;
          height: 100%;
          cursor: col-resize;
        }

        .ant-table-cell {
          white-space: pre-wrap;
        }

        /* .ant-table-wrapper {
          .ant-pagination {
            display: none;
          }

          .ant-table-thead {
            .ant-table-cell {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .ant-table-tbody {
            td.col-editing {
              background-color: #fffbe6;
            }

            tr.ant-table-row-selected > td {
              background-color: #ede6ff;
              color: #333;
            }

            label.ant-checkbox-wrapper {
              align-items: center;

              .ant-checkbox {
                height: 16px;
              }
            }
          }
        } */
      }

      .db-footer {
        border-top: 1px solid #ecebec;
        padding: 10px 85px 10px 15px;
        display: flex;
        justify-content: space-between;

        .ant-alert {
          align-items: baseline;
        }
      }
    }
  }
`;

export const AddRecordModal = styled(Modal)`
  .ant-input-suffix {
    margin-left: 4px;
    visibility: hidden;
  }
  &.ant-modal {
    .ant-modal-body {
      ::-webkit-scrollbar {
        display: none;
      }
      .ant-form {
        .ant-upload {
          width: 100%;
        }
        .ant-form-item-label-left {
          max-width: 150px;
          word-break: break-all;
          white-space: normal;
          height: 50px;
          overflow: overlay;
          ::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
`;
export const MapFieldModal = styled(Modal)``;
export const DownFieldsModal = styled(Modal)``;
