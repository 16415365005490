import { get } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { Platform, Text } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { checkFont } from '../func';
import { getValueBinding } from '../shared';
import { BloctoBindingValue, BloctoElementType } from './type';
import { UseBloctoHook } from './hook';

type Props = BloctoElementType & UseBloctoHook;
const BloctoButton: React.FC<Props> = (props) => {
  const {
    attributes: {
      backgroundColor,
      borderStyle,
      borderColor,
      borderWidth,
      fontFamily,
      fontSize,
      color,
      opacity,
      borderRadius,
      button: { icon },
    },
    height,
    width,
    isInCustomList,
    data,
    loginFetching,
    handlePress,
    initializing,
  } = props;
  const bindingValue = getValueBinding(
    isInCustomList ? props.initId : props.id,
    isInCustomList ? props?.record : data,
    props
  ) as BloctoBindingValue;
  const textButton = get(bindingValue, 'button.text', '');

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  return (
    <RNButton
      {...{
        style: {
          opacity,
          backgroundColor,
          borderRadius,
          height,
          width,
          justifyContent: 'center',
          borderColor,
          borderWidth,
          borderStyle: borderStyle === 'none' ? undefined : borderStyle,
        },
        labelStyle: {
          fontFamily: checkFont(fontFamily, undefined, target),
          fontSize,
          ...(fontSize && {
            lineHeight: fontSize * 1.15,
            paddingVertical: 1.5,
          }),
          color,
        },
        icon,
      }}
      onPress={handlePress}
      loading={initializing || loginFetching}
    >
      <Text
        {...(Platform.OS !== 'web' && {
          style: {
            fontFamily: checkFont(fontFamily),
            fontSize,
            color,
          },
        })}
      >
        {textButton}
      </Text>
    </RNButton>
  );
};

export default BloctoButton;
