import { ICarousel } from '@common/types/element';
import { filter, pick } from 'lodash';
import querystring from 'query-string';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Animated,
  FlatList,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native';
import Icon from '../AppBar/Icon';
import { getValueBinding } from '../shared';
import CarouselItem from './CarouselItemWeb';
import createStyles from './style';

const INDICATORS_POSITION = {
  OUTSIDE: 1,
  INSIDE: 2,
};

const getCarouselItemList = (attrs: ICarousel) => {
  const { dataBinding, attributes, databaseOptions } = attrs;

  const search = querystring.parse(window?.location?.search);
  const target = search?.target;
  if (Platform.OS === 'web' && !target) {
    return (
      dataBinding ??
      Array.from(Array(3), () => (dataBinding ? dataBinding[0] : ''))
    );
  } else {
    if (!databaseOptions?.id && attributes.customImage.enabled) {
      const bindingValue: Record<string, any> =
        getValueBinding('', dataBinding[0] || {}, attrs) || {};
      const value = filter(
        pick(
          bindingValue,
          Array.from(Array(6).keys()).map(
            (number) => `customImage.image${number}`
          )
        )
      );
      return Object.values(value).map((item: any) => ({
        cardCover: {
          imageUrl: item,
        },
      }));
    } else {
      return dataBinding ?? [];
    }
  }
};

const isCanvas = () => {
  const isWeb = Platform.OS === 'web';

  if (!isWeb) return false;

  const search = querystring.parse(window?.location?.search);
  const target = search?.target;

  return target ? false : true;
};

const Carousel: FC<ICarousel> = (attrs) => {
  const { attributes, onPress } = attrs;
  const width = attrs.width || 0;
  const height = attrs.height || 0;
  const { autoScroll, slideIndicators, arrows, enableZoom } = attributes;
  const [sliderState, setsliderState] = useState({
    currentScrollValue: 0,
    scrollOffsetValue: 0,
  });
  const flatlist = useRef<FlatList<any>>(null);
  const isMounted = useRef<boolean>(false);
  const interval = useRef<any>(null);
  const items = getCarouselItemList(attrs);
  const scrollX = new Animated.Value(0);
  const data_len = items.length;
  const styles = createStyles({ ...attributes, width });
  let position = Animated.divide(scrollX, width);

  const scrollSlider = () => {
    if (!!flatlist) {
      flatlist?.current?.scrollToOffset({
        animated: true,
        offset: sliderState?.scrollOffsetValue,
      });
    }
  };

  const infiniteAutoScroll = () => {
    if (interval.current !== null) {
      clearTimeout(interval.current);
    }

    interval.current = setTimeout(() => {
      if (sliderState.currentScrollValue + 1 < data_len) {
        setsliderState((prev) => ({
          currentScrollValue: prev.currentScrollValue + 1,
          scrollOffsetValue: prev.scrollOffsetValue + width,
        }));
      } else {
        setsliderState({
          currentScrollValue: 0,
          scrollOffsetValue: 0,
        });
      }
    }, autoScroll.autoScrollSpeed * 1000);

    return interval.current;
  };

  const slide = (direction: string) => {
    if (direction === 'left') {
      if (sliderState?.currentScrollValue - 1 < 0) return;
      setsliderState({
        currentScrollValue: sliderState.currentScrollValue - 1,
        scrollOffsetValue: sliderState.scrollOffsetValue - width,
      });
    }

    if (direction === 'right') {
      if (sliderState?.currentScrollValue + 1 === data_len) return;
      setsliderState({
        currentScrollValue: sliderState.currentScrollValue + 1,
        scrollOffsetValue: sliderState.scrollOffsetValue + width,
      });
    }
  };

  useEffect(() => {
    if (isCanvas()) return;
    if (autoScroll?.enabled) infiniteAutoScroll();
    return () => clearTimeout(interval.current);
  }, [items]);

  useEffect(() => {
    if (isCanvas()) return;
    if (isMounted.current) {
      if (autoScroll?.enabled) {
        infiniteAutoScroll();
      }
      scrollSlider();
    } else {
      isMounted.current = true;
    }
    return () => clearTimeout(interval.current);
  }, [sliderState]);

  const getDataWidth = (): number => items?.length ?? 10;
  const renderer = ({ item, index }: any) => (
    <CarouselItem
      item={item}
      key={index}
      index={index}
      onPress={onPress}
      attrs={attrs}
      height={height}
      width={width}
    />
  );
  // const momoizedValue = useMemo(() => renderer, items);

  return (
    <View style={styles.wrapper}>
      <View style={{ position: 'relative', flex: 1 }}>
        {isCanvas() ? (
          <View style={{ width, height, backgroundColor: '#eee' }}></View>
        ) : (
          <FlatList
            data={items}
            renderItem={renderer}
            ref={flatlist}
            horizontal
            showsHorizontalScrollIndicator={false}
            snapToAlignment={Platform.OS === 'ios' ? 'start' : undefined}
            scrollEventThrottle={16}
            decelerationRate={'normal'}
            pagingEnabled
            scrollEnabled
            style={{ width, height }}
            onScroll={Animated.event([
              { nativeEvent: { contentOffset: { x: scrollX } } },
            ])}
            initialNumToRender={getDataWidth()}
          />
        )}

        {arrows?.enabled && (
          <>
            <View style={[styles.arrowWrapper, { left: 0 }]}>
              <TouchableOpacity
                onPress={() => slide('left')}
                style={[
                  styles.arrow,
                  {
                    backgroundColor:
                      sliderState.currentScrollValue - 1 < 0
                        ? arrows.iconDisabledColor
                        : arrows.iconColor,
                  },
                ]}
              >
                <Icon name={arrows.leftIcon ?? 'chevron-left'} color={'#FFF'} />
              </TouchableOpacity>
            </View>
            <View style={[styles.arrowWrapper, { right: 0 }]}>
              <TouchableOpacity
                onPress={() => slide('right')}
                style={[
                  styles.arrow,
                  {
                    backgroundColor:
                      sliderState.currentScrollValue + 1 === data_len
                        ? arrows.iconDisabledColor
                        : arrows.iconColor,
                  },
                ]}
              >
                <Icon
                  name={arrows.rightIcon ?? 'chevron-left'}
                  color={'#FFF'}
                />
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
      {slideIndicators?.enabled && (
        <View
          style={[
            styles.dots,
            slideIndicators.position === INDICATORS_POSITION.INSIDE
              ? styles.insideDots
              : {},
          ]}
        >
          {(items ?? []).map((_: any, i: any) => {
            let opacity = position.interpolate({
              inputRange: [i - 1, i, i + 1],
              outputRange: [0.6, 1, 0.6],
              extrapolate: 'clamp',
            });
            return <Animated.View key={i} style={[styles.dot, { opacity }]} />;
          })}
        </View>
      )}
    </View>
  );
};

export default Carousel;
