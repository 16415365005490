import { ActionStatus } from '@common/constants/shared';
import { MetadataAction } from '@common/types/action';
import { get } from 'lodash';
import { ActionResponse } from '../excuteAction';

export const sendEmail: (
  action: any,
  metadata: MetadataAction
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetadataAction
) => {
  try {
    const { messages } = metadata;

    if (action.status === ActionStatus.ERROR) {
      return {
        status: ActionStatus.FAILED,
        message: get(action, 'error.message'),
      };
    }

    return {
      status: ActionStatus.SUCCESS,
      message: messages['send_email_completed'],
    };
  } catch (error: any) {
    return {
      status: ActionStatus.FAILED,
      message: error.message,
    };
  }
};
