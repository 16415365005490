import { IAvatarList } from '@nocode/types';
import { get } from 'lodash';
import queryString from 'query-string';
import React, { FC, useEffect, useRef } from 'react';
import { ActivityIndicator, FlatList, Platform, View } from 'react-native';
import Icon from '../AppBar/Icon';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import AvatarItem from './Avatar';
import { getInitValueAvatarList } from './func';
import createStyles from './style';

const AvatarListLayout: FC<IAvatarList> = (props) => {
  const { page = 1, totalPage, onLoadMore } = props;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;
  const { initializeList } = props;

  const {
    attributes: { items },
    isWeb,
    opacity,
    records,
    onPress,
    dataBinding,
  } = props;

  const flatListRef: any = useRef();
  const styles = createStyles();

  const listData = getInitValueAvatarList(dataBinding, items);

  if (target && !get(props, 'databaseOptions.tableId') && !props.isLoadMore)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <EmptyList attributes={props} />
      </View>
    );

  useEffect(() => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0, x: 0 });
  }, [props.id]);

  useEffect(() => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0, x: 0 });

    if (props?.hasAction) {
      // props.setLoadMore && props.setLoadMore(1);
    }
  }, [props?.hasAction]);

  const onEndReached = () => {
    if (props?.isLoadMore || page >= totalPage) return;
    onLoadMore && onLoadMore();
  };

  return initializeList ? (
    <Loading />
  ) : (
    <View
      //@ts-ignore
      style={[
        {
          opacity,
        },
        styles.wapperList,
      ]}
    >
      <FlatList
        data={listData}
        horizontal
        // pagingEnabled={Platform.OS === 'web'}
        scrollEnabled
        snapToAlignment="start"
        scrollEventThrottle={16}
        decelerationRate={'normal'}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        ref={flatListRef}
        onEndReachedThreshold={0.1}
        {...(props.isLoadMore && {
          ListFooterComponent: (
            <View
              style={{
                display: 'flex',
                height: props.height - 30,
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size="small" color="#1976d2" />
            </View>
          ),
        })}
        onEndReached={onEndReached}
        renderItem={({ item, index }) => (
          <AvatarItem
            item={item}
            key={index}
            index={index}
            attrs={props}
            onPress={onPress}
            isWeb={isWeb}
            records={records}
            isCanvas={isCanvas}
          />
        )}
        keyExtractor={(item, index) => `${(item?._id, index)}`}
      />

      {isCanvas && <Icon name="ellipsis-horizontal" />}
    </View>
  );
};

export default AvatarListLayout;
