import { ISimpleList } from '@nocode/types';
import { findLastIndex, get, isEmpty, isNil } from 'lodash';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { FlatList, Platform, Text, TouchableOpacity, View } from 'react-native';
import { List } from 'react-native-paper';
import LeftSection from '../Common/SectionLeftRight';
import EmptyList from '../EmptyList';
import { checkFont, getListItems } from '../func';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import Loading from '../Loading';
import createStyles from './style';

const SimpleList = (attrs: ISimpleList) => {
  const {
    attributes: {
      listHeader,
      subTitle,
      subTitle2,
      leftSection,
      rightSection,
      dividerType,
      scrollActive,
      iconColor,
      opacity,
      backgroundColor,
      borderColor,
      borderWidth,
      borderRadius,
      fontFamily,
      fontSize,
      color,
      title,
      rowCount,
      items,
    },
    dataBinding,
    isWeb,
    page,
    totalPage,
    onLoadMore,
    isLoadMore,
    onPress,
    initializeList,
    externalCollection,
  } = attrs;

  const handlePress = (
    id: 'onPress' | 'rightSection',
    item: Record<string, any>
  ) => {
    const options = {
      itemListClick: getItemListClick(item.record),
    };

    onPress && onPress(getActions(attrs, id), options);
  };

  const isShowFooter = totalPage > page;

  const getInitValues = () => {
    let len = rowCount || 3;

    if (Platform.OS !== 'web') {
      // App
      return dataBinding;
    } else {
      // web
      const search = queryString.parse(window?.location?.search);
      const target = search?.target;

      if (target) {
        // preview
        return dataBinding;
      } else {
        // client
        return getListItems(items, len);
      }
    }
  };

  const listData = useMemo(() => getInitValues(), [dataBinding]);

  const styles = createStyles();

  const isFixedHeight: any = scrollActive
    ? { contentContainerStyle: { flexGrow: 0, height: 'auto', margin: 0 } }
    : {};

  const tableId = get(attrs, 'attributes.items.source.tableId');

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;

  const emptyList = (target && !tableId) || (!tableId && Platform.OS !== 'web');

  if (emptyList)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <EmptyList attributes={attrs} />
      </View>
    );

  if (initializeList) {
    return <Loading />;
  }

  const ListFooterComponent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (isShowFooter) {
            onLoadMore();
          }
        }}
      >
        {isLoadMore ? (
          <Loading />
        ) : (
          <Text
            style={{
              fontSize: 14,
              textDecorationLine: 'underline',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            もっとロード
          </Text>
        )}
      </TouchableOpacity>
    );
  };

  const renderItemList = ({ item, index }: any) => {
    const bindingValue = getValueBinding('', item, attrs);

    return (
      <List.Item
        key={index}
        title={get(bindingValue, 'title.text')}
        titleNumberOfLines={title && title.titleLineNum > 1 ? 500 : 1}
        onPress={() => {
          handlePress('onPress', {
            record: item,
            indexRecord: index,
          });
        }}
        description={() => (
          <View>
            {subTitle.enabled && (
              <Text
                numberOfLines={
                  subTitle && subTitle.subtitleLineNum > 1 ? 500 : 1
                }
                style={{
                  fontSize,
                  fontFamily: checkFont(fontFamily),
                  color,
                }}
              >
                {get(bindingValue, 'subTitle.text')}
              </Text>
            )}
            {subTitle2.enabled && (
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: checkFont(fontFamily),
                  color,
                }}
                numberOfLines={
                  subTitle2 && subTitle2.subtitle2LineNum > 1 ? 500 : 1
                }
              >
                {get(bindingValue, 'subTitle2.text')}
              </Text>
            )}
          </View>
        )}
        left={() =>
          leftSection?.enabled ? (
            <LeftSection
              attributes={{
                ...(leftSection || {}),
                iconColor,
              }}
              record={item}
              mode="leftSection"
              externalCollection={externalCollection}
            />
          ) : null
        }
        right={() =>
          rightSection?.enabled ? (
            <LeftSection
              attributes={{ ...(rightSection || {}), iconColor }}
              record={item}
              mode="rightSection"
              externalCollection={externalCollection}
              handlePress={() =>
                handlePress('rightSection', {
                  record: item,
                  indexRecord: index,
                })
              }
            />
          ) : null
        }
        style={{
          borderTopWidth:
            dividerType && dividerType === 'none'
              ? 0
              : index === 0 && dividerType && dividerType === 'line'
              ? 0
              : dividerType && dividerType === 'shadow'
              ? 1
              : 0,
          borderBottomWidth:
            dividerType && dividerType === 'none'
              ? 0
              : index !== findLastIndex(listData)
              ? 1
              : dividerType && dividerType === 'shadow'
              ? 1
              : 0,
          borderColor: '#e2e1e1',
          justifyContent: 'center',
          ...(dividerType && dividerType === 'shadow' && { borderRadius: 20 }),
        }}
        titleStyle={{
          //error font family________________
          fontFamily: checkFont(fontFamily),
          fontSize,
          color,
        }}
        descriptionStyle={{
          //error font family________________
          fontFamily: checkFont(fontFamily),
          fontSize,
          color,
        }}
      />
    );
  };

  const keyExtractor = (item: any, index: number) => index;

  const list: any = (
    <FlatList
      {...isFixedHeight}
      nestedScrollEnabled
      showsHorizontalScrollIndicator={false}
      data={listData}
      keyExtractor={keyExtractor}
      // ItemSeparatorComponent={() => <Divider />}
      renderItem={renderItemList}
      {...(isShowFooter && {
        ListFooterComponent: ListFooterComponent,
      })}
    />
  );

  return (
    <>
      <List.Section
        style={{
          ...styles,
          opacity,
          ...(listData && listData.length > 0 ? { backgroundColor } : {}),
          borderColor,
          borderWidth,
          borderRadius,
          ...(isWeb && {
            marginTop: 0,
            marginBottom: 0,
            height: attrs.height - 20,
            overflowX: 'hidden',
            overflowY: listData.length > 0 ? 'visible' : 'hidden',
          }),
        }}
      >
        {listHeader.enabled && (
          <List.Subheader onPressIn={() => {}} onPressOut={() => {}}>
            Simple List
          </List.Subheader>
        )}
        {listData &&
          listData.length > 0 &&
          (scrollActive && (Platform.OS !== 'web' || !isNil(target)) ? (
            <View style={{ height: attrs.height, margin: 0 }}>{list}</View>
          ) : (
            list
          ))}
      </List.Section>
    </>
  );
};

export default SimpleList;
