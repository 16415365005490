import React, { memo } from 'react';
import { Text, View } from 'react-native';
import Icon from '../AppBar/Icon';
import BrandIcon from './BrandIcon';
import { CardStripeInfo } from './style';

interface Props {
  titleEnabled?: boolean;
  titleText: string;
  cardDefaultInfo: CardStripeInfo;
  rememberCheckbox: {
    icon: string;
  };
  message: {
    error?: string;
    success?: string;
  };
}
const DefaultCard: React.FC<Props> = ({
  titleEnabled,
  titleText,
  cardDefaultInfo,
  rememberCheckbox,
  message,
}) => {
  return (
    <View
      style={{
        width: '100%',
        height: '70%',
        justifyContent: 'space-around',
        alignContent: 'center',
      }}
    >
      {titleEnabled && <Text>{titleText}</Text>}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BrandIcon brand={cardDefaultInfo.brand} />
        <View style={{ marginRight: 5 }}></View>
        {Array(12)
          .fill(1)
          .map((value, index) => {
            return (
              <>
                <Icon
                  name={rememberCheckbox.icon || 'checkbox-blank-circle'}
                  key={index.toString()}
                  size={12}
                />
                {index % 4 === 3 && <View style={{ marginRight: 5 }}></View>}
              </>
            );
          })}
        <Text style={{ fontSize: 16 }}>{cardDefaultInfo.last4}</Text>
      </View>
      {message.error && (
        <Text
          style={{
            color: 'red',
          }}
        >
          {message.error}
        </Text>
      )}
      {message.success && (
        <Text
          style={{
            color: 'green',
          }}
        >
          {message.success}
        </Text>
      )}
    </View>
  );
};

export default memo(DefaultCard);
