import { getValueBindingComponent } from '@common/utils/component';
import React, { FC, useMemo } from 'react';
import { Platform, View } from 'react-native';
import { WebView } from 'react-native-webview';
import createStyles from './style';

const Youtube: FC = (attributes: any) => {
  const styles = createStyles(attributes);

  const videoIdPlay = useMemo(() => {
    const url = getValueBindingComponent(attributes, 'url');

    if (url) {
      const regeExURL =
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
      const matchForUrl = url.match(regeExURL);
      const regExID =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const matchForID = url.match(regExID);
      return matchForUrl && matchForID && matchForID[7].length === 11
        ? matchForID[7]
        : '';
    }
  }, [attributes]);

  if (Platform.OS === 'web') {
    return (
      <View style={styles.container}>
        <iframe
          title="PMU"
          src={`https://www.youtube.com/embed/${videoIdPlay}?autoplay=${
            attributes.isAutoPlay ? 1 : 0
          }&origin=${origin}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            borderRadius: attributes.borderRadius,
            height: '100%',
            width: '100%',
          }}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <WebView
        javaScriptEnabled={true}
        scrollEnabled={false}
        allowsFullscreenVideo={true}
        allowsInlineMediaPlayback={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        mediaPlaybackRequiresUserAction={
          Platform.OS !== 'android' || Platform.Version >= 17
            ? false
            : undefined
        }
        userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1"
        source={{
          uri: `https://www.youtube.com/embed/${videoIdPlay}?&autoplay=${
            attributes.isAutoPlay ? 1 : 0
          }&showinfo=0&controls=1&fullscreen=1`,
        }}
        style={{
          width: attributes.width,
          height: attributes.height,
        }}
      />
    </View>
  );
};

export default Youtube;
