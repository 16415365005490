import { IToggle } from '@nocode/types';
import React, {
  ComponentProps,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { ToggleButton } from 'react-native-paper';
import PointIcon from '../CustomIcon';
import {
  getItemListClick,
  getPathLibrary,
  getValueBindingComponent,
  getConfig,
  isCheckColor,
} from '../shared';
import createStyles from './style';
type Props = ComponentProps<typeof ToggleButton>;

const Toggle: React.FC<IToggle> = (attrs) => {
  const {
    attributes,
    onPress,
    id,
    data,
    initId = '',
    record,
    isInCustomList,
  } = attrs;
  const styles = createStyles(attributes);

  const [isActive, setIsActive] = useState<boolean>(false);

  const defaultValue = useMemo(() => {
    const paths = getPathLibrary(getConfig(attrs));
    const value = getValueBindingComponent(attrs, paths[0]);
    return value;
  }, [attrs]);

  const isHaveDefaultValue = typeof defaultValue === 'boolean' ? true : false;

  useEffect(() => {
    if (isHaveDefaultValue) {
      setIsActive(defaultValue);
    }
  }, [attrs]);

  const handleToggle = (actionProps: any) => {
    if (isHaveDefaultValue && !actionProps) {
      return;
    } else {
      setIsActive(!isActive);
      if (actionProps) {
        onPress(actionProps.actionId, {
          itemListClick: getItemListClick(record),
        });
      }
    }
  };
  const childProps: Props = {
    status: 'unchecked',
    icon: isActive
      ? attributes.activeIcon === 'point'
        ? () => (
            <PointIcon
              width={attributes.size}
              height={attributes.size}
              fill={attributes.activeColor}
            />
          )
        : attributes.activeIcon
      : attributes.inactiveIcon === 'point'
      ? () => (
          <PointIcon
            width={attributes.size}
            height={attributes.size}
            fill={attributes.inactiveColor}
          />
        )
      : attributes.inactiveIcon,
    color: isActive
      ? isCheckColor(attributes.activeColor)
        ? attributes.activeColor
        : '#4259AC'
      : isCheckColor(attributes.inactiveColor)
      ? attributes.inactiveColor
      : '#bbb',
    size: attributes.size,
  };

  const handlePress = useCallback(async () => {
    const { clickActions, activeActions, inactiveActions } = attributes;

    if (!onPress) return;

    if (!!isActive && activeActions) {
      await handleToggle(activeActions);
      return;
    }

    if (!isActive && inactiveActions) {
      await handleToggle(inactiveActions);
      return;
    }

    return handleToggle(clickActions);
  }, [isActive]);

  return (
    <ToggleButton onPress={handlePress} style={styles.icon} {...childProps} />
  );
};

export default Toggle;
